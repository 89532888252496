import PropTypes from "prop-types";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { CustomTypography4 } from "../../../components/Typography/Typography";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

// import { WHITE } from "../../theme/Palette";

const CallSettings = ({ onClose }) => {
  const [videoDevices, setVideoDevices] = useState([]);
  const [audioDevices, setAudioDevices] = useState([]);
  const [speakerDevices, setSpeakerDevices] = useState([]);
  const [selectedVideoDevice, setSelectedVideoDevice] = useState(""); //commented for hide
  const [selectedAudioDevice, setSelectedAudioDevice] = useState("");
  const [selectedSpeakerDevice, setSelectedSpeakerDevice] = useState("");

  useEffect(() => {
    const getDevices = async () => {
      const devices = await navigator.mediaDevices.enumerateDevices();
      console.log("devices", devices);
      const videoInputDevices = devices.filter(
        (device) => device.kind === "videoinput"
      );
      const audioInputDevices = devices.filter(
        (device) => device.kind === "audioinput"
      );
      const audioOutputDevices = devices.filter(
        (device) => device.kind === "audiooutput"
      );

      setVideoDevices(videoInputDevices);
      setAudioDevices(audioInputDevices);
      setSpeakerDevices(audioOutputDevices);

      if (videoInputDevices.length > 0) {
        setSelectedVideoDevice(videoInputDevices[0].deviceId);
      }
      if (audioInputDevices.length > 0) {
        setSelectedAudioDevice(audioInputDevices[0].deviceId);
      }
      if (audioOutputDevices.length > 0) {
        setSelectedSpeakerDevice(audioOutputDevices[0].deviceId);
      }
    };

    getDevices();
  }, []);

  return (
    <Box 
      sx={{
        m:1,
        position: "initial",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        width: "350px",
        paddingLeft: "20px",
       
      }}
    >
      <CustomTypography4
        sx={{
          fontSize: "18px",
          
          fontWeight: 600,
          lineHeight: "27px",
          fontFamily: "Lato",
          textAlign: "left",
          padding:5
         
        }}
        gutterBottom
      >
        Call settings
      </CustomTypography4>

      <Box
        sx={{
        
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between", flexDirection:"column", height:"80vh"
        }}
      >
        <Box sx={{width:"300px"}}>
          <FormControl fullWidth sx={{ mt: 5 }}>
            <InputLabel
              id="videoInput-label"
              sx={{
                color: "rgba(255, 255, 255, 0.5)",
                fontWeight: "400",
                fontFamily: "Lato",
                fontSize: "14px",
                position: "absolute", // Keep the label inside the input area
                // Vertically center the label
                transform: "translateY(-50%)", // Ensure the label is vertically centered
                left: "8px", // Align label with the start of the input
                paddingLeft: "12px", // Match padding with the select content
                pointerEvents: "none", // Prevent interaction with the label
              }}
            >
              Video
            </InputLabel>
            <Select
              labelId="videoInput-label"
              id="videoInput"
              value={selectedVideoDevice}
              label="Select Camera"
              onChange={(e) => setSelectedVideoDevice(e.target.value)}
              variant="standard" // Use standard variant for underline style
              IconComponent={KeyboardArrowDownIcon} // Set the custom Star icon
              sx={{
                color: "", // Ensure the text is white
                marginLeft: "20px",
                fontWeight: 600,
                fontSize: "15px",
                fontFamily: "Lato",

                "& .MuiSelect-select": {
                  paddingBottom: "15px", // Adjust padding between text and the underline
                },

                "&:before": {
                  borderBottom: "1px solid rgba(245, 245, 250, 0.4)", // Placeholder line (underline)
                },
                "&:after": {
                  marginTop: "2rem",
                  borderBottom: "2px solid rgba(245, 245, 250, 0.4)", // Thicker line on focus
                },

                "&:hover:not(.Mui-disabled):before": {
                  borderBottom: "2px solid rgba(245, 245, 250, 0.4)", // Line on hover
                },
                "& .MuiSvgIcon-root": {
                  color: "rgba(255, 255, 255, 0.5)", // White color for dropdown arrow
                  width: "18px",
                  height: "18px",
                  position: "absolute", // Keep the arrow in its original position
                  lineHeight: "18px",
                },
              }}
            >
              {videoDevices.map((device) => (
                <MenuItem key={device.deviceId} value={device.deviceId}>
                  {device.label || `Camera ${device.deviceId}`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth sx={{ mt: 7 }}>
            <InputLabel
              id="audioInput-label"
              sx={{
                color: "rgba(255, 255, 255, 0.5)",
                fontWeight: "400",
                fontFamily: "Lato",
                fontSize: "14px",
                position: "absolute", // Keep the label inside the input area
                // Vertically center the label
                transform: "translateY(-50%)", // Ensure the label is vertically centered
                left: "8px", // Align label with the start of the input
                paddingLeft: "12px", // Match padding with the select content
                pointerEvents: "none", // Prevent interaction with the label
              }}
            >
              Microphone
            </InputLabel>
            <Select
              labelId="audioInput-label"
              id="audioInput"
              value={selectedAudioDevice}
              label="Select Microphone"
              onChange={(e) => setSelectedAudioDevice(e.target.value)}
              variant="standard" // Use standard variant for underline style
              IconComponent={KeyboardArrowDownIcon} // Set the custom Star icon
              sx={{
                color: "", // Ensure the text is white
                marginLeft: "20px",
                fontWeight: 600,
                fontSize: "15px",
                fontFamily: "Lato",
                "& .MuiSelect-select": {
                  paddingBottom: "15px", // Adjust padding between text and the underline
                },

                "&:before": {
                  borderBottom: "1px solid rgba(245, 245, 250, 0.4)", // Placeholder line (underline)
                },
                "&:after": {
                  marginTop: "2rem",
                  borderBottom: "2px solid rgba(245, 245, 250, 0.4)", // Thicker line on focus
                },

                "&:hover:not(.Mui-disabled):before": {
                  borderBottom: "2px solid rgba(245, 245, 250, 0.4)", // Line on hover
                },
                "& .MuiSvgIcon-root": {
                  color: "rgba(255, 255, 255, 0.5)", // White color for dropdown arrow
                  width: "18px",
                  height: "18px",
                },
              }}
            >
              {audioDevices.map((device) => (
                <MenuItem key={device.deviceId} value={device.deviceId}>
                  {device.label || `Microphone ${device.deviceId}`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth sx={{ mt: 7 }}>
            <InputLabel
              id="audioOutput-label"
              sx={{
                color: "rgba(255, 255, 255, 0.5)",
                fontWeight: "400",
                fontFamily: "Lato",
                fontSize: "14px",
                position: "absolute", // Keep the label inside the input area
                // Vertically center the label
                transform: "translateY(-50%)", // Ensure the label is vertically centered
                left: "8px", // Align label with the start of the input
                paddingLeft: "12px", // Match padding with the select content
                pointerEvents: "none", // Prevent interaction with the label
              }}
            >
              Select Speakers
            </InputLabel>
            <Select
              labelId="audioOutput-label"
              id="audioOutput"
              value={selectedSpeakerDevice}
              label="Select Speakers"
              onChange={(e) => setSelectedSpeakerDevice(e.target.value)}
              variant="standard" // Use standard variant for underline style
              IconComponent={KeyboardArrowDownIcon} // Set the custom Star icon
              sx={{
                color: "", // Ensure the text is white
                marginLeft: "20px",
                fontWeight: 600,
                fontSize: "15px",
                fontFamily: "Lato",
                "& .MuiSelect-select": {
                  paddingBottom: "15px", // Adjust padding between text and the underline
                },

                "&:before": {
                  borderBottom: "1px solid rgba(245, 245, 250, 0.4)", // Placeholder line (underline)
                },
                "&:after": {
                  marginTop: "2rem",
                  borderBottom: "2px solid rgba(245, 245, 250, 0.4)", // Thicker line on focus
                },

                "&:hover:not(.Mui-disabled):before": {
                  borderBottom: "2px solid rgba(245, 245, 250, 0.4)", // Line on hover
                },
                "& .MuiSvgIcon-root": {
                  color: "rgba(255, 255, 255, 0.5)", // White color for dropdown arrow
                  width: "18px",
                  height: "18px",
                },
              }}
            >
              {speakerDevices.map((device) => (
                <MenuItem key={device.deviceId} value={device.deviceId}>
                  {device.label || `Speaker ${device.deviceId}`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        <Button
          sx={{
            // marginLeft: "2rem",
            marginTop: "10rem",
            height: "46px",
            width: "276px",
            borderRadius: "10px",
            backgroundColor: "rgba(0, 146, 248, 1)",
          }}
          variant="contained"
          onClick={() => onClose(false)}
        >
          <CustomTypography4
            sx={{
              textTransform: "none",
              fontSize: "16px",
              fontWeight: 600,
              fontFamily: "Lato",
            }}
          >
            Done
          </CustomTypography4>
        </Button>
      </Box>
    </Box>
  );
};

export default CallSettings;

CallSettings.propTypes = {
  onClose: PropTypes.func,
};
