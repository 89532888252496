import * as React from "react";
import PropTypes from "prop-types";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
// import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import { Paper,styled } from "@mui/material";
import { PRIMARY } from "../../theme/Palette";
// import TableHead from '@mui/material/TableHead';
// import TableRow from "@mui/material/TableRow";
// import Paper from "@mui/material/Paper";
 const CustomPaper = styled(Paper)(() => ({
  borderRadius: "8px",
  border: "1px solid rgba(255, 255, 255, 0.15)",
  opacity:1
}));


export default function BasicTable({ children }) {
  return (
    <TableContainer component={CustomPaper} sx={{ borderRadius: '8px', overflow: 'hidden', background: PRIMARY.darker }}>
    <Table sx={{ minWidth: 650 }} aria-label="simple table">
      <TableBody>{children}</TableBody>
    </Table>
  </TableContainer>
  );
}

BasicTable.propTypes = {
  children: PropTypes.node,
};
