

export const path = (root, subpath, params = {}) => {
  let fullPath = `${root}${subpath}`;
  for (const [key, value] of Object.entries(params)) {
    fullPath = fullPath.replace(`:${key}`, value);
  }
  return fullPath;
};
const ROOTS_AUTH = "/auth";
const ROOTS_DASHBOARD = "/dashboard";


//For business user
const ROOTS_BUSINESSDASHBOARD = "/business";
const ROOTS_BUSINESSAUTH = "/businessauth";

// for business admin
const ROOTS_BUSINESSADMINDASHBOARD = "/businessadmin";
const ROOTS_BUSINESSADMINAUTH = "/businessadminauth";


// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, "/login"),
  signUp: path(ROOTS_AUTH, "/sign-up"),
  emailCheck: path(ROOTS_AUTH, "/email-sucess"),
  // loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  // registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  emailVerification: path(ROOTS_AUTH, "/otp-verification"),
  resetPassword: path(ROOTS_AUTH, "/reset-password"),
  newPassword: path(ROOTS_AUTH, "/new-password"),
  createMeeting: (id) => path(ROOTS_AUTH, `/create-meeting/${id}`),
  joinCall: (meetingId) => path(ROOTS_AUTH, `/join-call/${meetingId}`),
  // emailSuccess: path
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  Overview: path(ROOTS_DASHBOARD, "/Overview"),
  Schedule: path(ROOTS_DASHBOARD, "/Schedule"),
  Notifications: path(ROOTS_DASHBOARD, "/Notifications"),
  Messages: path(ROOTS_DASHBOARD, "/messages"),
  JoinMeeting: (meetingId) => path(ROOTS_DASHBOARD, `/call/join/${meetingId}`), // This generates the correct path with meetingId
  meeting: path(ROOTS_DASHBOARD, "/call/meet"),
};



//FOR BUSINESS USER AUTH

export const PATH_BUSINESSAUTH = {
  root: ROOTS_BUSINESSAUTH,
  login: path(ROOTS_BUSINESSAUTH, "/login"),
  signUp: path(ROOTS_BUSINESSAUTH, "/signup"),
  //// loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  //// registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  businessemailVerification: path(ROOTS_BUSINESSAUTH, "/otp-verification"),
  resetPassword: path(ROOTS_AUTH, "/reset-password"),
  newPassword: path(ROOTS_AUTH, "/new-password"),
  BusinessemailCheck: path(ROOTS_BUSINESSAUTH, "/email-sucess"),

  //createMeeting: (id) => path(ROOTS_BUSINESSAUTH, `/create-meeting/${id}`),
  //joinCall: (meetingId) => path(ROOTS_BUSINESSAUTH, `/join-call/${meetingId}`),
  // emailSuccess: path
};

//FOR BUSINESS USER DASHBOARD
export const PATH_BUSINESS_DASHBOARD = {
  root: ROOTS_BUSINESSDASHBOARD,
  Overview: path(ROOTS_BUSINESSDASHBOARD, "/Overview"),
  Notifications: path(ROOTS_BUSINESSDASHBOARD, "/Notifications"),
  Schedule: path(ROOTS_BUSINESSDASHBOARD, "/Schedule"),
  Messages: path(ROOTS_BUSINESSDASHBOARD, "/messages"),
  JoinMeeting: (meetingId) => path(ROOTS_BUSINESSDASHBOARD, `/call/join/${meetingId}`), // This generates the correct path with meetingId
  meeting: path(ROOTS_BUSINESSDASHBOARD, "/call/meet"),
};




//FOR BUSINESS AdMIN AUTH

export const PATH_BUSINESSADMINAUTH = {
  root: ROOTS_BUSINESSADMINAUTH,
  login: path(ROOTS_BUSINESSADMINAUTH, "/login"),
  signUp: path(ROOTS_BUSINESSADMINAUTH, "/signup"),
  BusinessadminemailCheck: path(ROOTS_BUSINESSADMINAUTH, "/email-sucess"),
  newPassword: path(ROOTS_BUSINESSADMINAUTH, "/new-password"),
  businessadminemailVerification: path(ROOTS_BUSINESSADMINAUTH, "/otp-verification"),

  //// loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  //// registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  //createMeeting: (id) => path(ROOTS_BUSINESSAUTH, `/create-meeting/${id}`),
  //joinCall: (meetingId) => path(ROOTS_BUSINESSAUTH, `/join-call/${meetingId}`),
  // emailSuccess: path
};
//FOR  BUSINESS ADMIN 
export const PATH_BUSINESSADMIN_DASHBOARD = {
  root: ROOTS_BUSINESSADMINDASHBOARD,
  Overview: path(ROOTS_BUSINESSADMINDASHBOARD, "/Overview"),
  Notifications: path(ROOTS_BUSINESSADMINDASHBOARD, "/Notifications"),
  Schedule: path(ROOTS_BUSINESSADMINDASHBOARD, "/Schedule"),
  Messages: path(ROOTS_BUSINESSADMINDASHBOARD, "/messages"),
  JoinMeeting: (meetingId) => path(ROOTS_BUSINESSADMINDASHBOARD, `/call/join/${meetingId}`), // This generates the correct path with meetingId
  meeting: path(ROOTS_BUSINESSADMINDASHBOARD, "/call/meet"),

};
