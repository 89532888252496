import PropTypes from "prop-types";
import { Divider, Stack } from "@mui/material";
import React from "react";
import Image from "../../../components/Image";
import {
  CustomSubtitle,
  CustomTypography1,
} from "../../../components/Typography/Typography";
import user from "../../../assets/images/translation/imggirl.jpg";
import { OutlinedButton } from "../../../components/Butons/CustomButton";
import KeyboardArrowDownSharpIcon from "@mui/icons-material/KeyboardArrowDownSharp";
import { Box } from "@mui/material";
//import AddIcon from '@mui/icons-material/Add';
//import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

function MeetingAttendeeDetails({
  isTranslation,
  invitees,
  translationLanguages,
}) {
  return (
    <div>
      {invitees?.map((invitee, index) => (
        <React.Fragment key={index}>
          <Stack direction="row" spacing={2} p={2}>
            <Image
              alt="user"
              src={user}
              sx={{
                width: "38px",
                height: "38px",
                borderRadius: "6px",
                flexShrink: 0,
              }}
            />

            <Stack direction="column" alignItems="flex-start" flexGrow={1}>
              {/* If full_name is empty, display the email in the name field */}

              <CustomTypography1
                sx={{
                fontSize:{xs:"10px",sm:"10px",md:"14px",lg:"14px"},
                  marginTop: "-9px",
                  fontFamily: "Lato",
                  fontWeight: 400,
                }}
              >
                
                {invitee.full_name ? invitee.full_name : invitee.email}
           
              </CustomTypography1>
              {/* If full_name exists, display the email below it */}
              {invitee.full_name && (
                <CustomSubtitle
                  sx={{
                    color: "rgba(255, 255, 255, 0.5)",
                    fontWeight: 400,
                    fontSize: "14px",
                    lineHeight: "21px",
                  }}
                  marginTop={-1}
                >
                  {invitee.email}
                </CustomSubtitle>
              )}

         
            </Stack>

            {/* Show translation language if available */}

            {isTranslation &&
              translationLanguages &&
              translationLanguages[index] && ( //if these condition are ok the outline button style is enabled
                <OutlinedButton
                  variant="contained"
                  sx={{
                    height: "36px",
                    textTransform: "none",
                    "&:hover": {
                      backgroundColor: "inherit",
                      boxShadow: "none",
                    },
                  }}
                >
                  {isTranslation && (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <CustomTypography1
                        sx={{ fontSize: "14px", fontWeight: 400 }}
                      >
                        {translationLanguages[index] || ""}
                      </CustomTypography1>
                      <KeyboardArrowDownSharpIcon
                        sx={{
                          height: "18px",
                          width: "18px",
                          color: "inherit", // Inherit color from the button
                          mt: "2px",
                          marginLeft: "10px",
                        }}
                      />
                    </Box>
                  )}
                </OutlinedButton>
              )}
          </Stack>

          {/* Add a divider between each invitee, but not after the last one */}
          {index !== invitees.length - 1 && (
            <Divider
              style={{
                background: "rgba(255, 255, 255, 0.15)",
                borderBottomWidth: "2px",
              }}
            />
          )}
        </React.Fragment>
      ))}
    </div>
  );
}

MeetingAttendeeDetails.propTypes = {
  isTranslation: PropTypes.bool,
  invitees: PropTypes.arrayOf(
    PropTypes.shape({
      full_name: PropTypes.string,
      email: PropTypes.string,
    })
  ),
  translationLanguages: PropTypes.array, // Add translationLanguages prop
};

export default MeetingAttendeeDetails;
