import React, { useRef, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Box, Grid, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import {
  getOpponent,
  SpeechToText,
  TranslateText,
  TextToSpeech,
} from "../../../redux/slices/MeetingSlice";
import SimplePeer from "simple-peer/simplepeer.min.js";
import { TEST_SOCKET_URL } from "../../../utils/Config-globals";
import CallNavBar from "../../businessUser/BusinessNavBar/BusinessCallNavBar";
import { PRIMARY } from "../../../theme/Palette";
import Participants from "../../businessUser/Calls/Participants";
import Transcript from "../../businessUser/Calls/Transcript";
import Translation from "../../businessUser/Calls/Translation";
import ChatBot from "../../../sections/call/ChatBot";
import TranslationPrefrence from "../../businessUser/Calls/TranslationPreference";
import CallSettings from "../../../sections/call/CallSettings";
import Chat from "../../businessUser/Calls/Chat";
import user from "../../../assets/images/Meeting/meetingImg.svg";
import Captions from "../../businessUser/Calls/Captions";
import { useLocation } from "react-router-dom";
import MicIcon from "@mui/icons-material/Mic";
import MicOffIcon from "@mui/icons-material/MicOff";

const SpeechRecognition =
  window.SpeechRecognition || window.webkitSpeechRecognition;

const stringToBase64 = (str) => {
  return btoa(unescape(encodeURIComponent(str)));
};

// const participants = [
// { id: 1, name: "User 1", isMuted: false },
// { id: 2, name: "User 2", isMuted: true },
// { id: 3, name: "User 3", isMuted: false },
// { id: 4, name: "User 4", isMuted: true },
// { id: 5, name: "User 5", isMuted: false },
// { id: 6, name: "User 6", isMuted: false },
// ];

const VideoCallApp = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const {
    translationEnabled: translationEnabledStatus,
    sourceLanguage: passedSourceLanguage = null,
    targetLanguage: passedTargetLanguage = null,
    languageCode: passedLanguageCode = null,
    name: passedName = null,
    opponentName: passedOpponentName = null,
  } = location.state || {};
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const [isVideoOn, setIsVideoOn] = useState(true);
  const [activePanel, setActivePanel] = useState(null);
  const [messages, setMessages] = useState([]);
  const [transcriptData, setTranscriptData] = useState([]);
  const [showCaptions, setShowCaptions] = useState(false);
  const isSidePanelOpen = activePanel !== null;
  const quickCallId = localStorage.getItem("quick_call_id");
  const localVideoRef = useRef(null);
  const remoteVideoRef = useRef(null);
  const peerRef = useRef(null);
  const socketRef = useRef(null);
  const localStreamRef = useRef(null);
  const recognitionRef = useRef(null);
  const recognitionActive = useRef(false);
  const currentUserId = localStorage.getItem("user_id");
  // let senderId;
  const captionsRef = useRef(null);
  const [sourceLanguage, setSourceLanguage] = useState(passedSourceLanguage);
  const [targetLanguage, setTargetLanguage] = useState(passedTargetLanguage);
  const [languageCode, setLanguageCode] = useState(passedLanguageCode);
  const [name, setName] = useState(passedName);
  const [opponentName, setOpponentName] = useState(passedOpponentName);
  const [translationEnabled, setTranslationEnabled] = useState(
    translationEnabledStatus
  );
  const [opponentJoined, setOpponentJoined] = useState(false);
  const messageQueue = useRef([]);
  const isMutedRef = useRef(false);
  // const peer = new SimplePeer({ initiator: true });
  const recognitionPaused = useRef(false);
  const audioQueue = useRef([]); // Queue to store audio files
  const isAudioPlaying = useRef(false); // Flag to track audio playback status
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 768);
  // const[Chatselected,isChatselected]=useState(false)
  console.log({ isVideoOn });
  
  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  useEffect(
    () => {
      // const savedVideoState = localStorage.getItem("isVideoOn");
      // setIsVideoOn(savedVideoState === "true");

      const quickCallId = localStorage.getItem("quick_call_id");
      const email = localStorage.getItem("email");

      if (translationEnabled === true) {
        initializeSpeechRecognition();
        setTranslationEnabled(true);
        muteRemoteOriginalVoice(true); // Mute original voice when translation is enabled
      } else {
        // Stop recognition and unmute original voice when translation is disabled
        if (recognitionRef.current && recognitionActive.current) {
          recognitionRef.current.stop();
          recognitionActive.current = false; // Update recognition state
        }
        muteRemoteOriginalVoice(false); // Unmute original voice
      }

      fetchOpponentData();
      startConnection(email, quickCallId);

      return () => {
        cleanupResources();
      };
    },
    [dispatch],
    translationEnabled
  );

  useEffect(() => {
    console.log("Opponent joined state:", opponentJoined);
  }, [opponentJoined]);

  const fetchOpponentData = async () => {
    const quickCallId = localStorage.getItem("quick_call_id");
    const email = localStorage.getItem("email");

    if (email && quickCallId) {
      try {
        const response = await dispatch(
          getOpponent({ email, quick_call_id: quickCallId })
        ).unwrap();
        const opponentData = response;
        const loggedInUser = opponentData.logged_in_user;

        const loggedInUserName = loggedInUser.full_name;
        const opponentUserName = opponentData.opponent.full_name;
        // Set source language and language code
        const receivedSourceLanguage = loggedInUser.preferred_language;
        const receivedLanguageCode = loggedInUser.language_code;

        // Set target language from opponent user
        let receivedTargetLanguage = null;
        if (opponentData.opponent) {
          const opponentUser = opponentData.opponent;
          receivedTargetLanguage = opponentUser.preferred_language;
        }
        if (opponentData.translation_status === true) {
          setTranslationEnabled(true);
        } else {
          setTranslationEnabled(false);
          console.log("Translation and speech recognition are disabled.");
        }
        if (
          receivedSourceLanguage &&
          receivedLanguageCode &&
          receivedTargetLanguage
        ) {
          setSourceLanguage(receivedSourceLanguage);
          setLanguageCode(receivedLanguageCode);
          setTargetLanguage(receivedTargetLanguage);
          setName(loggedInUserName);
          setOpponentName(opponentUserName);
          // Check translation_status and enable/disable accordingly
        } else {
          console.log("Incomplete data received from API");
        }
      } catch (error) {
        console.error("Error fetching opponent data:", error);
      }
    }
  };

  useEffect(() => {
    if (
      sourceLanguage &&
      targetLanguage &&
      languageCode &&
      name &&
      opponentName
    ) {
      if (translationEnabled === true) {
        initializeSpeechRecognition();
        muteRemoteOriginalVoice(true);
      }
    }
  }, [
    sourceLanguage,
    targetLanguage,
    languageCode,
    translationEnabled,
    name,
    opponentName,
  ]);

  const initializeSocket = (roomName) => {
    console.log(`Initializing WebSocket with room name: ${roomName}`);
    socketRef.current = new WebSocket(`${TEST_SOCKET_URL}${roomName}/`);
    socketRef.current.onopen = () => {
      console.log("WebSocket connection established.");

      // Send 'join' message to trigger the signaling process
      socketRef.current.send(
        JSON.stringify({
          type: "join",
          username: currentUserId,
          room: roomName,
        })
      );
      // Process and send all queued messages
      while (messageQueue.current.length > 0) {
        const message = messageQueue.current.shift();
        socketRef.current.send(JSON.stringify(message));
      }
    };
    socketRef.current.onmessage = (event) => {
      const data = JSON.parse(event.data);

      signalingDataHandler(data, localStreamRef.current);
    };
    socketRef.current.onclose = () => {
      console.warn("WebSocket closed. Attempting to reconnect...");
      setTimeout(() => initializeSocket(roomName), 2000); // Retry connection
    };
    socketRef.current.onerror = (error) => {
      console.error("WebSocket error:", error);
    };
  };
  // Function to send audio-message with retry if WebSocket is not open
  const sendAudioMessage = (audioContent) => {
    if (isMuted) {
      return;
    }

    const message = {
      type: "audio-message",
      audioContent: audioContent,
      senderId: currentUserId,
      targetLanguage: targetLanguage,
    };
    if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
      socketRef.current.send(JSON.stringify(message));
    } else {
      console.warn("WebSocket is not open. Queuing message for retry.");
      messageQueue.current.push(message);
      // Check and reconnect WebSocket if it's not already connecting
      if (socketRef.current.readyState !== WebSocket.CONNECTING) {
        initializeSocket(localStorage.getItem("quick_call_id"));
      }
    }
  };

  const initializeSpeechRecognition = () => {
    if (!SpeechRecognition) {
      console.error("Your browser does not support the Web Speech API.");
      return;
    }

    if (!recognitionRef.current && languageCode) {
      recognitionRef.current = new SpeechRecognition();
      recognitionRef.current.continuous = true;
      recognitionRef.current.interimResults = false;
      recognitionRef.current.lang = languageCode;

      recognitionRef.current.onstart = () => {
        if (recognitionPaused.current) {
          console.log(
            "Speech recognition started during audio playback, stopping."
          );
          recognitionRef.current.stop(); // Immediately stop if audio is playing
          return;
        }
        recognitionActive.current = true;
        console.log(
          `Speech recognition started with language: ${recognitionRef.current.lang}`
        );
        muteRemoteOriginalVoice(true);

        recognitionRef.current.onresult = (event) => {
          const transcript =
            event.results[event.results.length - 1][0].transcript;
          console.log("Transcript received:", transcript);

          if (isMutedRef.current || recognitionPaused.current) {
            return;
          }

          handleTranscript(transcript);
        };

        recognitionRef.current.onerror = (event) => {
          console.error(`Speech recognition error: ${event.error}`);
        };

        recognitionRef.current.onend = () => {
          recognitionActive.current = false;
          console.log("Speech recognition ended");

          if (!isMuted && !recognitionActive.current) {
            setTimeout(() => {
              startSpeechRecognition();
            }, 1000);
          }
        };
      };

      startSpeechRecognition();
    }
  };

  const startSpeechRecognition = () => {
    if (
      recognitionRef.current &&
      !recognitionActive.current &&
      !recognitionPaused.current &&
      !isAudioPlaying.current
    ) {
      try {
        recognitionRef.current.start();
        recognitionActive.current = true;
        console.log(
          `Speech recognition started with language: ${recognitionRef.current.lang}`
        );
      } catch (error) {
        console.warn(
          "Speech recognition already started or failed to start",
          error
        );
      }
    }
  };

  useEffect(() => {
    if (
      translationEnabled === false &&
      recognitionRef.current &&
      recognitionActive.current
    ) {
      recognitionRef.current.stop(); // Stop recognition if translation is disabled
    }
  }, [translationEnabled]);

  const handleTranscript = (transcript) => {
    if (isMuted || isMutedRef.current || recognitionPaused.current) {
      return;
    }

    if (!sourceLanguage || !targetLanguage) {
      console.warn("Source or target language not set. Skipping translation.");
      muteRemoteOriginalVoice(true);
      return;
    }

    const timestamp = new Date().toLocaleString(); // Format as needed
    setTranscriptData((prevTranscripts) => [
      ...prevTranscripts,
      {
        name: name,
        text: transcript,
        language: sourceLanguage,
        timestamp: timestamp,
      },
    ]);
    // Send the transcript as captions via WebSocket
    if (!isMuted && socketRef.current.readyState === WebSocket.OPEN) {
      socketRef.current.send(
        JSON.stringify({
          type: "captions",
          transcripts: JSON.stringify({
            name: name,
            text: transcript,
            language: sourceLanguage,
            timestamp: timestamp,
          }),
        })
      );
    }

    const base64Audio = stringToBase64(transcript);
    const quickCallId = localStorage.getItem("quick_call_id");
    const email = localStorage.getItem("email");

    dispatch(
      SpeechToText({
        meeting_type: "quick_call",
        audioContent: base64Audio,
        meeting_id: quickCallId,
        guest_email: email,
      })
    ).then((speechToTextAction) => {
      if (speechToTextAction.meta.requestStatus === "fulfilled") {
        const transcription = speechToTextAction.payload.transcription;

        if (sourceLanguage && targetLanguage) {
          dispatch(
            TranslateText({
              text: transcription,
              sourceLanguage: sourceLanguage,
              targetLanguage: targetLanguage,
            })
          ).then((translateTextAction) => {
            if (translateTextAction.meta.requestStatus === "fulfilled") {
              const translatedText = translateTextAction.payload.translatedText;

              dispatch(
                TextToSpeech({
                  text: translatedText,
                  language: targetLanguage,
                })
              ).then((textToSpeechAction) => {
                if (textToSpeechAction.meta.requestStatus === "fulfilled") {
                  const audioContent = textToSpeechAction.payload.audioContent;

                  if (!isMuted) {
                    sendAudioMessage(audioContent);
                  } else {
                    console.log("hfjofijo");
                  }
                } else {
                  console.error(
                    "Text-to-Speech failed",
                    textToSpeechAction.payload
                  );
                }
              });
            } else {
              console.error("Translation failed", translateTextAction.payload);
            }
          });
        }
      } else {
        console.error("Speech-to-Text failed", speechToTextAction.payload);
      }
    });
  };

  const playAudioContent = (audioContent) => {
    // Add audio content to the queue
    audioQueue.current.push(audioContent);
    processAudioQueue();
  };

  const processAudioQueue = () => {
    if (isAudioPlaying.current || audioQueue.current.length === 0) {
      // If audio is already playing or queue is empty, check if recognition can restart
      if (
        !isAudioPlaying.current &&
        audioQueue.current.length === 0 &&
        translationEnabled &&
        !recognitionPaused.current
      ) {
        setTimeout(() => {
          startSpeechRecognition(); // Restart speech recognition
        }, 1000); // Delay to ensure proper transition
      }
      return;
    }

    const audioContent = audioQueue.current.shift();
    try {
      if (recognitionRef.current && recognitionActive.current) {
        recognitionRef.current.stop();
        recognitionActive.current = false;
        recognitionPaused.current = true;
        console.log("Speech recognition paused for audio playback.");
      }

      const binaryString = window.atob(audioContent);
      const binaryLen = binaryString.length;
      const bytes = new Uint8Array(binaryLen);

      for (let i = 0; i < binaryLen; i++) {
        bytes[i] = binaryString.charCodeAt(i);
      }

      const audioBlob = new Blob([bytes], { type: "audio/mp3" });
      const audioUrl = URL.createObjectURL(audioBlob);
      const audio = new Audio(audioUrl);

      audio.volume = 0.5;
      isAudioPlaying.current = true;

      audio.onended = () => {
        console.log("Audio playback completed.");
        isAudioPlaying.current = false;
        recognitionPaused.current = false;

        // Restart recognition if translation is enabled
        setTimeout(() => {
          if (!isMuted && translationEnabled && !recognitionActive.current) {
            startSpeechRecognition();
          }
        }, 1000);

        processAudioQueue(); // Process next audio in the queue
      };
      audio.play();
    } catch (error) {
      console.error("Error playing audio content: ", error);
      isAudioPlaying.current = false;
      processAudioQueue();
    }
  };

  const muteRemoteOriginalVoice = (mute) => {
    if (remoteVideoRef.current && remoteVideoRef.current.srcObject) {
      const remoteStream = remoteVideoRef.current.srcObject;
      remoteStream.getAudioTracks().forEach((track) => {
        track.enabled = !mute;
      });
    }
  };

  useEffect(() => {
    if (translationEnabled === true) {
      muteRemoteOriginalVoice(true);
    }
  }, [translationEnabled]);

  const startConnection = async (username, roomName) => {
    socketRef.current = new WebSocket(`${TEST_SOCKET_URL}${roomName}/`);

    socketRef.current.onopen = () => {
      socketRef.current.send(
        JSON.stringify({
          type: "join",
          username: username,
          room: roomName,
        })
      );
    };

    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: {
          echoCancellation: true,
          noiseSuppression: true,
          autoGainControl: true,
        },
        video: true,
      });

      localStreamRef.current = stream;
      localVideoRef.current.srcObject = stream;

      if (localVideoRef.current) {
        localVideoRef.current.srcObject = stream;
      } else {
        console.warn("localVideoRef is not available");
      }

      socketRef.current.onmessage = (event) => {
        const data = JSON.parse(event.data);
        signalingDataHandler(data, stream);
      };

      socketRef.current.onerror = (error) => {
        console.error(`WebSocket error for user ${username}:`, error);
      };
    } catch (error) {
      console.error(`Stream not found for user ${username}:`, error);
    }
  };

  const signalingDataHandler = (data, stream) => {
    const peerConfig = {
      initiator: data.type === "join",
      trickle: false,
      stream: stream,
      config: {
        iceServers: [
          { urls: "stun:stun.l.google.com:19302" },
          {
            urls: "turn:your.turn.server:3478",
            username: "yourUsername",
            credential: "yourCredential",
          },
        ],
      },
    };

    // Handle different types of signaling data
    if (data.type === "offer") {
      peerRef.current = new SimplePeer(peerConfig);
      peerRef.current.on("signal", (signalData) => {
        socketRef.current.send(JSON.stringify(signalData));
      });
      peerRef.current.on("stream", (remoteStream) => {
        remoteVideoRef.current.srcObject = remoteStream;
        setOpponentJoined(true);
        if (translationEnabled === true) {
          muteRemoteOriginalVoice(true);
        }
      });
      peerRef.current.on("close", () => {
        setOpponentJoined(false);
        // Perform any other cleanup if necessary
      });
      peerRef.current.on("error", (err) => {
        if (err.message.includes("User-Initiated Abort")) {
          if (peerRef.current) {
            peerRef.current.destroy();
          }
          setOpponentJoined(false);
        }
      });
      peerRef.current.on("connectionstatechange", () => {
        if (
          peerRef.current.connectionState === "disconnected" ||
          peerRef.current.connectionState === "failed" ||
          peerRef.current.connectionState === "closed"
        ) {
          console.warn("Connection state indicates disconnection.");
          if (peerRef.current) {
            peerRef.current.destroy();
          }
          setOpponentJoined(false);
        }
      });

      peerRef.current.signal(data);
      setOpponentJoined(true);
      peerRef.current.on("connect", () => {
        setOpponentJoined(true); // Fallback for ensuring opponentJoined
      });
      // Re-fetch opponent data upon receiving offer
      fetchOpponentData();
    } else if (data.type === "answer" || data.candidate) {
      peerRef.current.signal(data);
    } else if (data.type === "audio-message") {
      if (data.senderId !== currentUserId) {
        playAudioContent(data.audioContent, data.senderId);
        muteRemoteOriginalVoice(true);
      }
    } else if (data.type === "captions") {
      const transcripts = JSON.parse(data.transcripts);
      setTranscriptData((prevTranscripts) => [...prevTranscripts, transcripts]);
    } else if (data.type === "chat-message") {
      const message = JSON.parse(data.message);
      setMessages((prevMessages) => [...prevMessages, message]);
    } else if (data.type === "join") {
      peerRef.current = new SimplePeer(peerConfig);
      peerRef.current.on("signal", (signalData) => {
        socketRef.current.send(JSON.stringify(signalData));
      });
      peerRef.current.on("stream", (remoteStream) => {
        remoteVideoRef.current.srcObject = remoteStream;
        setOpponentJoined(true);
        if (translationEnabled === true) {
          muteRemoteOriginalVoice(true);
        }
      });
      peerRef.current.on("close", () => {
        setOpponentJoined(false);
      });

      peerRef.current.on("error", (err) => {
        console.error("Peer error:", err);
        if (err.message.includes("User-Initiated Abort")) {
          console.warn("Error: User-Initiated Abort. Cleaning up resources.");
          if (peerRef.current) {
            peerRef.current.destroy();
          }
          setOpponentJoined(false);
        }
      });

      peerRef.current.on("connectionstatechange", () => {
        console.log("Connection state:", peerRef.current.connectionState);
        if (
          peerRef.current.connectionState === "disconnected" ||
          peerRef.current.connectionState === "failed" ||
          peerRef.current.connectionState === "closed"
        ) {
          console.warn("Connection state indicates disconnection.");
          if (peerRef.current) {
            peerRef.current.destroy();
          }
          setOpponentJoined(false);
        }
      });

      setOpponentJoined(true);
      // Re-fetch opponent data upon receiving join
      fetchOpponentData();
    }
  };

  const formatTime = (date) => {
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 || 12;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    return `${formattedHours}:${formattedMinutes} ${ampm}`;
  };

  const sendMessage = (text) => {
    const message = {
      text,
      time: formatTime(new Date()),
      senderId: localStorage.getItem("user_id"),
      outgoing: true,
    };

    setMessages((prevMessages) => [...prevMessages, message]);

    if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
      socketRef.current.send(
        JSON.stringify({
          type: "chat-message",
          message: JSON.stringify(message),
        })
      );
    } else {
      console.warn("WebSocket is not open. Can't send message.");
    }
  };

  useEffect(() => {
    if (localStreamRef.current) {
      localStreamRef.current.getAudioTracks().forEach((track) => {
        track.enabled = !isMuted;
      });
    }
  }, [isMuted]);

  const toggleMute = () => {
    setIsMuted((prevIsMuted) => {
      // Toggle mute state
      const newMuteState = !prevIsMuted;

      // Update the ref immediately
      isMutedRef.current = newMuteState;

      if (localStreamRef.current) {
        // Apply mute state to the audio track
        localStreamRef.current.getAudioTracks().forEach((track) => {
          track.enabled = !newMuteState;
        });
      }

      if (translationEnabled) {
        if (newMuteState) {
          // Stop speech recognition when muted
          if (recognitionRef.current) {
            recognitionRef.current.stop();
            recognitionActive.current = false;
          }
          console.log("Speech recognition stopped due to mute.");
        } else {
          startSpeechRecognition();
          console.log("Speech recognition started due to unmute.");
        }
      }

      return newMuteState;
    });
  };

  useEffect(() => {
    if (localStreamRef.current) {
      const videoTracks = localStreamRef.current.getVideoTracks(); // Get video tracks from the stream
      videoTracks.forEach((track) => {
        track.enabled = isVideoOn; // Enable/disable video based on `isVideoOn`
      });
    }

    if (localVideoRef.current) {
      localVideoRef.current.srcObject = localStreamRef.current;
    }
  }, [isVideoOn]);

  useEffect(() => {
    if (localStreamRef.current && localVideoRef.current) {
      localVideoRef.current.srcObject = localStreamRef.current;
    }
    // if (remoteVideoRef.current && !remoteVideoRef.current.srcObject) {
    //   // Only set the remote stream if it hasn't been set already
    //   remoteVideoRef.current.srcObject = remoteStream; // Ensure remoteStream is accessible
    // }
  }, [isSidePanelOpen, isMobileView]);

  const toggleVideo = async () => {
    if (!localStreamRef.current) return;
    setIsVideoOn((prevIsVideoOn) => !prevIsVideoOn);
  };

  console.log({ activePanel });

  const handleToggleChat = () => {
    setActivePanel(activePanel === "chat" ? null : "chat");
    setIsChatOpen((prev) => !prev);
  };

  const handleToggleParticipants = () => {
    setActivePanel(activePanel === "participants" ? null : "participants");
  };

  const handleToggleTranscript = () => {
    setActivePanel(activePanel === "transcript" ? null : "transcript");
  };

  const handleToggleTranslation = () => {
    setActivePanel(activePanel === "translation" ? null : "translation");
  };

  const handleToggleChatBot = () => {
    setActivePanel(activePanel === "chatBot" ? null : "chatBot");
  };

  const handleCloseChat = () => {
    setActivePanel(null);
    setIsChatOpen(false)

  };

  const handleCloseParticipants = () => {
    setActivePanel(null);
  };

  const handleCloseTranscript = () => {
    setActivePanel(null);
  };

  const handleCloseTranslation = () => {
    setActivePanel(null);
  };

  const handleToggleCaptions = () => {
    setShowCaptions((prev) => !prev);
  };

  const renderStepContent = () => {
    switch (activePanel) {
      case "chat":
        return (
          <Chat
            onClose={(value)=>{handleCloseChat(value)}}
            messages={messages}
            sendMessage={sendMessage}
            userId={localStorage.getItem("user_id")}
            translationEnabled={translationEnabled}
          />
        );
      case "participants":
        return (
          <Participants
            setShowParticipants={handleCloseParticipants}
            translationEnabled={translationEnabled}
          />
        );
      case "callSettings":
        return <CallSettings setActivePanel={setActivePanel} />;
      case "translationPreference":
        return (
          <TranslationPrefrence
            quickCallId={quickCallId}
            setActivePanel={setActivePanel}
          />
        );
      case "transcript":
        return (
          <Transcript
            quickCallId={quickCallId}
            setShowTranscript={handleCloseTranscript}
            transcriptData={transcriptData}
            name={name}
            setTranscriptData={setTranscriptData}
          />
        );
      case "translation":
        return (
          <Translation
            quickCallId={quickCallId}
            setShowTranslation={handleCloseTranslation}
          />
        );
      case "chatBot":
        return (
          <ChatBot
            quickCallId={quickCallId}
            setShowChatBot={handleCloseTranslation}
          />
        );
      default:
        return null;
    }
  };

  // const renderVideoGrid = () => {
  //   const numberOfParticipants = participants.length;

  //   // Utility function to get grid size based on number of participants
  //   const getGridSizeForIndex = (index) => {
  //     if (numberOfParticipants === 1) return 12;
  //     if (numberOfParticipants === 2) return 6;
  //     if (numberOfParticipants === 3) {
  //       return index < 2 ? 6 : 12; // 2 participants on top, 1 centered below
  //     }
  //     if (numberOfParticipants === 4) return 6; // 2 rows, 2 users per row
  //     if (numberOfParticipants === 5) {
  //       return index < 3 ? 4 : 6; // 3 users on top, 2 centered below
  //     }
  //     return 4; // Default: For 6 or more participants, use 3 columns per row
  //   };

  //   return participants.map((participant, index) => (
  //     <Grid
  //       item
  //       xs={getGridSizeForIndex(index)}
  //       key={participant.id}
  //       style={{
  //         display: "flex",
  //         justifyContent: "center",
  //         alignItems: "center",
  //         height: numberOfParticipants > 2 ? "290px" : "630px", // Only set height if more than 2 participants
  //       }}
  //     >
  //       <Box
  //         style={{
  //           position: "relative",
  //           width: "100%",
  //           height: "100%", // This ensures equal height when height is set
  //           backgroundColor: "black",
  //           borderRadius: "10px",
  //           display: "flex",
  //           flexDirection: "column",
  //           justifyContent: "center",
  //           alignItems: "center",
  //         }}
  //       >
  //         {isVideoOn ? (
  //           <video
  //             ref={index === 0 ? localVideoRef : null} // The first video is the local user
  //             autoPlay
  //             muted={index === 0} // Mute the local video
  //             style={{
  //               width: "100%",
  //               height: "100%",
  //               borderRadius: "10px",
  //               objectFit: "cover",
  //             }}
  //           />
  //         ) : (
  //           <img
  //             src={user}
  //             alt="Profile"
  //             style={{
  //               width: "100%",
  //               height: "100%",
  //               borderRadius: "10px",
  //               objectFit: "cover",
  //             }}
  //           />
  //         )}

  //         <Box
  //           position="absolute"
  //           bottom="16px"
  //           left="3%"
  //           transform="translateX(-50%)"
  //           display="flex"
  //           alignItems="center"
  //           color="white"
  //         >
  //           <Typography
  //             sx={{
  //               fontFamily: "Lato",
  //               fontSize: "14px",
  //               fontWeight: 700,
  //               bgcolor: "rgba(0, 0, 0, 0.7)",
  //               borderRadius: "8px",
  //               paddingRight: "10px",
  //               padding: "10px",
  //               display: "flex",
  //               alignItems: "center",
  //             }}
  //           >
  //             {participant.name}
  //           </Typography>
  //           <Box
  //             sx={{
  //               fontFamily: "Lato",
  //               fontSize: "14px",
  //               fontWeight: 700,
  //               bgcolor: "rgba(0, 0, 0, 0.7)",
  //               borderRadius: "8px",
  //               display: "flex",
  //               alignItems: "center",
  //               marginLeft: "10px",
  //             }}
  //           >
  //             <IconButton color="inherit" sx={{ ml: 1 }}>
  //               <MicIcon />
  //             </IconButton>
  //             <Typography
  //               sx={{
  //                 fontFamily: "Lato",
  //                 fontSize: "14px",
  //                 fontWeight: 700,
  //                 paddingRight: "10px",
  //               }}
  //             >
  //               {participant.isMuted ? "english" : "german"}
  //             </Typography>
  //           </Box>
  //         </Box>
  //       </Box>
  //     </Grid>
  //   ));
  // };

  const cleanupResources = () => {
    if (peerRef.current) {
      peerRef.current.destroy();
    }
    if (socketRef.current) {
      socketRef.current.close();
    }
    if (recognitionRef.current && recognitionActive.current) {
      recognitionRef.current.stop();
    }
    if (localStreamRef.current) {
      localStreamRef.current.getTracks().forEach((track) => track.stop());
    }
  };

  return (
    <Box style={{ backgroundColor: "rgba(66, 66, 77, 1)", height: "92vh" }}>
      <CallNavBar
        toggleMute={toggleMute}
        isMuted={isMuted}
        toggleVideo={toggleVideo}
        isVideoOn={isVideoOn}
        setIsVideoOn={setIsVideoOn}
        handleToggleTranscript={handleToggleTranscript}
        handleToggleTranslation={handleToggleTranslation}
        handleToggleChatBot={handleToggleChatBot}
        handleToggleChat={handleToggleChat}
        handleToggleParticipants={handleToggleParticipants}
        handleToggleCaptions={handleToggleCaptions}
        setActivePanel={setActivePanel}
        translationEnabled={translationEnabled}
        isChatOpen={isChatOpen}
      />
      <Grid container style={{ height: "calc(100% - 64px)" }}>
        {}
        <Grid
          item
          xs={isSidePanelOpen ? 9 : 12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
          }}
        >
          {/* <Grid container spacing={2} style={{ padding: "16px" }}>
         {renderVideoGrid()}
         <Captions
           isOpen={showCaptions}
           quickCallId={quickCallId}
           ref={captionsRef}
           setShowCaptions={setShowCaptions}
         />
       </Grid> */}
          {!(isSidePanelOpen && isMobileView) && (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              gap={2}
              style={{ height: "100%", width: "100%" }}
              sx={{
                flexDirection: isMobileView ? "column" : "row",
                justifyContent: isMobileView && opponentJoined ? "flex-start" : "center",
              }}
            >
              <Box
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "relative",
                }}
              >
                {isVideoOn ? (
                  <video
                    ref={localVideoRef}
                    autoPlay
                    muted
                    style={{
                      width: opponentJoined
                        ? "100%"
                        : isMobileView
                        ? "100%"
                        : "50%",
                      // width:  "100%",
                      height: isMobileView ? "100%" : " 42rem",
                      backgroundColor: "black",
                      borderRadius: "10px",
                      objectFit: "cover",
                      OverflowY:"hidden"
                    }}
                  />
                ) : (
                  <img
                    src={user}
                    alt="Profile"
                    style={{
                      // width:  "100%",
                      width: opponentJoined ? "299px" : "299px",
                      height: isMobileView ? "50%" : " 299px",
                      borderRadius: "10px",
                      objectFit: "cover",
                    }}
                  />
                )}
                <Box
                  position="absolute"
                  bottom="16px"
                  left="3%"
                  transform="translateX(-50%)"
                  display="flex"
                  alignItems="center"
                  color="white"
                >
                  <Typography
                    sx={{
                      fontFamily: "Lato",
                      fontSize: "14px",
                      fontWeight: 700,
                      bgcolor: "rgba(0, 0, 0, 0.7)",
                      borderRadius: "8px",
                      paddingRight: "10px",
                      display: "flex",
                      alignItems: "center",
                      padding: "10px",
                    }}
                  >
                    {name}
                  </Typography>
                  <Box
                    sx={{
                      fontFamily: "Lato",
                      fontSize: "14px",
                      fontWeight: 700,
                      bgcolor: "rgba(0, 0, 0, 0.7)",
                      borderRadius: "8px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginLeft: "10px",
                      cursor: "default",
                      height: "40px",
                    }}
                  >
                    <Box sx={{ mx: 1 }}>
                      {isMuted ? <MicOffIcon /> : <MicIcon />}
                    </Box>
                    {translationEnabled && (
                      <Typography
                        sx={{
                          fontFamily: "Lato",
                          fontSize: "14px",
                          fontWeight: 400,
                          paddingRight: "10px",
                        }}
                      >
                        {sourceLanguage}
                      </Typography>
                    )}
                  </Box>
                </Box>
              </Box>
              {opponentJoined && (
                <Box
                  style={{
                    width: "100%",
                    height: isMobileView ? "50%" : " 100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "relative",
                  }}
                >
                  <video
                    ref={remoteVideoRef}
                    autoPlay
                    style={{
                      width: "100%",
                      height: isMobileView ? "auto" : " 100%",
                      backgroundColor: "black",
                      borderRadius: "10px",
                      objectFit: "cover",
                    }}
                  />
                  <Box
                    position="absolute"
                    bottom="16px"
                    left="3%"
                    transform="translateX(-50%)"
                    display="flex"
                    alignItems="center"
                    color="white"
                  >
                    <Typography
                      sx={{
                        fontFamily: "Lato",
                        fontSize: "14px",
                        fontWeight: 700,
                        bgcolor: "rgba(0, 0, 0, 0.7)",
                        borderRadius: "8px",
                        paddingRight: "10px",
                        display: "flex",
                        alignItems: "center",
                        padding: "10px",
                      }}
                    >
                      {opponentName}
                    </Typography>
                    <Box
                      sx={{
                        fontFamily: "Lato",
                        fontSize: "14px",
                        fontWeight: 700,
                        bgcolor: "rgba(0, 0, 0, 0.7)",
                        borderRadius: "8px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginLeft: "10px",
                        cursor: "default",
                        height: "40px",
                      }}
                    >
                      <Box sx={{ mx: 1 }}>
                        <MicIcon />
                      </Box>
                      {translationEnabled && (
                        <Typography
                          sx={{
                            fontFamily: "Lato",
                            fontSize: "14px",
                            fontWeight: 700,
                            paddingRight: "10px",
                          }}
                        >
                          {targetLanguage}
                        </Typography>
                      )}
                    </Box>
                  </Box>
                </Box>
              )}
              <Captions
                isOpen={showCaptions}
                quickCallId={quickCallId}
                ref={captionsRef}
                transcriptData={transcriptData}
                name={name}
                setShowCaptions={setShowCaptions}
                setTranscriptData={setTranscriptData}
              />
            </Box>
          )}
        </Grid>

        {isSidePanelOpen && (
          <Grid
            item
            xs={12}
            md={3}
            xl={3}
            style={{
              backgroundColor: PRIMARY.light,
              padding: 16,
              minHeight: 675,
            }}
          >
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              height="100%"
              overflow="auto"
              sx={{
                "&::-webkit-scrollbar": {
                  display: "none",
                },
                msOverflowStyle: "none",
                scrollbarWidth: "none",
              }}
            >
              {renderStepContent()}
              {console.log({ isSidePanelOpen })}
            </Box>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

VideoCallApp.propTypes = {
  meetingId: PropTypes.string.isRequired,
};

export default VideoCallApp;
