import React, { useState, useEffect, forwardRef } from "react";
import {
  Box,
  Typography,
  IconButton,
  Autocomplete,
  TextField,
  Avatar,
  Button,
  useTheme,
  Dialog,
  DialogTitle,
  DialogContent,
  InputLabel,
  DialogActions,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { fetchLanguageDropdown } from "../../../redux/slices/MeetingSlice";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import user from "../../../assets/images/videocall/girlonline.jpg";
import user2 from "../../../assets/images/videocall/men-img-a.jpg";
import user3 from "../../../assets/images/videocall/men-img-b.jpg";
import user4 from "../../../assets/images/videocall/girl-img-a.jpg";
import { PRIMARY } from "../../../theme/Palette";
import { CloseButton, OutlinedButton } from "../../../components/Butons/CustomButton";
import { CustomTypography4 } from "../../../components/Typography/Typography";
import CreateIcon from '@mui/icons-material/Create';
import { ToastContainer, toast } from "react-toastify"; // Import toast components
import "react-toastify/dist/ReactToastify.css";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import MeetingDetails from "../../../pages/businessUser/BusinessMeettingParticipants";
import SyncIcon from '@mui/icons-material/Sync';
const participants = [
  {
    name: "Marvin Lambert",
    language: "English",
    insideMeeting: true,
    mic: true,
    avatar: user,
  }, //avatar for img for users
  {
    name: "Teresa Lloyd",
    language: "Spanish",
    insideMeeting: true,
    mic: false,
    avatar: user2,
  },
  {
    name: "Fred Haynes",
    language: "Spanish",
    insideMeeting: true,
    mic: true,
    avatar: user3,
  },
  {
    name: "Rose Peters",
    language: "Spanish",
    insideMeeting: true,
    mic: true,
    avatar: user4,
  },
  {
    name: "Marvin Lambert",
    language: "English",
    insideMeeting: false,
    avatar: user2,
  },
];

const Translation = forwardRef(({ quickCallId, setShowTranslation }) => {
  const dispatch = useDispatch();
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const { languageDropdown, isLoading } = useSelector((state) => state.meeting);
  const theme = useTheme();
  //
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  //for validation
  const [error, setError] = useState(false); // Validation error state
  const [timer, setTimer] = useState(null); // Timer state
  //
  const [isRequested, setIsRequested] = useState(false); // Tracks if the request was submitted


  // Function to open the modal
  const handleOpen = () => {
    setOpen(true);
  };

  // Function to close the modal
  const handleClose = () => {
    setOpen(false);
    setMessage(""); // Clear message on close
    setError(false); // Clear error on close
    clearTimeout(timer); // Clear any active timer
  };

  // Function to handle submit
  const handleSubmit = () => {
    if (!message.trim()) {

      setError(true);
      // Start a 5-second timer
      const timeout = setTimeout(() => {
        setError(false);
        setMessage("");

      }, 1000);

      setTimer(timeout); // Store the timer reference
      return;
    }



    console.log("Message:", message); // Replace this with your API call logic
    setIsRequested(true); // Update the state to mark the request as submitted
    setOpen(false);
    setMessage(""); // Clear the message after successful submission
    setError(false); // Reset error
  };

  // invite call modal
  const [invitees, setInvitees] = useState([]);
  const [openMeetingDetails, setOpenMeetingDetails] = useState(false);
  //for call
  const handleMakeCallNow = () => {
    const updatedInvitees = [
      ...invitees,
      { email: "Jhohana steves@gmail.com", name: "Anju R" },
    ];

    // Update state and open the modal
    setInvitees(updatedInvitees);
    setOpenMeetingDetails(true); // Open Meeting Details modal
  };
  // Handler to close the Meeting Details modal
  const handleCloseMeetingDetails = () => {
    setOpenMeetingDetails(false);
  };


  const [openConfirmModal, setOpenConfirmModal] = useState(false);

  // Function to open the modal
  const handleOpenConfirmModal = () => {
    setOpenConfirmModal(true);
  };

  // Function to close the modal
  const handleCloseConfirmModal = () => {
    setOpenConfirmModal(false);
  };


  const handleUpdate = () => {
    toast.success("updated sucessfully.", {
      style: {
        fontWeight: 600,
        fontSize: "16px",
        lineHeight: "27px",
        fontFamily: "Lato",
        backgroundColor: "rgba(34, 37, 38, 1)",
        color: 'rgba(255, 255, 255, 1)'
      },
      autoClose: 2000,

    });

  };

  useEffect(() => {
    if (quickCallId) {
      dispatch(fetchLanguageDropdown({ meeting_id: quickCallId }))
        .then((res) => {
          const fetchedLanguages = res.payload;
          const originalLanguage = fetchedLanguages.find(
            (lang) => lang.name === "Original (Without Translation)"
          );
          if (originalLanguage) {
            setSelectedLanguage(originalLanguage); // Set original language as default
          }
        })
        .catch((err) => {
          console.error("Error fetching languages", err);
        });
    }
  }, [quickCallId, dispatch]);

  if (isLoading && !languageDropdown.length) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100%"
      >
        <Typography variant="body1">Loading...</Typography>
      </Box>
    );
  }
  //

  const languageOptions = languageDropdown?.filter((lang) => lang.name) || [];












  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        padding: "16px 16px 0",
        color: "#FFF",
        borderRadius: "8px",
        boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
        display: "flex",
        flexDirection: "column", justifyContent: "space-between",
      }}
    >

      <ToastContainer
        closeButton={false}
        toastStyle={{
          backgroundColor: PRIMARY.light,
          color: "#fff", // Custom text color
        }}
      />
      <Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between", // Places the IconButton on the right side
            width: "100%", // Make the container take full width
          }}
        >
          <Typography
            variant="h5"
            sx={{
              fontWeight: 600,
              fontSize: "18px",
              lineHeight: "27px",
              fontFamily: "Lato",
              color: "rgba(255, 255, 255, 0.9)",
            }}
          >
            Translation
          </Typography>
          <IconButton
            size="small"
            sx={{
              color: "white",
              border: "1px solid #444",
              borderRadius: "10px",
              width: "36px",
              height: "36px",
            }}
            onClick={() => setShowTranslation(false)}
          >
            <CloseIcon
              sx={{
                height: "18px",
                width: "18px",
                color: "rgba(255, 255, 255, 0.5)",
              }}
            />
          </IconButton>
        </Box>

        {/* Language Selection Dropdown */}
        <Box mt={3}>
          <Typography
            variant="body2"
            sx={{
              color: "rgba(255, 255, 255, 0.5)",
              fontSize: "14px",
              lineHeight: "21px",
              fontFamily: "Lato",
            }}
          >
            Your preferred language
          </Typography>
          <Autocomplete
            fullWidth
            options={languageOptions}
            getOptionLabel={(option) => option.name || ""}
            value={selectedLanguage}
            onChange={(event, newValue) => setSelectedLanguage(newValue)}
            clearIcon={null} // Hide the close icon
            popupIcon={
              <KeyboardArrowDownOutlinedIcon
                sx={{
                  color: "rgba(255, 255, 255, 0.5)",
                  width: "18px",
                  height: "18px",
                }}
              />
            }
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Select Language"
                variant="standard"
                InputProps={{
                  ...params.InputProps,
                  style: {
                    paddingBlock: "10px",
                    fontSize: "14px",
                    fontFamily: "Lato",
                  },
                }}
                sx={{
                  "& .MuiInputBase-input::placeholder": {
                    fontSize: "15px", // Set placeholder font size
                    fontWeight: 500, // Set placeholder font weight
                    color: "rgba(255, 255, 255, 0.5)", // Set placeholder color
                  },

                  // Underline styles
                  "& .MuiInput-underline:before": {
                    borderBottomColor: "rgba(245, 245, 250, 0.4)", // Default underline color
                  },
                  "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                    borderBottomColor: "rgba(245, 245, 250, 0.4)", // Underline color on hover
                  },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: "rgba(245, 245, 250, 0.4)", // Underline color when focused
                  },
                  // Icon styles
                  "& .MuiSvgIcon-root": {
                    color: "rgba(255, 255, 255, 0.5)", // Set icon color
                    width: "18px",
                    height: "18px",
                  },
                }}
              />
            )}
          />
        </Box>

        <Box sx={{ marginTop: "30px" }}>
          <Box sx={{ mb: 2 }}>
            <Typography
              variant="body2"
              sx={{
                color: "rgba(255, 255, 255, 0.5)",
                fontSize: "14px",
                lineHeight: "21px",
                fontFamily: "Lato",
                marginBottom: "10px",
              }}
            >
              Language prefernce
              <CreateIcon sx={{
                height: "18px",
                width: "18px", color: "white",
                ml: 19, cursor: "pointer"
              }}

                onClick={handleMakeCallNow}
              />

            </Typography>
          </Box>

          {participants
            .filter((p) => p.insideMeeting)
            .map((participant, index) => (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  background: PRIMARY.darker,
                  padding: "10px",
                  marginBottom: "1px",
                  marginX: "-15px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Avatar
                    sx={{
                      marginRight: 2,
                      height: "36px",
                      width: "36px",
                      borderRadius: "10px",
                    }}
                    variant="rounded"
                    src={participant.avatar}
                  >
                    {participant.name.charAt(0)}
                  </Avatar>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                    }}
                  >
                    <Typography
                      variant="body1"
                      sx={{
                        fontWeight: 700,
                        fontSize: "14px",
                        fontFamily: "Lato",
                      }}
                    >
                      {participant.name}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        fontWeight: 400,
                        fontSize: "14px",
                        fontFamily: "Lato",
                      }}
                      color="gray"
                    >
                      {participant.language}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            ))}
        </Box>
      </Box>
      <Box>
        <Box
          sx={{
            marginTop: "30px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            // backgroundColor: "#222526",
            backgroundColor: "#A1A1A11A",
            borderRadius: "4px",
            height: "48px",
            width: "100%",
            padding: "0 10px",
            cursor: isRequested ? "default" : "pointer", // Disable cursor if requested
          }}
          onClick={!isRequested ? handleOpen : null} // Prevent clicking again if requested
        >

          <Typography
            sx={{ fontWeight: 400, fontSize: "12px", fontFamily: "Lato" }}
          >

            10/50 hours Left
          </Typography>
          <Typography
            sx={{ fontWeight: 400, fontSize: "12px", fontFamily: "Lato", color: isRequested ? "white" : "inherit", }}
          >
            {isRequested ? "Requested" : "Buy More"} {/* Conditional text */}
            {isRequested && (
              <SyncIcon
                sx={{
                  height: "18px", width: "18px"
                }}
              />
            )}
          </Typography>

        </Box>

        <Button
          variant="contained"
          sx={{
            textTransform: "none",
            width: "100%",
            borderRadius: "8px",
            marginTop: "10px",
            padding: "8px 40px",
            background: theme.palette.primary.info,
            color: theme.palette.primary.white,
            fontSize: "16px",
            fontFamily: "Lato",
            ":hover": {
              background: "#007CD3",
              color: theme.palette.primary.white,
            },

          }}
          onClick={handleUpdate}
        >
          Update
        </Button>
        <OutlinedButton
          sx={{
            px: 3,
            height: "46px",
            width: "100%",
            marginTop: "10px",
            backgroundColor: "#A1A1A11A",
            border: "none",
          }}
          onClick={handleOpenConfirmModal}
        >
          <CustomTypography4 sx={{ fontSize: "16px" }}>
            Disable Translation
          </CustomTypography4>
        </OutlinedButton>

      </Box>
      <Dialog open={openConfirmModal} onClose={handleCloseConfirmModal} maxWidth="sm" fullWidth>
        <Box p={2} bgcolor={"#222526"}>
          <DialogTitle>
            <Box sx={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "20px",
              alignItems: "center",
            }}>
              <CustomTypography4
                sx={{
                  fontWeight: 700,
                  fontSize: "16px",
                  lineHeight: "24px",
                  color: "#FFFFFF",
                }}
              ></CustomTypography4>

              <CloseButton
                onClick={handleCloseConfirmModal}
                sx={{
                  width: "36px",
                  height: "36px",
                  border: "1px solid rgba(255, 255, 255, 0.5)", // Add border to close button
                }}
              >
                <CloseIcon
                  sx={{
                    color: "rgba(255, 255, 255, 0.5)",
                    width: "18px",
                    height: "18px",
                  }}
                />
              </CloseButton>
            </Box>
          </DialogTitle>

          <DialogContent sx={{ textAlign: "center" }}>
            <Typography
              sx={{
                color: "white",
                fontSize: "26px",
                lineHeight: "27px",
                fontWeight: 700,
                fontFamily: "Lato",
                paddingBottom: "20px",
              }}
            >
              Are you sure
            </Typography>

            <InputLabel
              htmlFor="input-with-icon-textfield"
              sx={{
                color: "rgba(128, 131, 163, 1)",
                fontSize: "14px",
                lineHeight: "21px",
                fontWeight: 400,
                fontFamily: "Lato",
                paddingBottom: "20px",
              }}
            >
              Make sure your action is correct.
            </InputLabel>
          </DialogContent>
          <DialogActions>
            <Box
              mt={4}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Button
                onClick={handleCloseConfirmModal}
                sx={{
                  width: "213px",
                  height: "52px",
                  backgroundColor: "rgba(161, 161, 161, 0.1)",
                  borderRadius: "10px",
                  color: "rgba(255, 255, 255, 1)",
                  fontWeight: 700,
                  fontSize: '16px',
                  textTransform: 'none',
                  fontFamily: 'Lato'
                }}
              >
                No
              </Button>

              <Button
                variant="contained"
                sx={{
                  textTransform: 'none',
                  width: "213px",
                  height: "52px",
                  fontFamily: "Lato",
                  fontWeight: 700,
                  fontSize: "16px",
                  borderRadius: "10px",
                  background: "#FF808B",
                  marginLeft: "20px",
                  color: "rgba(255, 255, 255, 1)",
                  "&:hover": {
                    backgroundColor: "#e76b77",
                  },
                }}
                onClick={handleCloseConfirmModal}
              >
                Yes, Close
              </Button>
            </Box>
          </DialogActions>
        </Box>


      </Dialog>

      <Dialog
        open={openMeetingDetails}
        onClose={handleCloseMeetingDetails}
        wdth="600px"
        fullWidth
      >
        <Box p={2} bgcolor={"#222526"}>
          <MeetingDetails
            onBack={handleCloseMeetingDetails}
            invitees={invitees}
            setInvitees={setInvitees}
            setOpenQuickCall={setOpenMeetingDetails}
          />
        </Box>
      </Dialog>

      {/*  */}
      {/* Upgrade Modal */}
      {/* Modal */}
      <Box>
        {/* Modal */}
        <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth sx={{ backgroundColor: "transparent" }}>
          <Box p={1} bgcolor={"#222526"}>
            <DialogTitle>
              <Box sx={{ display: "flex", justifyContent: "space-between", marginBottom: "20px", alignItems: "center" }}>
                <Typography
                  sx={{
                    fontSize: "16px",
                    lineHeight: "24px",
                    color: "#FFFFFF",
                    fontFamily: "Lato",
                    fontWeight: 700,
                  }}
                >
                  Request to upgrade plan
                </Typography>
                <CloseButton
                  onClick={handleClose}
                  sx={{ height: "36px", width: "36px", borderRadius: "10px" }}
                >
                  <CloseRoundedIcon sx={{ color: "rgba(255, 255, 255, 0.5)", height: "18px", width: "18px" }} />
                </CloseButton>
              </Box>
            </DialogTitle>

            <DialogContent sx={{ mt: 5 }}>
              <Typography
                sx={{
                  color: "rgba(255, 255, 255, 0.5)",
                  fontSize: "16px",
                  fontFamily: "Lato",
                }}
              >
                Type a message
              </Typography>
              <TextField
                fullWidth
                variant="standard"
                placeholder=""
                multiline
                maxRows={4}
                value={message}
                onChange={(e) => {
                  setMessage(e.target.value);
                  setError(false); // Clear the error when typing
                }}
                InputProps={{
                  disableUnderline: false,
                  sx: {
                    fontSize: "16px",
                    fontFamily: "Lato",
                    color: "white",
                    "&::placeholder": { color: "rgba(255, 255, 255, 0.5)" },
                  },
                }}
                sx={{
                  input: { color: "white" },
                  "& .MuiInput-underline:before": { borderBottomColor: "rgba(255, 255, 255, 0.5)" },
                  "& .MuiInput-underline:hover:not(.Mui-disabled):before": { borderBottomColor: "white" },
                  "& .MuiInput-underline:after": { borderBottomColor: "white" },
                }}
              />
              {error && (
                <Typography
                  sx={{
                    color: "red",
                    fontSize: "14px",
                    fontFamily: "Lato",
                    marginTop: "8px",
                  }}
                >
                  Input required
                </Typography>
              )}
              <Box>
                <DialogActions
                  sx={{
                    mt: 5,
                    backgroundColor: "#222526",
                    display: "flex", // Flex layout
                    justifyContent: "space-between", // Space between buttons
                    alignItems: "center", // Vertically align buttons
                  }}
                >
                  {/* Cancel Button - Aligned to the Left */}
                  <OutlinedButton
                    onClick={handleClose}
                    sx={{
                      fontSize: "16px",
                      fontFamily: "Lato",
                      borderRadius: "10px",
                      p: 2,
                      height: "46px",
                      textTransform: "none",
                      width: "124px",
                    }}
                  >
                    Cancel
                  </OutlinedButton>

                  {/* Request Button - Aligned to the Right */}
                  <Button
                    onClick={handleSubmit}
                    variant="contained"
                    sx={{
                      fontSize: "16px",
                      fontFamily: "Lato",
                      background: "rgba(0, 146, 248, 1)",
                      borderRadius: "10px",
                      px: 4,
                      width: "164px",
                      height: "46px",
                      color: "white",
                      textTransform: "none",
                      "&:hover": {
                        backgroundColor: "#007CD3",
                      },
                    }}
                  >
                    Request
                  </Button>
                </DialogActions>
              </Box>
            </DialogContent>
          </Box>
        </Dialog>
      </Box>


    </Box>
  );
});

Translation.displayName = "Translation";

Translation.propTypes = {
  quickCallId: PropTypes.string.isRequired,
  setShowTranslation: PropTypes.func.isRequired,
};

export default Translation;
