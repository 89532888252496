import React, { useState } from "react";
import PropTypes from "prop-types";
import PropsTypes from "prop-types";
import {
  Box,
  Button,
  Dialog,
  Grid,
  Typography,
  Paper,
  Divider,
  Tabs,
  Tab,
  useTheme,
  IconButton,
} from "@mui/material";
import StarsIcon from "@mui/icons-material/Stars";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/system";
import {
  CustomSubtitle,
  CustomTypography1,
} from "../../../../../components/Typography/Typography";
import CheckoutModal from "../../../../BusinessAdmin/SubscriptionPage/CheckOutModal";
//import { CloseButton } from "../../../components/Butons/CustomButton";

const ModalContainer = styled(Box)(() => ({
  backgroundColor: "#222526",
  borderRadius: "12px",
  boxShadow: "0px 6px 16px rgba(153, 155, 168, 0.1)",
  color: "#fff",
  padding: "24px 32px",
}));

const ModalHeader = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  paddingBottom: "3rem",
}));

const ModalContent = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "16px",
  marginBottom: "30px",
}));




const AddOnCard = ({
  title,
  price,
  features,
  buttonText,
  onClick,
  isPopular,
  theme,
  label,
}) => (
  <Paper
    elevation={3}
    sx={{
      mt: 2,
      minHeight: "400px",
      boxShadow: "none",
      borderRadius: "8px",
      position: "relative",
      border: `1px solid rgba(255, 255, 255, 0.15)`,
      marginBottom: "30px",
      background: theme.palette.background.default,
      backgroundColor: "rgba(34,37,38,1)",
    }}
  >
    <Box p={3}>
      <Typography
        variant="h6"
        sx={{
          color: "white",
          fontWeight: 500,
          fontSize: "16px",
          fontFamily: "Lato",
        }}
      >
     {title}
      </Typography>
    </Box>
    <Divider sx={{ backgroundColor: "#444" }} />
    <Box p={3}>
      {isPopular && (
        <CustomSubtitle
          sx={{
            textAlign: "left",
            my: 2,
            color: "rgba(255, 255, 255, 0.5)",
            fontWeight: 300,
            fontFamily: "Lato",
            
          }}
        >
          {label}
        </CustomSubtitle>
      )}

      <CustomTypography1
        sx={{
          mt: 3,
          fontSize: "26px",
          fontFamily: "Lato",
          fontWeight: 600,
          textAlign: "left",
          display: "flex",
          alignItems: "center", // Align items in the same line
          justifyContent: "space-between",
          position: "relative",
          mb: 2,
          "&::after": isPopular
            ? {
                content: '""',
                display: "block",
                height: "2px",
                width: "100%",
                backgroundColor: "green",
                position: "absolute",
                bottom: "-2px",
              }
            : {},
        }}
      >
        <Typography
          sx={{
            display: "flex",
            alignItems: "center",
            fontSize: "26px",
            fontFamily: "Lato",
          }}
        >
          {!isPopular && (
            <StarsIcon
              sx={{ width: "25px", height: "25px", marginRight: "7px" }}
            />
          )}
          {features}
        </Typography>

        {isPopular && <StarsIcon sx={{ marginLeft: 1, color: "green" }} />}
      </CustomTypography1>

      {!isPopular && (
        <CustomSubtitle
          sx={{
            textAlign: "left",
            mb: 2,
            color: "rgba(255, 255, 255, 0.5)",
            fontFamily: "Lato",
            fontWeight: 300,
          }}
        >
          {label}
        </CustomSubtitle>
      )}
      <Button
        variant="contained"
        fullWidth
        sx={{
          textTransform: "none",
          borderRadius: "4px",
          marginY: "10px",
          padding: "10px 40px",
          background: theme.palette.primary.info,   
           fontFamily: "Lato",
          color: theme.palette.primary.white,
          fontSize: "16px",
          ":hover": {
            backgroundColor: "#007CD3",
            color: theme.palette.primary.white,
          },
        }}
        onClick={onClick}
      >
        {buttonText}
      </Button>
      <CustomSubtitle
        sx={{
          textAlign: "left",
          mb: 2,
          color: "rgba(255, 255, 255, 0.5)",
          fontFamily: "Lato",
          fontSize: "13px",
          fontWeight: 300,
        }}
      >
        Valid till current billing cycle
      </CustomSubtitle>
    </Box>
    <Divider sx={{ backgroundColor: "#444" }} />
    <Box p={2}>
      {price.map((price, index) => (
        <Typography
          key={index}
          variant="body2"
          sx={{fontFamily: "Lato", mb: 2, fontSize: "32px", fontWeight: 700 }}
        >
          {price}
        </Typography>
      ))}
    </Box>
  </Paper>
);

export default function AddOnCardSection({ open, onClose }) {
  const [selectedTab, setSelectedTab] = useState(0); // State for managing tabs
  const [checkoutModalOpen, setCheckoutModalOpen] = useState(false); // State for CheckoutModal
  const theme = useTheme();

  const handleSelectPlan = (plan) => {
    console.log(`Selected plan: ${plan}`);
    setCheckoutModalOpen(true); // Open CheckoutModal
    onClose();
  };

  const handleCheckoutModalClose = () => {
    setCheckoutModalOpen(false); // Close CheckoutModal
  };
  const plans = [
    {
      title: "Starter pack",
      price: ["$5.00"],
      features: "100",
      buttonText: "Buy now",
      priceText: "Meeting hours",
    },
    {
      title: "Professional Pack",
      price: ["$10.00"],
      features: "200",
      buttonText: "Buy now",
      priceText: "Meeting hours",
    },
    {
      title: "Custom Pack",
      price: ["$15.00"],
      features: "1000",
      buttonText: "Buy now",
      priceText: "Meeting Hours",
      isPopular: true,
    },
  ];

  const wordPlans = [
    {
      title: "Starter pack",
      price: ["$5.00"],
      features: "1000",
      buttonText: "Buy now",
      priceText: "Words",
    },
    {
      title: "Professional Pack",
      price: ["$10.00"],
      features: "2000",
      buttonText: "Buy now",
      priceText: "Words",
    },
    {
      title: "Custom Pack",
      price: ["$15.00"],
      features: "3000",
      buttonText: "Buy now",
      priceText: "Words",
      isPopular: true,
    },
  ];

  const comboPlans = [
    {
      title: "Starter pack",
      price: ["$10.00"],
      features: "50 + 1000",
      buttonText: "Buy now",
      priceText: "Meeting Hour + Words",
    },
    {
      title: "Professional Pack",
      price: ["$25.00"],
      features: "100 + 2000",
      buttonText: "Buy now",
      priceText: "Meeting Hour + Words",
    },
    {
      title: "Advanced Pack",
      price: ["$50.00"],
      features: "150 + 3000",
      buttonText: "Buy now",
      priceText: "Meeting Hour + Words",
    },
  ];

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  // Determine which plans to show based on the selected tab
  const selectedPlans =
    selectedTab === 0 ? plans : selectedTab === 1 ? wordPlans : comboPlans;

  return (

    <>
          <CheckoutModal />
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="lg"
      fullWidth
      sx={{ backgroundImage: "none !important" }}
      PaperProps={{
        style: {
          backgroundImage: "none !important",
          background: "none",
          boxShadow: "0px 6px 16px rgba(153, 155, 168, 0.1)",
        },
      }}
    >
      <ModalContainer
        sx={{
          overflowY: "auto", // Allow scrolling
          scrollbarWidth: "none", // Hide scrollbar for Firefox
          "&::-webkit-scrollbar": {
            display: "none", // Hide scrollbar for Chrome, Safari, and Opera
          },
        }}
      >
        {/* Modal Header */}
        <ModalHeader>
          <Box sx={{ width: "75%" }}>
            <Typography
              variant="h4"
              sx={{ fontWeight: 600, fontSize: "32px", fontFamily: "Lato" }}
            >
              Purchase Add-ons
            </Typography>
            <CustomSubtitle
              sx={{
                mt: 2,
                textAlign: "left", // Ensure left alignment
                display: "block",
                marginLeft: "0px",
                fontFamily: "Lato",
                color: "rgba(255, 255, 255, 0.5)",
                fontWeight: 400,
                fontSize: "16px",
              }}
            >
              Unlock premium features in Talks & Talks by purchasing Credit
              Points.
              <br /> {/* Break to start a new line */}
              Choose between Meeting Hours and Text Translation credits to
              tailor your experience to your needs.
            </CustomSubtitle>
          </Box>
          <IconButton
            onClick={onClose}
            sx={{
              position: "absolute",
              top: "16px", // Adjust the vertical position
              right: "16px", // Adjust the horizontal position
              zIndex: 10, // Ensure it is on top of other elements
              color: "#FFFFFF80",
              border: "1px solid #444",
              borderRadius: "10px",
              width: "36px",
              height: "36px",
            }}
          >
            <CloseIcon
              sx={{
                height: "18px",
                width: "18px",
                color: "rgba(255, 255, 255, 0.5)",
              }}
            />
          </IconButton>
        </ModalHeader>

        {/* Tabs */}
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          sx={{
            mb: 4,
            borderColor: "#FFFFFF40",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
          TabIndicatorProps={{ style: { display: "none" } }} // Hide the default indicator
        >
          {/* Meeting Hours Add-on Tab */}
          <Tab
            label={
              selectedTab === 0 ? (
                <Button
                  variant="contained"
                  sx={{
                    textTransform: "none",
                    borderRadius: "8px",
                    background: theme.palette.primary.info,
                    color: theme.palette.primary.white,
                    fontSize: "18px",
                    fontWeight: 700,
                    fontFamily: "Lato",
                    ":hover": {
                      background: theme.palette.primary.info,
                      color: theme.palette.primary.white,
                    },
                  }}
                >
                  Meeting hours add-on
                </Button>
              ) : (
                "Meeting hours add-on"
              )
            }
            sx={{
              color: selectedTab === 0 ? "#1976D2" : "#FFFFFF80",
              borderBottom: selectedTab === 0 ? 0 : 1,
              paddingBottom: selectedTab === 0 ? 0 : 1,
              fontFamily:"lato",

              mr: 2,
              textTransform: "none",
              fontWeight: selectedTab === 0 ? 700 : 700,
              fontSize: selectedTab === 0 ? "18px" : "18px",
              minWidth: 0, // Adjust min width for tabs
              
            }}
          />

          {/* Translation Words Add-on Tab */}
          <Tab
            label={
              selectedTab === 1 ? (
                <Button
                  variant="contained"
                  sx={{
                    textTransform: "none",
                    borderRadius: "8px",
                    background: theme.palette.primary.info,
                    color: theme.palette.primary.white,
                    fontSize: "18px",
                    fontWeight: 700,
                    fontFamily: "Lato",
                    ":hover": {
                      background: theme.palette.primary.info,
                      color: theme.palette.primary.white,
                    },
                  }}
                >
                  Translation words add-on
                </Button>
              ) : (
                "Translation words add-on"
              )
            }
            sx={{
              color: selectedTab === 1 ? "#1976D2" : "#FFFFFF80",
              borderBottom: selectedTab === 1 ? 0 : 1,
              paddingBottom: selectedTab === 1 ? 0 : 1,
              fontFamily:"lato",

              mr: 2,
              textTransform: "none",
              fontWeight: selectedTab === 1 ? 700 : 700,
              fontSize: selectedTab === 1 ? "18px" : "18px",
              minWidth: 0, // Adjust min width for tabs
              
            }}
          />

          {/* Combo Add-on Tab */}
          <Tab
            label={
              selectedTab === 2 ? (
                <Button
                  variant="contained"
                  sx={{
                    textTransform: "none",
                    borderRadius: "8px",
                    background: theme.palette.primary.info,
                    color: theme.palette.primary.white,
                    fontSize: "18px",
                    fontWeight: 700,
                    fontFamily:"lato",
                    ":hover": {
                      background: "#1976D2",
                    },
                  }}
                >
                  Combo add-on
                </Button>
              ) : (
                "Combo add-on"
              )
            }
            sx={{
              color: selectedTab === 2 ? "#1976D2" : "#FFFFFF80",
              borderBottom: selectedTab === 2 ? 0 : 1,
              paddingBottom: selectedTab === 2 ? 0 : 1,
              textTransform: "none",
              fontWeight: selectedTab === 2 ? 700 : 700,
              fontSize: selectedTab === 2 ? "18px" : "18px",
              minWidth: 0, // Adjust min width for tabs
              fontFamily:"lato",
            }}
          />
        </Tabs>

        {/* Modal Content */}
        <ModalContent>
          <Grid container spacing={3}>
            {selectedPlans.map((plan, index) => (
              <Grid item xs={12} md={3} key={index}>
                <AddOnCard
                  {...plan}
                  theme={theme}
                  onClick={() => handleSelectPlan(plan.title)}
                  label={plan.priceText}
                />
              </Grid>
            ))}
          </Grid>
        </ModalContent>
      </ModalContainer>
    </Dialog>
    {checkoutModalOpen && (
        <CheckoutModal open={checkoutModalOpen} onClose={handleCheckoutModalClose} />
      )}

    </>
  );
}

AddOnCardSection.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

AddOnCard.propTypes = {
  title: PropsTypes.string,
  price: PropsTypes.string,
  features: PropsTypes.array,
  buttonText: PropsTypes.string,
  onClick: PropsTypes.string,
  isPopular: PropsTypes.bool,
  theme: PropsTypes.any,
  label: PropsTypes.string,
};
