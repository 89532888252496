import * as React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import CircularProgress, { circularProgressClasses } from '@mui/material/CircularProgress';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';



// Styled component for green linear progress
const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 4, // Decrease size
  top: "8px", // Changed
  borderRadius: 5,
  transform: 'rotate(180deg)', // Starting from left side
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: [theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: "green", // Set to green
  },
}));

const BorderLinearReverseProgress = styled(LinearProgress)(() => ({
  height: 4, 
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#fff", // Set the background color to white
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    background: 'linear-gradient(to right, #7CE7AC 80%, white 20%)', // 80% green and 20% white
  },
}));

const BorderLinearGreenReverseProgress = styled(LinearProgress)(() => ({
  height: 4, 
  borderRadius: 5,
  transform: 'rotate(180deg)', 
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#fff", // Set the background color to white
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    background: 'linear-gradient(to right, #28C345 80%, white 20%)', // 80% green and 20% white
  },
}));

// Styled component for orange linear progress
const OrangeLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 4, // Decrease size
  top: "8px", // Changed
  borderRadius: 5,
  transform: 'rotate(180deg)', // Starting from left side
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: [theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: "orange", // Set to orange
  },
}));

const OrangeLinearReverseProgress = styled(LinearProgress)(() => ({
  height: 4, // Decrease size
  top: "8px", // Changed
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#fff", // Set the background color to white
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    background: 'linear-gradient(to right, #F6933E 80%, white 20%)', 
  },
}));

const RedLinearReverseProgress = styled(LinearProgress)(() => ({
  height: 4, // Decrease size
  top: "8px", // Changed
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#fff", // Set the background color to white
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    background: 'linear-gradient(to right, #FF808B 40%, white 60%)', 
  },
}));

const RedLinearProgress = styled(LinearProgress)(() => ({
  height: 4, // Decrease size
  top: "8px", // Changed
  borderRadius: 5,
  transform: 'rotate(180deg)',
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#fff", // Set the background color to white
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    background: 'linear-gradient(to right, #FF808B 40%, white 60%)', 
  },
}));

const BlueLinearReverseProgress = styled(LinearProgress)(() => ({
  height: 4, // Decrease size
  top: "8px", // Changed
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#fff", // Set the background color to white
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    background: 'linear-gradient(to right, #5E81F4 80%, white 20%)', 
  },
}));


// Styled component for rose linear progress
const RoseLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 4, // Decrease size
  top: "8px", // Changed
  borderRadius: 5,
  transform: 'rotate(180deg)', // Starting from left side
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: [theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: "rgba(255, 128, 139, 1)", // Set to rose color
  },
}));
//

///
const VerticalLinearProgress = styled(LinearProgress)(({ color }) => ({
  height: "100px", // Set the height of the progress bar
  width: "10px", // Set the width (thickness) of the progress bar
  borderRadius: "5px",
  transform: "rotate(90deg)", // Rotate to make it vertical
  backgroundColor: "rgba(255, 255, 255, 0.2)", // Background color for the unfilled section
  "& .MuiLinearProgress-bar": {
    borderRadius: "5px",
    backgroundColor: color || "orange", // Set the color of the filled section (default to orange)
  },
}));




// Inspired by the former Facebook spinners.
// Circular progress components
export function FacebookCircularProgress(props) {
  return (
    <Box sx={{ position: 'relative' }}>
      <CircularProgress
        variant="determinate"
        sx={{
          color: (theme) =>
            theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
        }}
        size={40}
        thickness={4}
        {...props}
        value={100}
      />
      <CircularProgress
        variant="indeterminate"
        disableShrink
        sx={{
          color: (theme) => (theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8'),
          animationDuration: '550ms',
          position: 'absolute',
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: 'round',
          },
        }}
        size={40}
        thickness={4}
        {...props}
      />
    </Box>
  );
}

// Gradient circular progress
export function GradientCircularProgress() {
  return (
    <React.Fragment>
      <svg width={0} height={0}>
        <defs>
          <linearGradient id="my_gradient" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" stopColor="#e01cd5" />
            <stop offset="100%" stopColor="#1CB5E0" />
          </linearGradient>
        </defs>
      </svg>
      <CircularProgress sx={{ 'svg circle': { stroke: 'url(#my_gradient)' } }} />
    </React.Fragment>
  );
}

// Custom linear progress with green color
export function CustomLinearProgress({ style }) {
  console.log(style);
  return (
    <BorderLinearProgress variant="determinate" value={80} sx={{ width: "100%" }} />
  );
}
export function CustomLinearReverseProgress({ style }) {
  console.log(style);
  return (
    <BorderLinearReverseProgress variant="determinate" value={80} sx={{ width: "100%" }} />
  );
}

export function CustomLinearGreenReverseProgress({ style }) {
  console.log(style);
  return (
    <BorderLinearGreenReverseProgress variant="determinate" value={80} sx={{ width: "100%" }} />
  );
}

// Custom linear progress with orange color
export function CustomOrangeLinearProgress({ style }) {
  console.log(style);
  return (
    <OrangeLinearProgress variant="determinate" value={80} sx={{ width: "100%" }} />
  );
} 

export function CustomOrangeLinearReverseProgress({ style }) {
  console.log(style);
  return (
    <OrangeLinearReverseProgress variant="determinate" value={80} sx={{ width: "100%" }} />
  );
}

export function CustomRedLinearReverseProgress({ style }) {
  console.log(style);
  return (
    <RedLinearReverseProgress variant="determinate" value={80} sx={{ width: "100%" }} />
  );
}

export function CustomRedLinearProgress({ style }) {
  console.log(style);
  return (
    <RedLinearProgress variant="determinate" value={80} sx={{ width: "100%" }} />
  );
}

export function CustomBlueLinearReverseProgress({ style }) {
  console.log(style);
  return (
    <BlueLinearReverseProgress variant="determinate" value={80} sx={{ width: "100%" }} />
  );
}

// Custom linear progress with rose color
export function CustomRoseLinearProgress({ style }) {
  console.log(style);
  return (
    <RoseLinearProgress variant="determinate" value={80} sx={{ width: "100%" }} />
  );
}


//
export function CustomVerticalProgress({ value = 50, color = "orange" }) {
  return (
    <Box display="flex" alignItems="center" justifyContent="center">
      <VerticalLinearProgress variant="determinate" value={value} color={color} />
    </Box>
  );
}

//////////////////////////

CustomLinearProgress.propTypes = {
  style: PropTypes.object,
};
CustomLinearReverseProgress.propTypes = {
  style: PropTypes.object,
};

CustomOrangeLinearProgress.propTypes = {
  style: PropTypes.object,
};

CustomOrangeLinearReverseProgress.propTypes = {
  style: PropTypes.object,
};

CustomRedLinearReverseProgress.propTypes = {
  style: PropTypes.object,
};

CustomBlueLinearReverseProgress.propTypes = {
  style: PropTypes.object,
};

CustomLinearGreenReverseProgress.propTypes = {
  style: PropTypes.object,
};

CustomRedLinearProgress.propTypes = {
  style: PropTypes.object,
};

CustomRoseLinearProgress.propTypes = {
  style: PropTypes.object,
};


//
CustomVerticalProgress.propTypes = {
  value: PropTypes.number,
  color: PropTypes.string,
};