import React, { useEffect, useState } from "react";
import { Box, Button, Stack, useTheme, IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  CustomSubtitle,
  CustomTypography1,
} from "../../../components/Typography/Typography";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch } from "react-redux";
import { transcriptDownload } from "../../../redux/slices/MeetingSlice";
import PropTypes from "prop-types";
import { WHITE } from "../../../theme/Palette";

function MeetingEnd({ isGuest ,handleCloseModal}) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const navigate = useNavigate();
  const [transcriptData, setTranscriptData] = useState(null);

  const quickCallId = localStorage.getItem("quick_call_id"); // Retrieve quick_call_id from local storage
  const language = "original"; // Set language as 'original' as required in the API call

  // Fetch transcript when component mounts
  useEffect(() => {
    if (quickCallId && language) {
      dispatch(transcriptDownload({ quick_call_id: quickCallId, language }))
        .then((res) => {
          if (res.meta.requestStatus === "fulfilled") {
            setTranscriptData(res.payload);
          } else {
            console.error("Failed to fetch transcript:", res.payload);
          }
        })
        .catch((error) => {
          console.error("Error fetching transcript:", error);
        });
    }
  }, [dispatch, quickCallId, language]);

  const handleMeetingClose = () => {
    // If user is a guest, clear local storage and navigate to the dashboard
    if (isGuest) {
      localStorage.clear();
      navigate("/businessauth/login");
    }
    navigate("/business/Overview");
    window.location.reload();
  };

  // Handle download when Download Transcript button is clicked
  const handleDownload = () => {
    if (transcriptData) {
      const fileUrl = transcriptData?.fileUrl;

      if (fileUrl) {
        const link = document.createElement("a");
        link.href = fileUrl;
        link.download = "transcript.pdf"; // Set file name for the downloaded PDF
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        const pdfBlob = new Blob([transcriptData], { type: "application/pdf" });
        const url = URL.createObjectURL(pdfBlob);
        const link = document.createElement("a");
        link.href = url;
        link.download = "transcript.pdf"; // Set file name for the downloaded PDF
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      }
    } else {
      console.warn("Transcript data is not available for download.");
    }
  };

  return (
    <Box 
      sx={{
        background: theme.palette.background.lightGrey,
        padding: "40px",
        borderRadius: "8px",
        // alignItems: "center",
        display: "flex",
        flexDirection: "column", width:"900px",height:"700px"
      }}
    >
      <Box sx={{display:"flex", alignItems:"flex-end", justifyContent:"flex-end",width:"100%"}}>
      <IconButton
          size="small"
          sx={{
            color: "white",
            border: "1px solid #444",
            borderRadius: "10px",
            width: "36px",
            height: "36px",
          }}
          onClick={handleCloseModal}
        >
          <CloseIcon
            sx={{
              height: "18px",
              width: "18px",
              color: "rgba(255, 255, 255, 0.5)",
            }}
          />
        </IconButton>
      </Box>

      <Box mt={10} sx={{display:"flex", alignItems:"center", justifyContent:"center", flexDirection:"column"}}>
     
      <Stack spacing={3} m={1}>
        <CustomTypography1
          sx={{ fontFamily: "Lato", fontSize: "32px", fontWeight: 700 }}
        >
          Meeting Ended
        </CustomTypography1>
      </Stack>
      <Stack spacing={3} mt={2}>
        <CustomSubtitle
          sx={{ fontFamily: "Lato", fontSize: "16px", fontWeight: 400 }}
        >
          The meeting has now ended. We hope it was productive and informative.
        </CustomSubtitle>
      </Stack>
      <Stack direction="row" spacing={2} mt={6} sx={{ padding: "3px", display:"flex", alignItems:"center", justifyContent:"center" }}>
        <Box sx={{ background: "#1A1C1D", minWidth: "500px", p: 2, display:"flex" }}>
          <CustomSubtitle
            sx={{ color: "#FFFFFF", fontFamily: "Lato", fontSize: "14px" }}
          >
            Total hours used for this call:{" "}
            <span style={{ fontSize: "16px",fontFamily:"Lato" }}>45 Minute </span>{" "}
          </CustomSubtitle>
        </Box>
      </Stack>
      <Stack direction="row" sx={{display:"flex", alignItems:"center", justifyContent:"center" }}>
        <Box sx={{ background: "#1A1C1D", minWidth: "500px", p: 2, display:"flex"  }}>
          <CustomSubtitle
            sx={{ color: "#FFFFFF", fontFamily: "Lato", fontSize: "14px" }}
          >
            You have remining :{" "}
            <span style={{ fontSize: "16px",fontFamily:"Lato" }}>45Hours</span>
          </CustomSubtitle>
        </Box>
      </Stack>

      <Stack
        mt={4}
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Button
          sx={{
            width: "300px",
            borderRadius: "10px",
            background: theme.palette.primary.info,
            color: theme.palette.primary.light,
            textTransform: "none",
            padding: "12px",
            "@media (min-width: 600px)": {
              width: "300px",
            },
          
            fontSize: "16px",
            fontFamily: "Lato",
            fontWeight: 700,
            "&:hover": {
              backgroundColor: "#007CD3",
            },
          }}
          variant="contained"
          onClick={handleDownload}
          // disabled={!transcriptData}
        >
          Download Transcript
        </Button>
        <Button
          sx={{
            width: "300px",
            borderRadius: "10px",
            marginTop: "10px",
            background: "#A1A1A11A",
            color: theme.palette.primary.light,
            textTransform: "none",
            fontSize: "16px",
            fontFamily: "Lato",
            fontWeight: 700,
            padding: "12px",
            "@media (min-width: 600px)": {
              width: "300px",
            },
       
            "&:hover": {
              borderColor: WHITE[50],
              backgroundColor: "rgba(255, 255, 255, 0.1)",
            },
          }}
          variant="contained"
          onClick={handleMeetingClose}
        >
          Close
        </Button>
      </Stack>
      </Box>
    </Box>
  );
}

MeetingEnd.propTypes = {
  isGuest: PropTypes.bool, 
  handleCloseModal:PropTypes.bool,
};

export default MeetingEnd;
