import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Typography,
  Avatar,
  IconButton,
  TextField,
  Switch,
  Autocomplete,
  Button,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import SentimentSatisfiedIcon from "@mui/icons-material/SentimentSatisfiedAltSharp";
import AutoAwesomeSharpIcon from "@mui/icons-material/AutoAwesomeSharp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

// Main component handling both modals
const Chat = ({ onClose, messages, sendMessage, userId }) => {
  const [newMessage, setNewMessage] = useState("");
  const [isTranslationEnabled, setIsTranslationEnabled] = useState(false); // State to track toggle
  const [translationStatus, setTranslationStatus] = useState(false); // Translation status text state

  // Create a ref for the last message
  const messagesEndRef = useRef(null);

  // Scroll to the bottom of the chat when a new message is added
  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  const handleSendMessage = () => {
    if (newMessage.trim() !== "") {
      sendMessage(newMessage);
      setNewMessage("");
    }

  };

  const handleToggleSwitch = () => {
    setIsTranslationEnabled((prev) => !prev); // Toggle translation modal
  };

  const handleSaveTranslation = () => {
    setIsTranslationEnabled(false); // Close the modal
    setTranslationStatus(true); // Update the translation status
  };

  // If translation is enabled, render the Translation Preferences modal
  if (isTranslationEnabled) {
    return (
      <Box
        sx={{
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(34, 37, 38, 1)",
          padding: "10px",
          color: "#fff",

          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          boxShadow: "none",
        }}
      >
        <Box>
          <Box display="flex" justifyContent="space-between" p={1} mt={2}>
            <Typography
              sx={{
                fontFamily: "Lato",
                fontSize: "18px",
                fontWeight: 600,
              }}
              variant="h4"
            >
              Translation Preferences
            </Typography>
            <IconButton
              size="small"
              sx={{
                color: "white",
                border: "1px solid #444",
                borderRadius: "10px",
                width: "36px",
                height: "36px",
              }}
              onClick={() => setIsTranslationEnabled(false)} // Close modal
            >
              <CloseIcon
                sx={{
                  height: "18px",
                  width: "18px",
                  color: "rgba(255, 255, 255, 0.5)",
                }}
              />
            </IconButton>
          </Box>

          {/* Language Selection Dropdown */}
          <Box mb={-1} sx={{ textAlign: "left" }} p={1} mt={3}>
            <Typography
              sx={{
                fontSize: "14px",
                color: "#FFFFFF80",
                fontWeight: 400,
                fontFamily: "Lato",
              }}
            >
              Your language
            </Typography>
            <Autocomplete
              fullWidth
              options={[{ name: "English" }, { name: "Spanish" }]}
              getOptionLabel={(option) => option.name || ""}
              value={"English"}
              popupIcon={
                <KeyboardArrowDownIcon
                  sx={{
                    height: "18px",
                    width: "18px",
                    color: "rgba(255, 255, 255, 0.5)",
                  }}
                />
              }
              disableClearable
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  placeholder="Select Language"
                  sx={{
                    color: "white",
                    paddingBottom: "4px",  // Adjust this value to control the distance
                  }}
                />
              )}
              sx={{
                //borderBottom: `2px solid rgba(245, 245, 250, 0.4)`,
                "& .MuiInputBase-root": {
                  paddingBottom: "10px",
                },
                "& .MuiInputBase-input": {
                  padding: "8px 0px",
                  color: "white",
                  fontFamily: "Lato",
                  fontSize: "16px",
                },
              }}
            />

          </Box>

          <Box mb={1} sx={{ textAlign: "left" }} p={1} mt={2}>
            <Typography
              sx={{
                fontSize: "14px",
                color: "#FFFFFF80",
                fontWeight: 400,
                fontFamily: "Lato",
              }}
            >
              Translate to
            </Typography>
            <Autocomplete
              fullWidth
              options={[{ name: "Spanish" }, { name: "French" }]}
              getOptionLabel={(option) => option.name || ""}
              value={"Spanish"}
              popupIcon={
                <KeyboardArrowDownIcon
                  sx={{
                    height: "18px",
                    width: "18px",
                    color: "rgba(255, 255, 255, 0.5)",
                  }}
                />
              }
              disableClearable
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  placeholder="Select Language"
                  sx={{
                    "& .MuiInputBase-input::placeholder": {
                      color: "white",
                      fontFamily: "Lato",
                      fontSize: "16px",
                    },
                  }}
                />
              )}
              sx={{

                "& .MuiInputBase-root": {
                  paddingBottom: "10px",
                },
                "& .MuiInputBase-input": {
                  padding: "8px 0px",
                  color: "white",
                  fontFamily: "Lato",
                  fontSize: "16px",
                },
              }}
            />
          </Box>
        </Box>

        <Box>
          <Box
            sx={{
              marginTop: "30px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              backgroundColor: "#222526",
              borderRadius: "4px",
              height: "48px",
              width: "100%",
              padding: "0 10px",
            }}
          >
            <Typography
              sx={{ fontWeight: 400, fontSize: "12px", fontFamily: "Lato" }}
            >
              5000/150000 words Left
            </Typography>
            <Typography
              sx={{ fontWeight: 400, fontSize: "12px", fontFamily: "Lato" }}
            >
              Buy More
            </Typography>
          </Box>
          <Button
            variant="contained"
            sx={{
              textTransform: "none",
              width: "100%",
              borderRadius: "8px",
              marginTop: "30px",
              padding: "8px 40px",
              background: "#0092F8",
              color: "white",
              fontSize: "16px",
              fontFamily: "Lato",
              ":hover": {
                background: "#007CD3",
                color: "white",
              },
            }}
            onClick={() => {
              setIsTranslationEnabled(false); // Switch back to chat
              handleSaveTranslation(); // Update translation status
            }}
          >
            Save
          </Button>
        </Box>
      </Box>
    );
  }

  // If translation is disabled, render the chat modal
  return (
    <Box
      sx={{
        width: "100%",
        height: "500%",
        backgroundColor: "rgba(34, 37, 38, 1)",
        color: "white",
        borderRadius: 2,
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          padding: 2,
          borderBottom: "1px solid #333",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          //flexShrink: 0,
          mt: 1
        }}
      >
        <Typography
          variant="h6"
          sx={{
            color: "white",
            fontFamily: "Lato",
            fontWeight: 600,
            fontSize: "18px",
          }}
        >
          Chat
        </Typography>
        <IconButton
          size="small"
          sx={{
            color: "white",
            border: "1px solid #444",
            borderRadius: "10px",
            width: "36px",
            height: "36px",
          }}
          onClick={onClose}
        >
          <CloseIcon
            sx={{
              height: "18px",
              width: "19px",
              color: "rgba(255, 255, 255, 0.5)",
            }}
          />
        </IconButton>
      </Box>

      {/* Messages List */}
      <Box
        sx={{
          padding: 2,
          flexGrow: 1,
          overflowY: "auto", // Enable scrolling inside the chat messages list
        }}
      >
        {messages.map((message, index) => (
          <Box
            key={index}
            sx={{
              display: "flex",
              alignItems: "flex-start",
              marginBottom: 2,
              flexDirection:
                message.senderId === userId ? "row-reverse" : "row", // Show message based on sender ID
            }}
          >
            <Avatar
              src={message.avatar}
              sx={{
                borderRadius: "8px",
                height: "34px",
                width: "34px",
                marginLeft: message.senderId === userId ? 2 : 0,
                marginRight: message.senderId === userId ? 0 : 2,
              }}
            />
            <Box
              sx={{
                maxWidth: "70%",
                textAlign: message.senderId === userId ? "right" : "left",
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  color: "rgba(255, 255, 255, 0.9)",
                  fontFamily: "Lato",
                  fontWeight: 400,
                  fontSize: "14px",
                  marginBottom: "4px",
                  padding: "8px 12px",
                  borderRadius:
                    message.senderId === userId
                      ? "12px 12px 0px 12px"
                      : "12px 12px 12px 0px",
                  backgroundColor:
                    message.senderId === userId
                      ? "#1976d2"
                      : "rgba(0, 146, 248, 1)", // Different background for sender and receiver
                }}
              >
                {message.text}
              </Typography>
              <Typography
                variant="caption"
                sx={{
                  color: "rgba(128, 131, 163, 1)",
                  fontSize: "12px",
                  fontFamily: "Lato",
                  fontWeight: 600,
                  display: "block",
                }}
              >
                {message.time}
              </Typography>
            </Box>
          </Box>
        ))}
        {/* Dummy div to automatically scroll into view */}
        <div ref={messagesEndRef} />
      </Box>

      <Box
        sx={{
          padding: 2,
          backgroundColor: "rgba(34, 37, 38, 1)",
          flexShrink: 0,
          position: "relative",
          display: "flex",
          alignItems: "center",
        }}
      >
        <TextField
          variant="outlined"
          fullWidth
          placeholder="Type to add your message"
          multiline // Enables multiline input
          maxRows={2} // Limits the visible rows to 2
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          InputProps={{
            sx: {
              mt: 1,
              fontFamily: "Lato",
              fontSize: "16px",
              lineHeight: "19.2px",
              color: "white",
              borderRadius: "20px",
              padding: "8px 12px",
              "& .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
              overflow: "auto", // Ensures scrolling when content exceeds 2 lines
            },
          }}
        />
        <IconButton
          color="primary"
          onClick={handleSendMessage}
          sx={{
            width: "44px",
            height: "44px",
            position: "absolute",
            right: "16px",
            backgroundColor: "#0092F8",
            color: "white",
            borderRadius: "10px",
            padding: "12px",
            "&:hover": {
              backgroundColor: "#007CD3",
              color: "white",
            },
          }}
        >
          <SendIcon sx={{ height: "18px", width: "18px" }} />
        </IconButton>
      </Box>

      <Box
        sx={{
          padding: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "40px",
        }}
      >
        <IconButton sx={{ color: "white", marginRight: 0 }}>
          <SentimentSatisfiedIcon
            sx={{
              height: "22px",
              width: "22px",
              color: "rgba(250, 251, 255, 0.5)",
            }}
          />
        </IconButton>
        <IconButton sx={{ color: "white", marginRight: "30px" }}>
          <AddIcon
            sx={{
              color: "rgba(250, 251, 255, 0.5)",
              width: "22px",
              height: "22px",
            }}
          />
        </IconButton>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center", // Center the content horizontally
            border: "1px solid #fff",
            borderRadius: "20px",
            padding: "4px 8px",
            backgroundColor: "rgba(26, 28, 29, 0.7)",
            height: "22px",
            marginRight: "1px",
            "@media (max-width: 1280px)": {
              height: "20px",
              padding: "3px 6px",
              borderRadius: "18px",
            },
            "@media (max-width: 960px)": {
              height: "18px",
              padding: "3px 4px",
              borderRadius: "16px",
            },
            "@media (max-width: 600px)": {
              height: "auto",
              padding: "2px 4px",
              borderRadius: "12px",
              marginRight: "0px",
            },
            "@media (max-width: 360px)": {
              flexDirection: "column",
              padding: "4px",
            },
          }}
        >
          <Typography
            variant="caption"
            sx={{
              color: translationStatus ? "rgba(34, 197, 94, 1)" : "white", // Green text when enabled
              fontWeight: 500,
              fontFamily: "Lato",
              fontSize: "10px",
              lineHeight: "13.6px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center", // Center the content within the Typography
              "@media (max-width: 1280px)": {
                fontSize: "9px",
                lineHeight: "12px",
              },
              "@media (max-width: 960px)": {
                fontSize: "8px",
                lineHeight: "11px",
              },
              "@media (max-width: 600px)": {
                fontSize: "7px",
                lineHeight: "10px",
              },
              "@media (max-width: 360px)": {
                fontSize: "6px",
                lineHeight: "9px",
                textAlign: "center",
              },
            }}
          >
            <AutoAwesomeSharpIcon
              sx={{
                width: "10px",
                height: "10px",
                marginRight: "4px", // Adjust spacing between icon and text
                [window.matchMedia("(max-width: 1280px)").matches]: {
                  width: "9px",
                  height: "9px",
                },
                [window.matchMedia("(max-width: 960px)").matches]: {
                  width: "8px",
                  height: "8px",
                },
                [window.matchMedia("(max-width: 600px)").matches]: {
                  width: "7px",
                  height: "7px",
                },
                [window.matchMedia("(max-width: 360px)").matches]: {
                  width: "6px",
                  height: "6px",
                },
              }}
            />
            {translationStatus ? "Translation Enabled" : "Enable Translation"}
          </Typography>
        </Box>

        <Switch
          checked={isTranslationEnabled}
          onChange={handleToggleSwitch}
          sx={{
            marginLeft: "20px", // This moves the switch to the right side
            borderRadius: "12px",
            height: "22px",
            width: "43px",
            padding: 0, // Remove extra padding around the track
            "& .MuiSwitch-switchBase": {
              padding: "6px", // Adjust padding around the thumb
              top: "-3px", // Ensure the thumb is vertically centered (centered for a 16px thumb on a 22px track)
              color: "rgba(26, 28, 29, 1)", // Thumb color when not checked (black)
              "&.Mui-checked": {
                transform: "translateX(15px)", // Adjust thumb movement based on track width and thumb size
                color: "white", // Thumb color when checked
              },
              "&.Mui-checked + .MuiSwitch-track": {
                backgroundColor: "rgba(0, 146, 248, 1)", // Blue background when checked
                opacity: 1,
              },
            },
            "& .MuiSwitch-thumb": {
              width: "16px", // Thumb width
              height: "16px", // Thumb height
            },
            "& .MuiSwitch-track": {
              borderRadius: "11px", // Rounded corners for the track
              backgroundColor: "rgba(66, 66, 77, 1)", // Background when not checked
              opacity: 1,
            },
          }}
        />
      </Box>
    </Box>
  );
};

// Add PropTypes validation
Chat.propTypes = {
  onClose: PropTypes.func.isRequired,
  messages: PropTypes.array.isRequired,
  sendMessage: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired, // Pass the userId to identify the sender
};

export default Chat;
