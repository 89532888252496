import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";

export const OutlinedPaper = styled(Paper)(() => ({
//   textAlign: "center",
  borderRadius: "8px",
  border: "1px solid rgba(255, 255, 255, 0.15)",
  background: "var(--1-a-1-c-1-d-primary, #1A1C1D)",
  // opacity: 0.15,
}));

export const ContainedPaper = styled(Paper)(() => ({
  borderRadius: "12px",
  background: "var(--222526, #222526)",
//   textAlign: "center",

}));


