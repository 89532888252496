import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Typography,
  IconButton,
  Dialog,
  MenuItem,
  Select,
  FormControl,
} from "@mui/material";
import { styled } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowDownTwoToneIcon from '@mui/icons-material/KeyboardArrowDownTwoTone';
import InfoIcon from '@mui/icons-material/Info';

const ModalContainer = styled(Box)(() => ({
  backgroundColor: "#222526",
  borderRadius: "12px",
 // boxShadow: "0px 6px 16px rgba(153, 155, 168, 0.1)",
  color: "#fff",
  padding: "24px 32px",
}));

const ModalHeader = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  paddingBottom: "16px",
}));

const WarningBox = styled(Box)(() => ({
  backgroundColor: "#5A4D4D",
  padding: "16px",
  borderRadius: "8px",
  display: "flex",
  alignItems: "center",
  marginBottom: "16px",
}));

export default function CancelModal({ open, onClose }) {
  const [reason, setReason] = useState("");

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      sx={{ backgroundImage: "none !important" }}
      PaperProps={{
        style: {
          backgroundImage: "none !important",
          background: "none",
         // boxShadow: "0px 6px 16px rgba(153, 155, 168, 0.1)",
        },
      }}
    >
      <ModalContainer>
        {/* Modal Header */}
        <ModalHeader>
          <Typography sx={{ fontWeight: 700, fontSize: "18px" }}>
            Cancel plan
          </Typography>
          <IconButton
            onClick={onClose}
            sx={{
              color: "#FFFF",
              width: "36px",
              height: "36px",
              borderRadius:"10px",
              border:"1px solid rgba(255, 255, 255, 0.15)"
            }}
          >
            <CloseIcon  sx={{height:"18px",width:'18px',color:"rgba(255, 255, 255, 0.5)"}}/>
          </IconButton>
        </ModalHeader>

        {/* Modal Content */}
        <Box>
        <Typography sx={{ mb: 4, fontWeight: 700, fontSize: "14px", lineHeight: "20px", color: "#FFFFFF80" }}>
  Your monthly subscription is paid until May 10, 2024.  If you would like to proceed with cancelling your <br />
subscription, please select “Cancel Subscription” below.
</Typography>


          {/* Warning Box */}
          <WarningBox sx={{backgroundColor:"#FFA0431A",height:"79px",borderRadius:"0px",mt:7}}>
            <Typography sx={{ fontSize: "16px", fontWeight: 400,fontFamily:"Lato" }}>
              <InfoIcon sx={{color:"rgba(255, 160, 67, 1)" ,mr:1}}/> Are you sure? After May 10, 2024, you will not have the ability to use Realtime translation.
            </Typography>
          </WarningBox>
    
          {/* Reason for Cancelling */}
          <Typography sx={{ fontWeight: 700, fontSize: "16px",mt: 4 }}>
            What is your reason for cancelling?
          </Typography>
  
          <FormControl fullWidth sx={{mt:4}}>
            <Select
              value={reason}
              onChange={(e) => setReason(e.target.value)}
              displayEmpty
              IconComponent={KeyboardArrowDownTwoToneIcon } 
              sx={{
                height:"62px",
                background: "#1A1C1DB2",
                color: "#fff",
                borderRadius: "8px",
                "& .MuiSelect-icon": { color: "#fff",height:"18px",width:"18px" },
                "& .MuiInputBase-input": { padding: "12px" },
              }}
              MenuProps={{
                PaperProps: {
                  sx: {
                    background: "#1A1C1D",
                    color: "#fff",
                  },
                },
              }}
            >
              <MenuItem value="" >
             <Typography sx={{color:"rgba(157, 157, 157, 1)",fontWeight:600,fontSize:"14px"}}>Reason for cancelling</Typography>
              </MenuItem>
              <MenuItem value="Not satisfied" sx={{fontFamily:"Lato"}}>Not satisfied</MenuItem>
              <MenuItem value="Too expensive" sx={{fontFamily:"Lato"}}>Too expensive</MenuItem>
              <MenuItem value="Switching services" sx={{fontFamily:"Lato"}}>Switching services</MenuItem>
              <MenuItem value="Other" sx={{fontFamily:"Lato"}}>Other</MenuItem>
            </Select>
          </FormControl>

          {/* Action Buttons */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "24px",
            }}
          >
            <Button
              onClick={onClose}
              variant="outlined"
              sx={{
                width: "150px", color:"#fff",
            borderRadius: "10px",
            textTransform: "none", fontSize: "16px", fontWeight: 600 ,fontFamily:"Lato",
            padding: "12px",
            marginRight:"10px",
            "@media (min-width: 600px)": {
              width: "210px",
            },
              }}
            >
              Go back
            </Button>
            <Button
              variant="contained"
              sx={{
                width: "150px", color:"#fff",
            borderRadius: "10px",
            textTransform: "none",
            flexGrow:1,
            background: "rgba(255, 128, 139, 1)",fontSize: "16px", fontWeight: 600 ,fontFamily:"Lato",
            padding: "12px",
            "@media (min-width: 600px)": {
              width: "210px",
            },
            '&:hover': {
              backgroundColor: '#e76b77', // Darker shade of #FF808B
            },
          }}  onClick={onClose}
            >
              Cancel Subscription
            </Button>
          </Box>
        </Box>
      </ModalContainer>
    </Dialog>
  );
}

CancelModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
