import React, { Suspense, lazy } from 'react';
// components
import LoadingPage from '../pages/LoadingPage';

// ----------------------------------------------------------------------

const Loadable = (Component) => {
  const WrappedComponent = (props) => (
    <Suspense fallback={<LoadingPage />}>
      <Component {...props} />
    </Suspense>
  );
  // Set display name for debugging purposes
  WrappedComponent.displayName = `Loadable(${Component.displayName || Component.name || 'Component'})`;
  return WrappedComponent;
};

// AUTH
export const SignUpPage = Loadable(lazy(() => import('../pages/auth/SignUpPage')));
export const LoginPage = Loadable(lazy(() => import('../pages/auth/LoginPage')))
export const ResetPasswordPage = Loadable(lazy(() => import('../pages/auth/PasswordReset')))
export const EmailStatusPage = Loadable(lazy(() => import('../pages/auth/EmailStatusPage')))
export const ForgetPasswordPage = Loadable(lazy(() => import('../pages/auth/ForgetPassword')))
export const ForgetPasswordEmailPage = Loadable(lazy(() => import('../pages/auth/PasswordRestSuccessPage')))
export const OtpVerificationPage = Loadable(lazy(() => import('../pages/auth/OtpVerification')))
export const PromoCodePage = Loadable(lazy(() => import('../pages/auth/PromocodePage')))
export const GuestLoginPage = Loadable(lazy(() => import('../pages/auth/GuestLogin')))
export const SocialMediaSignUpSuccessPage = Loadable(lazy(() => import('../pages/auth/SocialMediaSignUpSuccess')))
export const ForgetPasswordResetSuccess = Loadable(lazy(() => import('../pages/auth/PasswordRestSuccessPage')))


//DASHBOARD
export const DashBoardPage = Loadable(lazy(() => import('../pages/Dashboard/DashBoardPage')))
export const MessagePage = Loadable(lazy(() => import('../pages/messenger/Messeges')))
export const SettingsPage = Loadable(lazy(() => import('../pages/settings/SettingsPage')))
export const SchedulePage = Loadable(lazy(() => import('../pages/Schedule')))
export const NotificationPage = Loadable(lazy(() => import('../pages/Notifications/index')))
export const ContactPage = Loadable(lazy(() => import('../pages/Contacts/Contact'))) //
export const MeetingJoinPage = Loadable(lazy(() => import('../pages/Call/MeetingJoin')))
export const ParticipantsPage = Loadable(lazy(() => import('../pages/Call/ParticipantsPage')))
export const DocumentsPage = Loadable(lazy(() => import('../pages/Documents/DocumentsPage')))
export const FaqPage = Loadable(lazy(() => import('../pages/Faq/FaqPage')))
export const SearchPage = Loadable(lazy(() => import('../layout/SearchViewModal')))


// CALL
export const CallHistoryPage = Loadable(lazy(() => import('../pages/Call/')))

export const VideoCallPage = Loadable(lazy(() => import('../pages/Call/VideoCallPage')))
export const GuestVideoCallApp = Loadable(lazy(() => import('../pages/Call/GuestVideoCallApp')))
export const MeetingNotStartPage = Loadable(lazy(() => import('../pages/Call/MeetingNotStart')))
export const CreateMeetingPage = Loadable(lazy(() => import('../sections/call/CreateMeeting')))

//SETTINGS
export const ManageSubscriptionPage = Loadable(lazy(() => import('../sections/Settings/ManagePlan')))
export const ChangePasswordPage = Loadable(lazy(() => import('../sections/Settings/ChangePassword')))
export const NotificationSection = Loadable(lazy(() => import('../sections/Settings/Notifications')))
export const OrderHistorySection = Loadable(lazy(() => import('../sections/Settings/OrderHistory')))
export const EditProfilePage = Loadable(lazy(() => import('../sections/Settings/Profile/Index')))
export const SubscriptionCard = Loadable(lazy(() => import('../pages/Subcription/Subcription')))


//BUSINESS USER

export const BusinessDashBoardPage = Loadable(lazy(() => import('../pages/businessUser/BusinessDashboard')))
export const BusinessNotification = Loadable(lazy(() => import('../pages/businessUser/BusinessNotification/BussinessNotifications')))
export const BusinessCalender = Loadable(lazy(() => import('../pages/businessUser/schedulepage/BusinessCalender')))
export const BusinessDocumentPage = Loadable(lazy(() => import('../pages/businessUser/Documents/BusinessDocumenPage')))
export const BussinessMessenger = Loadable(lazy(() => import('../pages/businessUser/Messages/BusinessMessenger')))
export const BusinessContacts = Loadable(lazy(() => import('../pages/businessUser/Contacts/BusinessContacts')))
export const BusinessSettings = Loadable(lazy(() => import('../pages/businessUser/Settings/BusinessSettings')))
export const BusinessEditprofile = Loadable(lazy(() => import('../pages/businessUser/Settings/BusinessEditProfile')))
export const ManagePlanIndex = Loadable(lazy(() => import('../pages/businessUser/Settings/Manageplan/ManagePlanIndex')))
export const BusinessChangePasswordForm = Loadable(lazy(() => import('../pages/businessUser/Settings/ChangePassword/BusinessChangePasswordForm')))
export const BusinessNotificationSection = Loadable(lazy(() => import('../pages/businessUser/Settings/Nottification/Notticications')))
//export const BusinessFaq  = Loadable(lazy(()=> import('../pages/businessUser/BusinessFAQ/BusinessFaq')))
export const BusinesssFAQ = Loadable(lazy(() => import('../pages/businessUser/BusinessFAQ/BusinesssFAQ')))
export const BusinessIndex = Loadable(lazy(() => import('../pages/businessUser/Calls/BusinessIndex')))
export const BusinessMeetingjoiningPge = Loadable(lazy(() => import('../pages/businessUser/Calls/BusinessMeetingjoinin')))
export const BusinessSearchPage = Loadable(lazy(() => import('../pages/businessUser/BussinesLayout/BusinessSearchViewModal')))
export const BusinessSubscriptionCard = Loadable(lazy(() => import('../pages/businessUser/Subscription/Subscription')))
export const BusinessGuestVideoCallApp = Loadable(lazy(() => import('../pages/businessUser/Calls/BusinessGuestVideoCallPage')))
export const BusinessGuestLoginPage = Loadable(lazy(() => import('../pages/businessUser/Auth/BusinessGuestLogin')))


//BUSINESS AUTH
export const BusinessSignUpPage = Loadable(lazy(() => import('../pages/businessUser/Auth/BusinessSignupPage')));
export const BusinessLoginPage = Loadable(lazy(() => import('../pages/businessUser/Auth/BusinessLogin')))
export const BusinessOtpVerificationPage = Loadable(lazy(() => import('../pages/businessUser/Auth/BusinessOtpVerification')))
export const BusinessForgetPasswordPage = Loadable(lazy(() => import('../pages/businessUser/Auth/BusinessForgotPassword')))
export const BusinessEmailStatusPage = Loadable(lazy(() => import('../pages/businessUser/Auth/BusinessEmailStatus')))
export const BusinessResetPasswordPage = Loadable(lazy(() => import('../pages/businessUser/Auth/BusinessPasssWordReset')))
export const BusinessForgetPasswordResetSuccess = Loadable(lazy(() => import('../pages/businessUser/Auth/BusinessPasswordResetSucess')))


//BUSINESS VIDEO CALL
//export const BusinessVideoCallPage =Loadable(lazy(()=> import('../pages/businessUser/Calls/BusinessVideoCallScreen')))


//ADMIN 
export const AdminDashBoardPage = Loadable(lazy(() => import('../pages/Dashboard/AdminDasBoardPage')))
export const AdminNotificationPage = Loadable(lazy(() => import('../pages/Notifications/AdminNotification')))
export const AdminCustomersPage = Loadable(lazy(() => import('../pages/Customers/index')))
export const AdminPlansPage = Loadable(lazy(() => import('../pages/Plans/index')))
export const AdminReportsPage = Loadable(lazy(() => import('../pages/Reports/index')))
export const AdminUsersPage = Loadable(lazy(() => import('../pages/AdminUser/index')))
export const AdminBillingPage = Loadable(lazy(() => import('../pages/Billing/index')))
export const AdminSupportPage = Loadable(lazy(() => import('../pages/Support/index')))
export const AdminPromotionPage = Loadable(lazy(() => import('../pages/Promotions/index')))
export const AdminSettingsPage = Loadable(lazy(() => import('../pages/AdminSettings/index')))
export const AdminLoginPage = Loadable(lazy(() => import('../pages/admin/AdminDashboard')))
export const AdminEditProfilePage = Loadable(lazy(() => import('../pages/AdminSettings/index')))
export const AdminPasswordPage = Loadable(lazy(() => import('../pages/AdminSettings/Security')))
export const AdminLanguagePage = Loadable(lazy(() => import('../pages/AdminSettings/language')))
export const AdminCountryPage = Loadable(lazy(() => import('../pages/AdminSettings/Local')))



//BUSINESS ADMIN AUTH
export const BusinessAdminLoginPage = Loadable(lazy(() => import('../pages/BusinessAdmin/Auth/BusinessAdminLoginpage')))
export const BusinessAdminSignUpPage = Loadable(lazy(() => import('../pages/BusinessAdmin/Auth/BusinessAdminSignUpPage')));
export const BusinessAdminForgetPasswordPage = Loadable(lazy(() => import('../pages/BusinessAdmin/Auth/ForgotPassWord')))
export const BusinessAdminEmailStatusPage = Loadable(lazy(() => import('../pages/BusinessAdmin/Auth/EmailStatus')))
export const BusinessAdminResetPasswordPage = Loadable(lazy(() => import('../pages/BusinessAdmin/Auth/ResetPassWord')))
export const BusinessAdminForgetPasswordResetSuccess = Loadable(lazy(() => import('../pages/BusinessAdmin/Auth/PasswordResetSucess')))
export const BusinessAdminOtpVerificationPage = Loadable(lazy(() => import('../pages/BusinessAdmin/Auth/OtpVerfication')))
//BUSINESS ADMIN
export const BusinessAdminDashBoardPage = Loadable(lazy(() => import('../pages/BusinessAdmin/BusinessAdminDashboard')))
export const BusinessAdminIndex = Loadable(lazy(() => import('../pages/BusinessAdmin/Call/BusinessAdminIndex')))
export const BusinessAdminNotification = Loadable(lazy(() => import('../pages/BusinessAdmin/Nottification/BusinessAdminNotifications')))
export const BussinessAdminMessenger = Loadable(lazy(() => import('../pages/BusinessAdmin/Messagess/BusinessAdminMessenger')))
export const BusinessAdminCalender = Loadable(lazy(() => import('../pages/BusinessAdmin/SchedulePage/BusinessAdminCalender')))
export const BusinessAdminContacts = Loadable(lazy(() => import('../pages/BusinessAdmin/ContactPage/BusinessAdminContact')))
export const BusinessAdminDocumentPage = Loadable(lazy(() => import('../pages/BusinessAdmin/Documents/BusinessAdminDocumentPage')))
export const BusinessAdminSettingsPage = Loadable(lazy(() => import('../pages/BusinessAdmin/Settings/BusinessAdminSettings')))
export const BusinessAdminEditprofile = Loadable(lazy(() => import('../pages/BusinessAdmin/Settings/Profile/BusinessAdminEditProfile')))
export const BusinessAdminManagePlan = Loadable(lazy(() => import('../pages/BusinessAdmin/Settings/ManagePlan/BusinessAdminManagePlan')))
export const BusinessAdminOrderHistorySection = Loadable(lazy(() => import('../pages/BusinessAdmin/Settings/OrderHistory/Index')))
export const BusinessAdminChangePassword = Loadable(lazy(() => import('../pages/BusinessAdmin/Settings/ChangePassword/BusinessAdminChangePassword')))
export const BusinessAdminNotificationSection = Loadable(lazy(() => import('../pages/BusinessAdmin/Settings/Nottification/Index')))
export const BusinessAdminMeetingjoiningPge = Loadable(lazy(() => import('../pages/BusinessAdmin/Call/BusinessAdminMeetingJoin')))
export const BusinessAdminSubscriptionCard = Loadable(lazy(() => import('../pages/BusinessAdmin/SubscriptionPage/Subscription')))
export const BusinessAdminpanellpage = Loadable(lazy(() => import('../pages/BusinessAdmin/BusinessAdminPanel/BusinessAdminPanellSetting')))
export const BusinessAdminSearchPage = Loadable(lazy(() => import('../pages/BusinessAdmin/SearchModal/BusinessAdminSearchModal')))
export const BusinesssAdminFAQ = Loadable(lazy(() => import('../pages/BusinessAdmin/FAQ/BusinessAdminFAQ')))
//BUSINESS ADMIN Panell
export const BusinessAdminDashboard = Loadable(lazy(() => import('../pages/BusinessAdmin/BusinessAdminPanel/BusinessAdminDashboard/BusinessAdminDashBoard')))
export const BusinessAdminActivities = Loadable(lazy(() => import('../pages/BusinessAdmin/BusinessAdminPanel/BusinessAdminActivity/BusinessssAdminActivites')))
export const BusinessAdminUserManagment = Loadable(lazy(() => import('../pages/BusinessAdmin/BusinessAdminPanel/BusinessAdminUserMnagment/BusinessAdminUsermanagment')))
export const BusinessAdminRole = Loadable(lazy(() => import('../pages/BusinessAdmin/BusinessAdminPanel/BusinessAdminRoles/BusinessAdminRole')))