import React, { useState } from "react";
import { ContainedPaper } from "../../../../components/Paper/Paper";
import { useNavigate } from "react-router-dom";
import {
  Avatar,
  Box,
  Button,
  Container,
  Grid,
  TableCell,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import {
  CustomSubtitle,
  CustomTypography1,
  CustomTypography2,
  CustomTypography4,
} from "../../../../components/Typography/Typography";
import BasicTable from "../../../../components/Table/Table";
import { styled } from "@mui/material/styles";
import Chip from "@mui/material/Chip";
import {
  CustomOrangeLinearProgress,

  CustomLinearProgress,
} from "../../../../components/Progress/CustomProgress";
//import CancelModal from './CancelPlan';
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import CancelModal from "../../../BusinessAdmin/Settings/ManagePlan/CancelPlan";
import AddOnCardSection from "../../../BusinessAdmin/Settings/ManagePlan/Addon/AddOnPack";
//import AddOnCardSection from "../../../Subcription/AddOns/AddOns";
import imguser from "../../../../assets/images/profile/imguser.jpg"
import imgusera from "../../../../assets/images/profile/imgguserg.jpg"
import imguserb from "../../../../assets/images/profile/imguserg.jpg"

export const CustomTableRow = styled(TableRow)(() => ({
  color: "var(--ffffff-50, rgba(255, 255, 255, 0.50))",
  border: "1px solid var(--ffffff-50, rgba(255, 255, 255, 0.15))",
  opacity: 1,
  textTransform: "none",
}));

export const CustomTableCell = styled(TableCell)(() => ({
  color: "var(--ffffff-50, rgba(255, 255, 255, 0.50))",
  borderBlock: "1px solid var(--ffffff-50, rgba(255, 255, 255, 0.15))",
  opacity: 1,
  textTransform: "none",
  // borderRadius: "8px",
}));
///
const rowsMeeting = [
  {
hour:"0.5 Hours",
details: "Design Meeting  ",
date: "21 oct 2018",
user: "5 Participants",
language: "Hindhi,Spanish",
img: imguser, // Replace with the actual image path
name: "Sam",
role: "Host",
 },
 {
  // amount: "$34.00",
  hour: "0.5 Hours",
  details: "Team meeting",
  date: "21 sep 2018",
  user: "2 Participants",
  language: "Malayalam",
  img: imgusera, // Replace with the actual image path
  name: "Monica",
  role: "Host",
},
{
  // amount: "$34.00",
  hour: "0.8 Hours",
  details: "Client onbording",
  date: "21 sep 2018",
  user: "10  Participants",
  language: "English,Tamil",
  img: imguserb, // Replace with the actual image path
  name: "Courtney ",
  role: "Host",
},
];

 const rowsWords = [
   {
 words: "500 Words",
details: "Design Meeting  ",
date: "21 oct 2018",
user: "5 Participants",
language: "Hindhi,Spanish",
img: imguser, // Replace with the actual image path
name: "Sam",
role: "Host",
   },
   {
    // amount: "$34.00",
    words: "400 Words",
    details: "Team meeting",
    date: "21 sep 2018",
    user: "2 Participants",
    language: "Malayalam",
    img: imgusera, // Replace with the actual image path
    name: "Monica",
    role: "Host",
  },
  {
    // amount: "$34.00",
    words: "400 Words",
    details: "Client onbording",
    date: "21 sep 2018",
    user: "10  Participants",
    language: "English,Tamil",
    img: imguserb, // Replace with the actual image path
    name: "Courtney ",
    role: "Host",
  },
 ];


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  borderRadius: "8px",
  background: "var(--222526, #222526)",
  boxShadow: "0px 6px 16px 0px rgba(153, 155, 168, 0.10)",
  p: 2,
};
const rows2 = [
  {
    // amount: "$34.00",
    hour: "0.5 Hours",
    details: "Design Meeting  ",
    date: "21 oct 2018",
    user: "5 Participants",
    language: "Hindhi,Spanish",
    img: imguser, // Replace with the actual image path
    name: "Sam",
    role: "Host",
  },
  {
    // amount: "$34.00",
    hour: "0.5 Hours",
    details: "Team meeting",
    date: "21 sep 2018",
    user: "2 Participants",
    language: "Malayalam",
    img: imgusera, // Replace with the actual image path
    name: "Monica",
    role: "Host",
  },
  {
    // amount: "$34.00",
    hour: "0.8 Hours",
    details: "Client onbording",
    date: "21 sep 2018",
    user: "10  Participants",
    language: "English,Tamil",
    img: imguserb, // Replace with the actual image path
    name: "Courtney ",
    role: "Host",
  },

];
const rows1 = [
  {
    amount: "0.9 Hours",
    details: "Pro (9/21/2018 to 10/21/2019)",
    date: "21 oct 2018",
  },
];

function BusinessAdminManagePlan() {
  const theme = useTheme();
  const navigate = useNavigate();

  const [openModal, setOpenModal] = useState(false);
  const [addOn, setAddOn] = useState(false);

  const handleCancel = () => {
    setOpenModal(true);
  }

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleOpenAddModal = () => {
    setAddOn(true);
  };

  const handleCloseAddModal = () => {
    setAddOn(false);
  };


  const [currentTable, setCurrentTable] = useState("all"); // Default to "All Usage"

  const handleTableSwitch = (table) => {
    setCurrentTable(table);
  };

  const renderTableContent = () => {
    switch (currentTable) {
      case "meeting":
        return (
          <BasicTable >
          {rowsMeeting.map((row, index) => (
            <CustomTableRow
              key={index}
              sx={{
                // "&:last-child td, &:last-child th": { },
                borderRadius: "8px",
              }}
            >
              <CustomTableCell
                sx={{
                  fontWeight: 400,
                  fontSize: "14px",
                  lineHeight: "21px",
                  fontFamily: "Lato",
                }}
                align="left"
              >
                {row.date}
              </CustomTableCell>

              <CustomTableCell
                align="left"
                sx={{
                  color: theme.palette.primary.white,
                  fontFamily: "Lato",
                  fontWeight: 400,
                  fontSize: "14px",
                  lineHeight: "21px",
                  padding: "25px",
                }}
              >
                {row.details}
              </CustomTableCell>

              <CustomTableCell
                align="left"
                sx={{
                  color: "white",
                  fontFamily: "Lato",
                  fontWeight: 400,
                  fontSize: "14px",
                  lineHeight: "21px",
                  paddingRight: "3rem",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Avatar src={row.img} alt={row.name} sx={{ width: "26px", height: "26px", marginRight: 2, borderRadius: "6px" }} />
                  <Box>
                    <Typography sx={{ fontSize: "14px", fontWeight: 400, color: "white", fontFamily: "Lato" }}>
                      {row.name}
                    </Typography>
                    <Typography sx={{ fontSize: "12px", fontWeight: 400, color: "rgba(255, 255, 255, 0.5)", fontFamily: "Lato" }}>
                      {row.role}
                    </Typography>
                  </Box>
                </Box>
              </CustomTableCell>

              <CustomTableCell
                align="left"
                sx={{
                  color: theme.palette.primary.white,
                  fontFamily: "Lato",
                  fontWeight: 400,
                  fontSize: "14px",
                  lineHeight: "21px",
                  paddingRight: "3rem",
                }}
              >
                {row.user}
              </CustomTableCell>

              <CustomTableCell
                align="left"
                sx={{
                  color: theme.palette.primary.white,
                  fontFamily: "Lato",
                  fontWeight: 400,
                  fontSize: "14px",
                  lineHeight: "21px",
                }}
              >
                {row.language}
              </CustomTableCell>
              <CustomTableCell
                align="right"
                sx={{
                  color: theme.palette.primary.white,
                  fontFamily: "Lato",
                  fontWeight: 400,
                  fontSize: "14px",
                  lineHeight: "21px",
                }}
              >
                {row.hour}

                <Button
                  sx={{
                    color: theme.palette.primary.info,
                    textTransform: "none",
                    fontFamily: "Lato",
                    fontWeight: 700,
                    fontSize: "14px",
                    lineHeight: "21px",

                    marginLeft: "10px",
                  }}
                ></Button>
              </CustomTableCell>
            </CustomTableRow>
          ))}
        </BasicTable>
        );
      case "words":
        return (
          <BasicTable >
          {rowsWords.map((row, index) => (
            <CustomTableRow
              key={index}
              sx={{
                // "&:last-child td, &:last-child th": { },
                borderRadius: "8px",
              }}
            >
              <CustomTableCell
                sx={{
                  fontWeight: 400,
                  fontSize: "14px",
                  lineHeight: "21px",
                  fontFamily: "Lato",
                }}
                align="left"
              >
                {row.date}
              </CustomTableCell>

              <CustomTableCell
                align="left"
                sx={{
                  color: theme.palette.primary.white,
                  fontFamily: "Lato",
                  fontWeight: 400,
                  fontSize: "14px",
                  lineHeight: "21px",
                  padding: "25px",
                }}
              >
                {row.details}
              </CustomTableCell>

              <CustomTableCell
                align="left"
                sx={{
                  color: "white",
                  fontFamily: "Lato",
                  fontWeight: 400,
                  fontSize: "14px",
                  lineHeight: "21px",
                  paddingRight: "3rem",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Avatar src={row.img} alt={row.name} sx={{ width: "26px", height: "26px", marginRight: 2, borderRadius: "6px" }} />
                  <Box>
                    <Typography sx={{ fontSize: "14px", fontWeight: 400, color: "white", fontFamily: "Lato" }}>
                      {row.name}
                    </Typography>
                    <Typography sx={{ fontSize: "12px", fontWeight: 400, color: "rgba(255, 255, 255, 0.5)", fontFamily: "Lato" }}>
                      {row.role}
                    </Typography>
                  </Box>
                </Box>
              </CustomTableCell>

              <CustomTableCell
                align="left"
                sx={{
                  color: theme.palette.primary.white,
                  fontFamily: "Lato",
                  fontWeight: 400,
                  fontSize: "14px",
                  lineHeight: "21px",
                  paddingRight: "3rem",
                }}
              >
                {row.user}
              </CustomTableCell>

              <CustomTableCell
                align="left"
                sx={{
                  color: theme.palette.primary.white,
                  fontFamily: "Lato",
                  fontWeight: 400,
                  fontSize: "14px",
                  lineHeight: "21px",
                }}
              >
                {row.language}
              </CustomTableCell>
              <CustomTableCell
                align="right"
                sx={{
                  color: theme.palette.primary.white,
                  fontFamily: "Lato",
                  fontWeight: 400,
                  fontSize: "14px",
                  lineHeight: "21px",
                }}
              >
                {row.words}

                <Button
                  sx={{
                    color: theme.palette.primary.info,
                    textTransform: "none",
                    fontFamily: "Lato",
                    fontWeight: 700,
                    fontSize: "14px",
                    lineHeight: "21px",

                    marginLeft: "10px",
                  }}
                ></Button>
              </CustomTableCell>
            </CustomTableRow>
          ))}
        </BasicTable>
        );

      case "all":
        return (
          <BasicTable >
            {rows2.map((row, index) => (
              <CustomTableRow
                key={index}
                sx={{
                  // "&:last-child td, &:last-child th": { },
                  borderRadius: "8px",
                }}
              >
                <CustomTableCell
                  sx={{
                    fontWeight: 400,
                    fontSize: "14px",
                    lineHeight: "21px",
                    fontFamily: "Lato",
                  }}
                  align="left"
                >
                  {row.date}
                </CustomTableCell>

                <CustomTableCell
                  align="left"
                  sx={{
                    color: theme.palette.primary.white,
                    fontFamily: "Lato",
                    fontWeight: 400,
                    fontSize: "14px",
                    lineHeight: "21px",
                    padding: "25px",
                  }}
                >
                  {row.details}
                </CustomTableCell>

                <CustomTableCell
                  align="left"
                  sx={{
                    color: "white",
                    fontFamily: "Lato",
                    fontWeight: 400,
                    fontSize: "14px",
                    lineHeight: "21px",
                    paddingRight: "3rem",
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Avatar src={row.img} alt={row.name} sx={{ width: "26px", height: "26px", marginRight: 2, borderRadius: "6px" }} />
                    <Box>
                      <Typography sx={{ fontSize: "14px", fontWeight: 400, color: "white", fontFamily: "Lato" }}>
                        {row.name}
                      </Typography>
                      <Typography sx={{ fontSize: "12px", fontWeight: 400, color: "rgba(255, 255, 255, 0.5)", fontFamily: "Lato" }}>
                        {row.role}
                      </Typography>
                    </Box>
                  </Box>
                </CustomTableCell>

                <CustomTableCell
                  align="left"
                  sx={{
                    color: theme.palette.primary.white,
                    fontFamily: "Lato",
                    fontWeight: 400,
                    fontSize: "14px",
                    lineHeight: "21px",
                    paddingRight: "3rem",
                  }}
                >
                  {row.user}
                </CustomTableCell>

                <CustomTableCell
                  align="left"
                  sx={{
                    color: theme.palette.primary.white,
                    fontFamily: "Lato",
                    fontWeight: 400,
                    fontSize: "14px",
                    lineHeight: "21px",
                  }}
                >
                  {row.language}
                </CustomTableCell>
                <CustomTableCell
                  align="right"
                  sx={{
                    color: theme.palette.primary.white,
                    fontFamily: "Lato",
                    fontWeight: 400,
                    fontSize: "14px",
                    lineHeight: "21px",
                  }}
                >
                  {row.hour}

                  <Button
                    sx={{
                      color: theme.palette.primary.info,
                      textTransform: "none",
                      fontFamily: "Lato",
                      fontWeight: 700,
                      fontSize: "14px",
                      lineHeight: "21px",

                      marginLeft: "10px",
                    }}
                  ></Button>
                </CustomTableCell>
              </CustomTableRow>
            ))}
          </BasicTable>
        );

    }
  };


  return (
    <>
      <Container maxWidth="xl">
        <CancelModal open={openModal} onClose={handleCloseModal} style={style} />
        {/* <AddOnCardSection open={addOn} onClose={setAddOn} /> */}
        <AddOnCardSection open={addOn} onClose={handleCloseAddModal} />

        <Grid container spacing={4}>
          <Grid
            item
            xs={12} // Ensures the grid item takes full width on all screen sizes
            md={12}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: { xs: "column", sm: "row" }, // Stack vertically on small screens
              alignItems: { xs: "flex-start", sm: "center" }, // Adjust alignment for responsiveness
              marginTop: "25px",
              gap: { xs: 2, sm: 0 }, // Adds space between elements on small screens
            }}
          >
            <CustomTypography2
              sx={{
                textAlign: "left",
                fontSize: { xs: "16px", sm: "18px", lg: "18px" }, // Responsive font size
                fontFamily: "Lato",
                lineHeight: "27px",
                mb: { xs: 2, sm: 0 }, // Adds bottom margin on small screens for spacing
              }}
            >
              Manage Plan
            </CustomTypography2>

            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" }, // Stack buttons vertically on small screens
                gap: { xs: "8px", sm: "4px" }, // Adjust the gap to a smaller value for spacing
              }}
            >
              <Button
                variant="contained"
                sx={{
                  fontSize: "16px",
                  fontFamily: "Lato",

                  p: 1.5,
                  px: 4,
                  background: theme.palette.primary.info,
                  color: theme.palette.primary.white,
                  textTransform: "none",
                  borderRadius: "9px",
                  marginRight: { xs: 0, sm: 1 }, // Reduce the margin on small screens
                  width: "137px",
                  height: "46px",
                  "&:hover": {
                    background: "#007CD3",
                  },
                  mb: { xs: 2, sm: 0 }, // Adds bottom margin on small screens for spacing
                }} onClick={() => navigate("/businessadmin/subscription")}
              >

                Upgrade

              </Button>
              <Button
                variant="contained"
                sx={{
                  background: "#EB5757",
                  color: "rgba(255, 255, 255, 0.5)",
                  textTransform: "none",
                  borderRadius: "9px",
                  width: "137px",
                  height: "47px",
                  "&:hover": {
                    background: "#D14545",
                  },
                }}
                onClick={() => { handleCancel(), console.log("hhhhh") }}
              >
                <CloseRoundedIcon
                  sx={{
                    color: '#FFFFFF, 50%',
                    height: "18px",
                    width: "18px",
                    opacity: "0.7",
                    marginRight: "8px", // Add a gap between the icon and the text
                  }}
                />
                <CustomTypography4
                  sx={{
                    display: "flex", // Ensures icon and text are aligned in a row
                    alignItems: "center", // Vertically centers the icon and text
                    opacity: 0.5,
                    fontWeight: 400,
                    fontSize: "16px",
                    lineHeight: "19.2px",
                    whiteSpace: "nowrap", // Prevents the text from wrapping to the next line

                    color: '#FFFFFF, 50%'
                  }}
                >
                  Cancel Plan
                </CustomTypography4>
              </Button>
            </Box>
          </Grid>

          <Grid item sx={12} md={12}>
            <CustomSubtitle
              sx={{
                fontFamily: "Lato",
                textAlign: "left",
                fontWeight: 700,
                fontSize: "16px",
                lineHeight: "24px",
                color: "rgba(255, 255, 255, 0.5)",
              }}
            >
              Current Plan
            </CustomSubtitle>
          </Grid>

          <Grid item sx={12} md={12}>
            <Grid container spacing={1} rowGap={0} padding={0}>
              <Grid item xs={12} md={6} p={10}>
                <ContainedPaper sx={{ minWidth: "100%" }}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      flexDirection: { xs: "column", sm: "row" }, // Responsive flex direction
                      alignItems: "flex-start",
                      padding: { xs: "15px", sm: "20px" }, // Responsive padding
                    }}
                  >
                    <Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          gap: { xs: 1, sm: 2 }, // Responsive gap
                          flexWrap: { xs: "wrap", sm: "nowrap" }, // Wrap content on smaller screens
                        }}
                      >
                        <CustomTypography1
                          sx={{
                            fontSize: { xs: "14px", sm: "16px", lg: "16px" }, // Responsive font size
                            lineHeight: "24px",
                            fontWeight: 700,
                          }}
                        >
                          Talks & Talks Premium
                        </CustomTypography1>
                        <Chip
                          label="Pro"
                          color="primary"
                          variant="outlined"
                          sx={{
                            fontFamily: 'Lato',
                            fontWeight: 700,
                            fontSize: "12px",
                            lineHeight: "18px",
                            borderRadius: "12px",
                            border: "none",
                            color: "#fff",
                            width: { xs: "60px", sm: "70px", lg: "73.95px" }, // Responsive width
                            height: "18px",
                            background: "var(--f-6933-e-primary, #F6933E)",
                            marginTop: { xs: "8px", sm: "0" }, // Add margin on smaller screens to separate elements vertically
                          }}
                        />
                      </Box>

                      <CustomTypography1
                        sx={{
                          fontSize: { xs: "12px", sm: "14px", lg: "16px" }, // Responsive font size
                          textAlign: "left",
                          marginTop: { xs: "16px", sm: "24px" }, // Responsive margin top
                          fontWeight: 700,
                        }}
                      >
                        $25 / month
                      </CustomTypography1>
                    </Box>
                  </Box>
                </ContainedPaper>
              </Grid>

              <Grid item xs={12} md={6} p={10}>
                <ContainedPaper sx={{ minWidth: "100%" }}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: { xs: "column", sm: "row" }, // Responsive flex direction
                      alignItems: "flex-start",
                      padding: { xs: "16px", sm: "21px" }, // Responsive padding
                    }}
                  >
                    <Box sx={{ height: "auto", mb: { xs: "12px", sm: "0" } }}>
                      {" "}
                      {/* Adjusted height and added margin bottom for small screens */}
                      <CustomTypography1
                        sx={{
                          fontSize: { xs: "14px", sm: "16px", lg: "16px" }, // Responsive font size
                          lineHeight: "24px",
                          textAlign: "left",
                          fontWeight: 700,
                        }}
                      >
                        Renew at
                      </CustomTypography1>
                      <CustomTypography1
                        sx={{
                          fontSize: { xs: "14px", sm: "16px", lg: "16px" }, // Responsive font size
                          lineHeight: "24px",
                          marginTop: "25px",
                          textAlign: "left",
                          fontWeight: 700,
                        }}
                      >
                        April 07 2024
                      </CustomTypography1>
                    </Box>

                    <Box
                      sx={{
                        textAlign: "right",
                        mt: { xs: "16px", sm: "0" }, // Margin top for better spacing on smaller screens
                      }}
                    >
                      <CustomTypography1
                        sx={{
                          fontSize: { xs: "14px", sm: "16px", lg: "16px" }, // Responsive font size
                          lineHeight: "24px",
                          fontWeight: 700,
                        }}
                      >
                        MasterCard
                      </CustomTypography1>
                      <CustomTypography1
                        sx={{
                          fontSize: { xs: "14px", sm: "16px" }, // Responsive font size
                          mt: { xs: "2px", sm: "-1px" }, // Adjusted margin top
                          fontWeight: 700,
                        }}
                      >
                        *********4098
                      </CustomTypography1>
                      <CustomTypography1
                        sx={{
                          fontSize: { xs: "14px", sm: "16px", lg: "16px" }, // Responsive font size
                          mt: { xs: "5px", sm: "-15px" }, // Adjusted margin top for different screen sizes
                          textAlign: "right",
                          fontWeight: 700,
                        }}
                      >
                        8/2020
                      </CustomTypography1>
                    </Box>
                  </Box>
                </ContainedPaper>
              </Grid>
            </Grid>

            <Grid item sx={12} md={12} marginTop={-5}>
              <CustomSubtitle
                sx={{
                  textAlign: "left",
                  mb: 0,
                  fontFamily: "Lato",
                  fontWeight: 700,
                  fontSize: "16px",
                  lineHeight: "24px",
                  color: "rgba(255, 255, 255, 0.5)",
                }}
              >
                Plan summary
              </CustomSubtitle>
            </Grid>

            <Grid item xs={12} md={12} mt={4}>
              <Box
                sx={{
                  overflowX: "auto",
                  "&::-webkit-scrollbar": {
                    display: "none",
                  },
                  "-ms-overflow-style": "none", // IE and Edge
                  "scrollbar-width": "none", // Firefox
                }}
              >
                <BasicTable>
                  {rows1.map((row, index) => (
                    <CustomTableRow
                      key={index}
                      sx={{
                        "&:last-child th": { border: 0 },
                        borderRadius: "8px",
                      }}
                    >
                      <CustomTableCell
                        align="center"
                        sx={{ border: "1px solid rgba(255, 255, 255, 0.15)" }}
                      >
                        <Box
                          sx={{
                            padding: ["4px", "6px", "8px", "10px"],
                            display: "flex",
                            flexDirection: { xs: "column", sm: "row" }, // Responsive direction
                            justifyContent: "space-between",
                            alignItems: "center",
                            gap: { xs: 1, sm: 2 }, // Add a smaller gap between the boxes
                          }}
                        >
                          <Box sx={{ mb: { xs: 1, sm: 0 } }}>
                            {" "}
                            {/* Reduce margin bottom on smaller screens */}
                            <CustomTypography1
                              sx={{
                                fontSize: {
                                  xs: "12px",
                                  sm: "14px",
                                  lg: "16px",
                                }, // Responsive font size
                                textAlign: "left",
                                display: "block",
                                lineHeight: "24px",
                                fontFamily: "Lato"
                              }}
                            >
                              Call minutes
                            </CustomTypography1>
                            <CustomSubtitle
                              sx={{
                                textAlign: "left",
                                color: "rgba(255, 255, 255, 0.5)",
                                fontSize: {
                                  xs: "10px",
                                  sm: "12px",
                                  lg: "14px",
                                }, // Responsive font size
                                fontWeight: 400,
                                lineHeight: "21px",
                                fontFamily: "Lato"
                              }}
                            >
                              Total time spent
                            </CustomSubtitle>
                          </Box>

                          <Box sx={{ mb: { xs: 1, sm: 0 } }}>
                            {" "}
                            {/* Reduce margin bottom on smaller screens */}
                            <CustomTypography1
                              sx={{
                                fontSize: {
                                  xs: "12px",
                                  sm: "14px",
                                  lg: "18px",
                                }, // Responsive font size
                                textAlign: "left",
                                display: "block",
                                lineHeight: "20px",
                                //fontWeight: 700,
                                mb: 0, // Remove margin bottom to reduce space
                              }}
                            >
                              10345/10345
                            </CustomTypography1>
                            <CustomLinearProgress
                              sx={{
                                width: { xs: "100%", sm: 100 }, // Responsive width
                                marginTop: "-4px", // Adjust the gap between text and progress bar
                              }}
                            />{" "}
                            {/* Responsive width */}
                          </Box>
                        </Box>
                      </CustomTableCell>

                      <CustomTableCell
                        sx={{ border: "1px solid rgba(255, 255, 255, 0.15)" }}
                        align="center"
                      >
                        <Box
                          sx={{
                            padding: ["4px", "6px", "8px", "10px"],
                            display: "flex",
                            flexDirection: { xs: "column", sm: "row" }, // Responsive direction
                            justifyContent: "space-between",
                            alignItems: "center",
                            gap: { xs: 1, sm: 2 }, // Add a smaller gap between the boxes
                          }}
                        >
                          <Box sx={{ mb: { xs: 1, sm: 0 } }}>
                            {" "}
                            {/* Reduce margin bottom on smaller screens */}
                            <CustomTypography1
                              sx={{
                                fontSize: {
                                  xs: "12px",
                                  sm: "14px",
                                  lg: "16px",
                                }, // Responsive font size
                                textAlign: "left",
                                display: "block",
                                lineHeight: "24px",
                                fontFamily: "Lato"
                              }}
                            >
                              Transalation usage
                            </CustomTypography1>
                            <CustomSubtitle
                              sx={{
                                textAlign: "left",
                                color: "rgba(255, 255, 255, 0.5)",
                                fontSize: {
                                  xs: "10px",
                                  sm: "12px",
                                  lg: "14px",
                                }, // Responsive font size
                                fontWeight: 400,
                                lineHeight: "21px",
                                fontFamily: "Lato"
                              }}
                            >
                              Total character spent
                            </CustomSubtitle>
                          </Box>

                          <Box sx={{ mb: { xs: 1, sm: 0 } }}>
                            {" "}
                            {/* Reduce margin bottom on smaller screens */}
                            <CustomTypography1
                              sx={{
                                fontSize: {
                                  xs: "12px",
                                  sm: "14px",
                                  lg: "18px",
                                }, // Responsive font size
                                textAlign: "left",
                                display: "block",
                                lineHeight: "20px",
                                //fontWeight: 700,
                                mb: 0, // Remove margin bottom to reduce space
                              }}
                            >
                              1000/31500
                            </CustomTypography1>
                            <CustomOrangeLinearProgress
                              sx={{
                                width: { xs: "100%", sm: 100 }, // Responsive width
                                marginTop: "-4px", // Adjust the gap between text and progress bar
                              }}
                            />{" "}
                            {/* Responsive width */}
                          </Box>
                        </Box>
                      </CustomTableCell>

                      <CustomTableCell
                        align="center"
                        sx={{ border: "1px solid rgba(255, 255, 255, 0.15)" }}
                      >
                        <Box
                          sx={{
                            padding: ["4px", "6px", "8px", "10px"],
                            display: "flex",
                            flexDirection: { xs: "column", sm: "row" }, // Responsive direction
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Box sx={{ mb: { xs: 1, sm: 0 } }}>
                            {" "}
                            {/* Reduce margin bottom on smaller screens */}
                            <CustomTypography1
                              sx={{
                                fontSize: {
                                  xs: "12px",
                                  sm: "14px",
                                  lg: "16px",
                                }, // Responsive font size
                                textAlign: "left",
                                display: "block",
                                lineHeight: "24px",
                                fontFamily: "Lato"
                              }}
                            >
                              Language limit
                            </CustomTypography1>
                            <CustomSubtitle
                              sx={{
                                textAlign: "left",
                                color: "rgba(255, 255, 255, 0.5)",
                                fontSize: {
                                  xs: "10px",
                                  sm: "12px",
                                  lg: "14px",
                                }, // Responsive font size
                                fontWeight: 400,
                                lineHeight: "21px",
                                fontFamily: "Lato"
                              }}
                            >
                              Total avaliable languages
                            </CustomSubtitle>
                          </Box>

                          <Box>
                            <CustomTypography1
                              sx={{
                                fontSize: {
                                  xs: "16px",
                                  sm: "18px",
                                  lg: "18px",
                                }, // Responsive font size
                                textAlign: "left",
                                lineHeight: "27px",
                                padding: "2px",
                                fontWeight: 700,
                              }}
                            >
                              5
                            </CustomTypography1>
                          </Box>
                        </Box>
                      </CustomTableCell>
                    </CustomTableRow>
                  ))}
                </BasicTable>
              </Box>
            </Grid>


            <Grid item sx={12} md={12} mt={5}>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <CustomSubtitle
                  sx={{
                    textAlign: "left",
                    fontWeight: 700,
                    fontSize: "16px",
                    lineHeight: "24px",
                    color: "rgba(255, 255, 255, 0.5)",
                    fontFamily: "Lato"
                  }}
                >
                  Add on
                </CustomSubtitle>

                <Button
                  variant="contained"
                  sx={{
                    width: "135px",
                    height: "44px",
                    borderRadius: "8px",
                    textTransform: "none",
                    background: theme.palette.primary.info,
                    color: theme.palette.primary.white,
                    fontFamily: "Lato",
                    "&:hover": {
                      background: "#007CD3",
                    },
                  }}
                  onClick={() => handleOpenAddModal()}
                >
                  Buy Add-On
                </Button>
              </Box>

              {/* Add marginTop to create space between "Add on" section and "Call minutes" box */}
            </Grid>

            <Grid item xs={12} md={12} mt={2}>

            </Grid>

            <Grid item sx={12} md={12} mt={5}>
              <Box sx={{ display: "flex", gap: 3, alignItems: "center", mb: -2 }}>
                <CustomTypography1
                  sx={{
                    fontFamily: "Lato",
                    fontWeight: 700,
                    fontSize: "16px",
                    color: currentTable === "all" ? "#FFFFFF" : "rgba(255, 255, 255, 0.5)",
                    cursor: "pointer"
                  }}
                  variant={currentTable === "all" ? "contained" : "outlined"}
                  onClick={() => handleTableSwitch("all")}
                >
                  All Usage
                </CustomTypography1>

                <CustomTypography1
                  sx={{
                    fontFamily: "Lato",
                    fontWeight: 700,
                    fontSize: "16px",
                    color: currentTable === "meeting" ? "#FFFFFF" : "rgba(255, 255, 255, 0.5)",
                    cursor: "pointer"
                  }}
                  variant={currentTable === "meeting" ? "contained" : "outlined"}
                  onClick={() => handleTableSwitch("meeting")}
                >
                  Meeting Usage
                </CustomTypography1>

                <CustomTypography1
                  sx={{
                    fontFamily: "Lato",
                    fontWeight: 700,
                    fontSize: "16px",
                    color:currentTable==="words"?"#FFFFFF":"rgba(255, 255, 255, 0.5)",
                    cursor: "pointer"
                  }}
                  variant={currentTable === "words" ? "contained" : "outlined"}
                  onClick={() => handleTableSwitch("words")}
                >
                  Words Usage
                </CustomTypography1>
              </Box>

              <Box sx={{ mt: 4 }}>{renderTableContent()}</Box>



            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default BusinessAdminManagePlan;
