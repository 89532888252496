import * as React from "react";
import PropTypes from 'prop-types'
import { styled } from "@mui/material/styles";
import FormGroup from "@mui/material/FormGroup";
import Switch from "@mui/material/Switch";
import Stack from "@mui/material/Stack";
//import { Height } from "@mui/icons-material";


const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 43, // Increased width
  height: 22, // Increased height
  padding: 0,
  
  display: "flex",
  alignItems: "center", // Ensures content inside the switch is vertically aligned
  "& .MuiSwitch-switchBase": {
    padding: 3, // Adjust padding for proper alignment
    top: 0, // Ensures the thumb is centered vertically
    "&.Mui-checked": {
      transform: "translateX(21px)", // Properly adjust the translation when checked
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: "#177ddc", // Background when checked
      },
      "& .MuiSwitch-thumb": {
        backgroundColor: "#fff", // Thumb color when checked (white)
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: "16px", // Thumb size
    height: "16px", // Thumb size
    borderRadius: 8, // Thumb border radius
    backgroundColor: "rgba(26, 28, 29, 1)", // Thumb color when unchecked (default)
    transition: theme.transitions.create(["width", "background-color"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 22 / 2, // Adjusted border radius
    opacity: 1,
    backgroundColor: "rgba(66, 66, 77, 1)", // Track background color when unchecked
    boxSizing: "border-box",
  },
}));




export default function CustomizedSwitch({checked,setChecked}) {
  

  const handleChange = (event) => {
    setChecked(event.target.checked);
    console.log("Switch is now: ", event.target.checked); // For demonstration, replace with actual handling logic
  };
  return (
    <FormGroup>
      <Stack direction="row" spacing={1} alignItems="center">
        <AntSwitch
          checked={checked}
          onChange={handleChange}
          defaultChecked
          inputProps={{ "aria-label": "ant design" }}
        />
      </Stack>
    </FormGroup>
  );
}

CustomizedSwitch.propTypes ={
  checked:PropTypes.bool,
  setChecked:PropTypes.func
}
