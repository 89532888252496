import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/system";
//import { BpRadio } from "../../components/Radio/CustomRadio";
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Box,
  Button,
  IconButton,
  TextField,
  Autocomplete,
  InputLabel,
  Chip,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { WHITE } from "../../theme/Palette";
import {  useSelector } from "react-redux";
//import { getSupportedLanguages } from "../../redux/slices/MeetingSlice";
//import { GetUser } from "../../redux/slices/UserSlice";
//import { BpRadio } from "../../components/Radio/CustomRadio";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';




const CustomDialog = styled(Dialog)(() => ({
  "& .MuiDialog-paper": {
    borderRadius: "8px",
    background: "#222526",
    color: "#fff",
    padding: "10px",
  },
}));

const CustomDialogActions = styled(DialogActions)(() => ({
  display: "flex",
  justifyContent: "space-between",
  padding: "10px",
}));

const CloseButton = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  right: theme.spacing(1),
  top: theme.spacing(1),
  border: "1px solid",
  borderColor: "rgba(255, 255, 255, 0.15)",
  borderRadius: "30%",
  color: "rgba(255, 255, 255, 0.15)",
}));

const TranslationSetupModal = ({
  open,
  onClose,
  setYourLanguage,
  setTranslationLanguages,
  setTranslationType
}) => {
  const [translationTypes, setTranslationTypes] = useState("one_to_one"); // Set initial translationType
  const [selectedLanguages, setSelectedLanguages] = useState([]);
  const [yourLanguage, setYourLanguageState] = useState(null);
  //const dispatch = useDispatch();
  const { supportedLanguages, isLoading } = useSelector((state) => state.meeting);
  const { user } = useSelector((state) => state.user);

  // useEffect(() => {
  //   if (open) {
  //     dispatch(getSupportedLanguages());
  //     dispatch(GetUser());
  //   }
  // }, [dispatch, open]);

  useEffect(() => {
    if (user && user.preferred_language) {
      const language = {
        label: user.preferred_language,
        code: user.preferred_language_code || "",
      };
      setYourLanguageState(language);
      setYourLanguage(language.label);
    }
  }, [user, setYourLanguage]);

  const languageOptions =
    supportedLanguages?.map((language) => ({
      label: language.name,
      code: language.code,
    })) || [];

  // Handler for changing the translation type
  const handleTranslationTypeChange = (event) => {
    const newType = event.target.value;
    setTranslationTypes(newType);
    setTranslationType(newType);
  };

  const handleClose = () => {
    //setTranslationType(translationTypes); // Ensure translation type is set before closing
    onClose(false);
    //setTranslationLanguages(selectedLanguages.map((lang) => lang.label));
  };

  const handleYourLanguageChange = (event, newValue) => {
    setYourLanguageState(newValue || {});
   // setYourLanguage(newValue.label || {}); // Pass updated value to parent component
   setYourLanguage(newValue ? newValue.label : ""); // Pass empty string to parent if newValue is null
  };

  const handleTranslationLanguageChange = (event, newValue) => {
    if (Array.isArray(newValue)) {
      setSelectedLanguages(newValue);
      setTranslationLanguages(newValue.map((lang) => lang.label)); // Pass to parent
    } else if (newValue) {
      setSelectedLanguages([newValue]);
      setTranslationLanguages([newValue.label]); // Pass to parent
    } else {
      setSelectedLanguages([]);
      setTranslationLanguages([]);
    }
  };

  const handleSave = () => {
    const email = localStorage.getItem("email");
    if (email && yourLanguage?.label) {
      console.log("Translation Type:", translationTypes); // Check if translationType is set correctly
      console.log("Language Saved:", yourLanguage.label);
      setTranslationType(translationTypes); // Set translation type before saving
      onClose();
    } else {
      console.error("Email or preferred language is missing.");
    }
  };




  return (
    <CustomDialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle
        sx={{
          padding: "10px 16px",
          fontFamily: "Lato",
          fontSize: "16px",
          lineHeight: "24px",
          marginTop: "10px",
          fontWeight: 400,
          color: "#FFFFFF",
        }}
      >
        Translation Setup
      </DialogTitle>

      <CloseButton onClick={handleClose}
        sx={{
          width: "36px",
          height: "36px",
          marginTop: "10px",
          marginRight: "20px"
        }}>
        <CloseIcon sx={{ width: '18px', height: "18px", color: "rgba(255, 255, 255, 0.5)" }} />
      </CloseButton>

      <DialogContent sx={{ marginTop: "10px" }}>
        <FormControl component="fieldset">
          <RadioGroup value={translationTypes} onChange={handleTranslationTypeChange} row>
            <Box display="flex" gap="2rem">
              <FormControlLabel
                value="one_to_one"
                control={
                  <Radio
                    sx={{
                      transform: "scale(1.3)",
                      "& .MuiSvgIcon-root": { fontSize: "24px" },
                      "&:not(.Mui-checked) .MuiSvgIcon-root": { backgroundColor: "rgba(26, 28, 29, 0.7)", },
                      "&.Mui-checked": {
                        transform: "scale(1.0)",
                        "& .MuiSvgIcon-root": { fontSize: "20px" },
                      },
                      "&.Mui-checked .MuiSvgIcon-root": {
                        border: "7px solid rgba(0, 146, 248, 1)", // Increase border thickness when checked
                        width: "20px", // Set size of the radio button
                        height: "20px",
                        backgroundColor: "white", // Set checked inner color to white
                        color: "white"
                      },
                    }}
                  />
                }
                label="One to one (language)"
                sx={{
                  "& .MuiSvgIcon-root": {
                    borderRadius: "50%", // Make it circular
                    width: "20px", // Set size of the radio button
                    height: "20px",
                    backgroundColor: "rgba(26, 28, 29, 1)",
                    color: "rgba(255, 255, 255, 0.13)",

                    "&:hover": {
                      backgroundColor: "", // Keep the inside white on hover
                    },
                  },
                  "&.Mui-checked .MuiSvgIcon-root": {
                    border: "7px solid rgba(0, 146, 248, 1)", // Increase border thickness when checked
                    width: "20px",
                    height: "20px",
                    backgroundColor: "white", // Set checked inner color to white
                    color: "white"
                  },
                  "& .MuiFormControlLabel-label": {
                    fontFamily: 'Lato',
                    fontSize: "14px",
                    fontWeight: 500

                  },
                }}
              />

              <FormControlLabel
                value="one_to_many"
                control={
                  <Radio
                    sx={{
                      transform: "scale(1.3)",
                      "& .MuiSvgIcon-root": { fontSize: "24px" },
                      "&:not(.Mui-checked) .MuiSvgIcon-root": { backgroundColor: "rgba(26, 28, 29, 0.7)", },
                      "&.Mui-checked": {
                        transform: "scale(1.0)",
                        "& .MuiSvgIcon-root": { fontSize: "20px" },
                      },
                      "&.Mui-checked .MuiSvgIcon-root": {
                        border: "7px solid rgba(0, 146, 248, 1)", // Increase border thickness when checked
                        width: "20px", // Set size of the radio button
                        height: "20px",
                        backgroundColor: "white", // Set checked inner color to white
                        color: "white"
                      },
                    }}
                  />
                }
                label="One to many (language)"
                sx={{
                  "& .MuiSvgIcon-root": {
                    borderRadius: "50%", // Make it circular
                    width: "20px", // Set size of the radio button
                    height: "20px",
                    backgroundColor: "rgba(26, 28, 29, 1)",
                    color: "rgba(255, 255, 255, 0.13)",

                    "&:hover": {
                      backgroundColor: "", // Keep the inside white on hover
                    },
                  },
                  "&.Mui-checked .MuiSvgIcon-root": {
                    border: "7px solid rgba(0, 146, 248, 1)", // Increase border thickness when checked
                    width: "20px",
                    height: "20px",
                    backgroundColor: "white", // Set checked inner color to white
                    color: "white"
                  },
                  "& .MuiFormControlLabel-label": {
                    fontFamily: 'Lato',
                    fontSize: "14px",
                    fontWeight: 500

                  },
                }}
              />
            </Box>

          </RadioGroup>
        </FormControl>

        <Box sx={{ margin: "20px 0", mt: 7 }}>
          <InputLabel
            htmlFor="input-with-icon-textfield"
            sx={{
              color: "rgba(255, 255, 255, 0.5)",
              fontWeight: 400,
              fontSize: "14px",
              lineHeight: "21px",
              marginBottom: "-5px",
              display: "flex",
              fontFamily: "Lato",
            }}
          >
            Select your preferred language
          </InputLabel>

          <Autocomplete
            id="choose-your-language"
            options={languageOptions}
            loading={isLoading}
            value={yourLanguage || null}
            getOptionLabel={(option) => option.label || ""}
            onChange={handleYourLanguageChange}
            filterOptions={(options, params) => {
              const filtered = options.filter((option) =>
                option.label.toLowerCase().includes(params.inputValue.toLowerCase())
              );
              if (params.inputValue !== "" && !filtered.some((opt) => opt.label === params.inputValue)) {
                filtered.push({ label: params.inputValue, code: "" });
              }
              return filtered;
            }}
            popupIcon={<KeyboardArrowDownRoundedIcon  sx={{ height: "18px", width: "18px" }}/>}
            clearIcon={null} // Remove the clear icon 
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                placeholder="Language"
                sx={{
                  "& .MuiInput-underline:before": { borderBottom: `2px solid ${WHITE[50]}` },
                  "& .MuiInput-underline:after": { borderBottom: `2px solid ${WHITE[50]}` },
                  "& .MuiInput-underline:hover:not(.Mui-disabled):before": { borderBottom: `2px solid ${WHITE[50]}` },
                  "& .MuiInputBase-input": { padding: "15px", color: "white" },
                  "& .MuiInputBase-input::placeholder": { fontSize: "14px",
                     fontWeight: 400, color: "rgba(255, 255, 255, 0.7)" ,fontFamily:"Lato"},
                  "& .MuiSvgIcon-root": { color: "rgba(255, 255, 255, 0.5)" },
                }}
                InputProps={{
                  ...params.InputProps,
                  style: { paddingBlock: "10px", paddingInline: "0px",fontWeight:600,fontSize:"16px",fontFamily:"Lato" },
                }}
              />
            )}
          />
        </Box>

        <Box sx={{ margin: "20px 0", marginTop: 5 }}>
          <InputLabel
            htmlFor="translation-languages"
            sx={{ color: "rgba(255, 255, 255, 0.5)", fontWeight: 400, fontSize: "14px", lineHeight: "21px", fontFamily: "Lato" }}
          >
            Translation Language{" "}
            {translationTypes === "one_to_many"
              ? "(you can choose multiple languages)"
              : "(you can choose one language)"}
          </InputLabel>
          <Autocomplete
            sx={{ marginTop: "15px" }}
            id="translation-languages"
            options={languageOptions}
            loading={isLoading}
            multiple={translationTypes === "one_to_many"}
            getOptionLabel={(option) => option.label || ""}
            value={translationTypes === "one_to_many" ? selectedLanguages : selectedLanguages[0] || null}
            filterOptions={(options, params) => {
              const filtered = options.filter((option) =>
                option.label.toLowerCase().includes(params.inputValue.toLowerCase())
              );

              if (
                params.inputValue !== "" &&
                !filtered.some((option) => option.label === params.inputValue)
              ) {
                filtered.push({ label: params.inputValue, code: "" });
              }

              return filtered;
            }}
            onChange={handleTranslationLanguageChange}
            renderTags={(value, getTagProps) =>
              (value || []).map((option, index) => (
                <Chip
                  key={index}
                  label={option.label || option}

                  deleteIcon={<CloseRoundedIcon
                    sx={{
                      height: "16px",
                      width: "16px",
                    }} />} // Replace default close icon with normal close
                  sx={{
                    backgroundColor: "#1A1C1D",
                    color: "#fff",
                    borderRadius: "8px",
                    height: "36px",

                    fontSize: "14px",
                    fontFamily: "Lato"
                  }}
                  {...getTagProps({ index })}
                />
              ))
            }
            clearIcon={null} // Remove the clear icon 
            popupIcon={< KeyboardArrowDownRoundedIcon sx={{ height: "18px", width: "18px" }} />} // Use a standard dropdown arrow icon
            renderInput={(params) => (

              <TextField
                {...params}
                variant="standard"
                placeholder={
                  selectedLanguages.length === 0
                    ? translationTypes === "one_to_many"
                      ? "Select translation languages"
                      : "Select translation language"
                    : "" // No placeholder when a language is selected
                }
                sx={{
                  "& .MuiInput-underline:before": {
                    borderBottom: `2px solid ${WHITE[50]}`,
                  },
                  "& .MuiInput-underline:after": {
                    borderBottom: `2px solid ${WHITE[50]}`,
                  },
                  "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                    borderBottom: `2px solid ${WHITE[50]}`,
                  },
                  "& .MuiSvgIcon-root": {
                    color: "rgba(255, 255, 255, 0.5)",
                  },

                  "& .MuiInputBase-input": {
                    padding: "15px",
                    color: "white",
                    "&::placeholder": {
                      fontSize: "14px", // Set placeholder font size
                      fontWeight: 400,  // Set placeholder font weight
                      color: "rgba(255, 255, 255, 0.7)", // Placeholder color
                      fontFamily:"Lato"
                    },
                  },
                  marginTop: "-13px", // Adjust this value to move the TextField upwards
                }}
                InputProps={{
                  ...params.InputProps,
                  style: { paddingBlock: "10px", paddingInline: "0px",fontWeight:600,fontSize:"16px",fontFamily:"Lato" },
                }}
              />
            )}
          />
        </Box>
      </DialogContent>
      <CustomDialogActions sx={{ marginTop: "2rem" }}>
        <Button
          variant="outlined"
          sx={{

            marginBottom: "10px",
            height: "46px",
            width: "124px",
            textTransform: "none",
            fontWeight: 400,
            fontSize: "16px",
            lineHeight: "19.2px",
            fontFamily: "Lato",
            paddingInline: "25px",
            borderColor: WHITE[50],
            color: "rgba(255, 255, 255, 1)",
            borderRadius: "8px",
            "&:hover": {
              borderColor: WHITE[50],
              backgroundColor:  "rgba(255, 255, 255, 0.1)",

            },
          }}
          onClick={handleClose}
        >
          Cancel
        </Button>

        <Button
          variant="contained"
          sx={{
            marginBottom: "10px",
            height: "46px",
            width: "124px",
            textTransform: "none",
            fontWeight: 400,
            fontSize: "16px",
            lineHeight: "19.2px",
            fontFamily: "Lato",
            borderRadius: "8px",
            paddingInline: "25px",
            backgroundColor: "#0092F8",
            color: "rgba(255, 255, 255, 1)",
            "&:hover": {
              backgroundColor: "#007CD3",
            },
          }}
          onClick={() => {
            handleSave();
            handleClose();
          }}

        >
          Save
        </Button>
      </CustomDialogActions>
    </CustomDialog>
  );
};

TranslationSetupModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  setYourLanguage: PropTypes.func.isRequired,
  setTranslationLanguages: PropTypes.func.isRequired,
  setTranslationType: PropTypes.func.isRequired,
};

export default TranslationSetupModal;
