import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import PersonIcon from "@mui/icons-material/Person";
import MailIcon from "@mui/icons-material/Mail";
import CallIcon from "@mui/icons-material/Call";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AddCircleIcon from "@mui/icons-material/AddCircle";
import LinkIcon from "@mui/icons-material/Link";
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
// import RecordVoiceOverIcon from "@mui/icons-material/RecordVoiceOver"; 
import { getSupportedLanguages } from "../../../../redux/slices/MeetingSlice";
import { Button, Grid, useTheme, Autocomplete, TextField, Dialog, DialogTitle, DialogContent, Typography, DialogActions } from "@mui/material";
import { Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import { CustomTextField } from "../../../../components/TextField/TextField";
import { PRIMARY, WHITE } from "../../../../theme/Palette";
import { CustomSubtitle, CustomTypography4 } from "../../../../components/Typography/Typography";
import { CloseButton, OutlinedButton } from "../../../../components/Butons/CustomButton";
import { GetUser, UpdateUser } from "../../../../redux/slices/UserSlice";
import { useDispatch, useSelector } from "react-redux";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import Businessprofileupdated from "../../../BusinessAdmin/Settings/Profile/BusinessAdminProfileUpdateSucess";
import BasicModal from "../../../../components/Modal/CustomModal";
import CloseIcon from '@mui/icons-material/Close';
import BusinessIcon from '@mui/icons-material/Business';



const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    borderRadius: "8px",
    background: "var(--222526, #222526)",
    boxShadow: "0px 6px 16px 0px rgba(153, 155, 168, 0.10)",
    p: 4,
};
export default function BusinessAdminEditprofile({
    userDetails,
    uploadedImage,
    onProfileUpdate,
}) {
    const dispatch = useDispatch();
    const theme = useTheme();

    const [openModal, setOpenModal] = useState(false);


    const [selectedLanguage, setSelectedLanguage] = useState(null);

    const { supportedLanguages, isLoading, error } = useSelector(
        (state) => state.meeting
    );

    useEffect(() => {
        dispatch(getSupportedLanguages());
    }, [dispatch]);

    useEffect(() => {
        if (supportedLanguages.length > 0) {
            console.log("Fetched Languages in Component:", supportedLanguages);
        } else if (isLoading) {
            console.log("Loading supported languages...");
        } else if (error) {
            console.log("Error fetching languages:", error);
        } else {
            console.log("No supported languages available.");
        }
    }, [supportedLanguages, isLoading, error]);

    // Transform the supportedLanguages array to match the Autocomplete expectations
    const languageOptions =
        supportedLanguages?.map((language) => ({
            label: language.name,
            code: language.code,
        })) || [];

    useEffect(() => {
        setAdditional_email(userDetails.additional_email ? true : false);
        setSelectedLanguage({
            label: userDetails.preferred_language,
            code: userDetails.preferred_language_code || "",
        });
    }, [userDetails]);

    const [additional_email, setAdditional_email] = useState(false);
    const [additional_phone_number, setAdditional_phone_number] = useState(false);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            userId: userDetails.id ? userDetails.id : "",
            first_name: userDetails.first_name
                ? userDetails.first_name + " " + userDetails.last_name
                : "",
            last_name: userDetails.last_name ? userDetails.last_name : "",
            email: userDetails.email ? userDetails.email : "",
            phone: userDetails.phone ? userDetails.phone : "",
            country: userDetails.country ? userDetails.country : "",
            preferred_language: userDetails.preferred_language || "",
            voice_preference: userDetails.voice_preference || "",
            additional_email: userDetails.additional_email || "",
            additional_phone_number: userDetails.additional_phone_number || "",
            social: userDetails.social || "",
            link: userDetails.link || "",
            profile_picture: userDetails.profile_picture || "",
        },
        validationSchema: Yup.object().shape({
            first_name: Yup.string().required("Full name is required"),
            email: Yup.string().email("Invalid email").required("Email is required"),
            phone: Yup.string().required("Phone number is required"),
        }),
        onSubmit: async (values, { resetForm }) => {
            try {
                const updatedValues = {
                    userId: values.userId, // Ensure userId is included here
                    first_name: values.first_name.split(" ")[0],
                    last_name: values.first_name.split(" ")[1] || "",
                    email: values.email,
                    phone: values.phone,
                    country: values.country,
                    preferred_language: selectedLanguage?.label || values.preferred_language, // Set selected language
                    voice_preference: values.voice_preference,
                    additional_email: values.additional_email,
                    additional_phone_number: values.additional_phone_number,
                    social: values.social,
                    link: values.link,
                    profile_picture: uploadedImage,
                };
                await dispatch(UpdateUser(updatedValues));
                await dispatch(GetUser(userDetails.id)); // Fetch updated user details
                if (uploadedImage) {
                    const newImageUrl = URL.createObjectURL(uploadedImage);
                    onProfileUpdate(newImageUrl);
                }
                resetForm();
            } catch (error) {
                console.error("Error updating user: ", error);
            }
        },
    });

    const {
        errors,
        values,
        touched,
        handleSubmit,
        handleChange,
    } = formik;


    const [openConfirmModal, setOpenConfirmModal] = useState(false);

    // Function to open the modal
    const handleOpenConfirmModal = () => {
        setOpenConfirmModal(true);
    };

    // Function to close the modal
    const handleCloseConfirmModal = () => {
        setOpenConfirmModal(false);
    };

    return (
        <>
            <BasicModal open={openModal} setOpen={setOpenModal} style={style}>
                <Businessprofileupdated onClose={setOpenModal} />
            </BasicModal>

            <FormikProvider value={formik}>
                <Box sx={{ mt: { md: 10 }, p: 3 }}>
                    <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={6}>

                                <InputLabel
                                    htmlFor="input-with-icon-textfield"
                                    sx={{
                                        color: PRIMARY.dark,
                                        fontWeight: 400,
                                        fontSize: "14px",
                                        lineHeight: "21px",
                                        fontFamily: "Lato"
                                    }}
                                >
                                    Full name
                                </InputLabel>
                                <CustomTextField
                                    sx={{
                                        width: {
                                            xs: '100%',      // 100% width on extra-small screens (mobile)
                                            sm: '100%',      // 100% width on small screens (larger mobile devices)
                                            md: '100%',      // 100% width on medium screens (tablets)
                                            lg: '440px',     // 440px width on large screens (desktops)
                                            xl: '440px',     // Keep the width at 440px for extra-large screens (large desktops)
                                        },
                                        '@media (min-width: 1200px) and (max-width: 1530px)': {
                                            width: '100%',  // Full width between 1200px and 1530px screen sizes
                                        },
                                        "& .MuiInput-underline:before": {
                                            borderBottomColor: "rgba(245, 245, 250, 0.3)", // Default underline color
                                        },
                                        "& .MuiInput-underline:hover:before": {
                                            borderBottomColor: "rgba(245, 245, 250, 0.4)", // Underline color on hover
                                        },
                                        "& .MuiInput-underline:after": {
                                            borderBottomColor: "rgba(245, 245, 250, 0.4)", // Underline color when focused
                                        },
                                        "& .MuiInputBase-input::placeholder": {
                                            fontFamily: "Lato"
                                        },
                                    }}

                                    id="input-with-icon-textfield"
                                    name="first_name"
                                    value={values.first_name}
                                    onChange={handleChange}
                                    placeholder="Start typing ..."
                                    InputProps={{
                                        style: {
                                            fontFamily: "Lato",
                                            fontSize: "16px",
                                            fontStyle: "normal",
                                            fontWeight: 500,
                                            lineHeight: "19.2px",
                                        },
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <PersonIcon
                                                    sx={{
                                                        height: "18px",
                                                        width: "18px",
                                                        color: PRIMARY.dark,
                                                        cursor: "pointer"
                                                    }}
                                                />
                                            </InputAdornment>
                                        ),
                                        sx: {
                                            "& .MuiInputBase-input::placeholder": {
                                                style: { fontSize: "16px", fontFamily: "Lato" },
                                            },
                                        },
                                    }}
                                    variant="standard"
                                    error={Boolean(touched.first_name && errors.first_name)}
                                    helperText={touched.first_name && errors.first_name}
                                />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <InputLabel
                                    htmlFor="input-with-icon-textfield"
                                    sx={{
                                        color: PRIMARY.dark,
                                        fontWeight: 400,
                                        fontSize: "14px",
                                        lineHeight: "21px",
                                        cursor: "pointer"
                                    }}
                                >
                                    Voice preference
                                </InputLabel>
                                <CustomTextField
                                    sx={{
                                        width: {
                                            xs: '100%',      // 100% width on extra-small screens (mobile)
                                            sm: '100%',      // 100% width on small screens (larger mobile devices)
                                            md: '100%',      // 100% width on medium screens (tablets)
                                            lg: '440px',     // 440px width on large screens (desktops)
                                            xl: '440px',     // Keep the width at 440px for extra-large screens (large desktops)
                                        },
                                        '@media (min-width: 1200px) and (max-width: 1530px)': {
                                            width: '100%',  // Full width between 1200px and 1530px screen sizes
                                        },
                                        "& .MuiInput-underline:before": {
                                            borderBottomColor: "rgba(245, 245, 250, 0.3)", // Default underline color
                                        },
                                        "& .MuiInput-underline:hover:before": {
                                            borderBottomColor: "rgba(245, 245, 250, 0.4)", // Underline color on hover
                                        },
                                        "& .MuiInput-underline:after": {
                                            borderBottomColor: "rgba(245, 245, 250, 0.4)", // Underline color when focused
                                        },
                                        "& .MuiInputBase-input::placeholder": {
                                            fontFamily: "Lato"
                                        },
                                    }}
                                    id="input-with-icon-textfield"
                                    name="role"
                                    value={values.role}
                                    onChange={handleChange}
                                    placeholder="Start typing"
                                    InputProps={{
                                        style: {
                                            fontFamily: "Lato",
                                            fontSize: "16px",
                                            fontStyle: "normal",
                                            fontWeight: 500,
                                            lineHeight: "19.2px",
                                        },
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <RecordVoiceOverIcon
                                                    sx={{
                                                        height: "18px",
                                                        width: "18px",
                                                        color: PRIMARY.dark,
                                                    }}
                                                />
                                            </InputAdornment>
                                        ),
                                        sx: {
                                            "& .MuiInputBase-input::placeholder": {
                                                style: { fontSize: "16px", fontFamily: "Lato" },
                                            },
                                        },
                                    }}
                                    variant="standard"
                                    error={Boolean(touched.role && errors.role)}
                                    helperText={touched.role && errors.role}
                                />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <InputLabel
                                    htmlFor="input-with-icon-textfield"
                                    sx={{
                                        color: PRIMARY.dark,
                                        fontWeight: 400,
                                        fontSize: "14px",
                                        lineHeight: "21px",
                                        fontFamily: "Lato"
                                    }}
                                >
                                    Email
                                </InputLabel>
                                <CustomTextField
                                    sx={{
                                        width: {
                                            xs: '100%',      // 100% width on extra-small screens (mobile)
                                            sm: '100%',      // 100% width on small screens (larger mobile devices)
                                            md: '100%',      // 100% width on medium screens (tablets)
                                            lg: '440px',     // 440px width on large screens (desktops)
                                            xl: '440px',     // Keep the width at 440px for extra-large screens (large desktops)
                                        },
                                        '@media (min-width: 1200px) and (max-width: 1530px)': {
                                            width: '100%',  // Full width between 1200px and 1530px screen sizes
                                        },
                                        "& .MuiInput-underline:before": {
                                            borderBottomColor: "rgba(245, 245, 250, 0.3)", // Default underline color
                                        },
                                        "& .MuiInput-underline:hover:before": {
                                            borderBottomColor: "rgba(245, 245, 250, 0.4)", // Underline color on hover
                                        },
                                        "& .MuiInput-underline:after": {
                                            borderBottomColor: "rgba(245, 245, 250, 0.4)", // Underline color when focused
                                        },
                                        "& .MuiInputBase-input::placeholder": {
                                            fontFamily: "Lato"
                                        },
                                    }}

                                    id="input-with-icon-textfield"
                                    name="email"
                                    onChange={handleChange}
                                    value={values.email}
                                    placeholder="Start typing..."
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <MailIcon
                                                    sx={{
                                                        height: "18px",
                                                        width: "18px",
                                                        color: PRIMARY.dark,
                                                        cursor: "pointer"
                                                    }}
                                                />
                                            </InputAdornment>
                                        ),
                                        sx: {
                                            "& .MuiInputBase-input::placeholder": {
                                                fontSize: "16px",
                                                lineHeight: "19.2px",
                                                fontFamily: "Lato",

                                            },
                                        },
                                    }}
                                    variant="standard"
                                    error={Boolean(touched.email && errors.email)}
                                    helperText={touched.email && errors.email}
                                />
                            </Grid>

                            {!additional_email ? (
                                <Grid
                                    item
                                    xs={12}
                                    md={6}
                                    sx={{
                                        display: "flex",
                                        justifyContent: "flex-start",
                                        alignItems: "center",
                                    }}
                                >
                                    <Box sx={{ display: "contents" }}>
                                        <AddCircleIcon
                                            sx={{
                                                color: "rgba(245, 245, 250, 0.4)",
                                                mr: 2,
                                                height: "18px",
                                                width: "18px",
                                                mt: '20px',
                                                cursor: "pointer"
                                            }}
                                            onClick={() => setAdditional_email(true)}
                                        />
                                        <CustomSubtitle
                                            sx={{
                                                fontWeight: 700,
                                                fontSize: "12px",
                                                lineHeight: "18px",
                                                color: "var(--ffffff-50, rgba(255, 255, 255, 0.50))",
                                                mt: "20px",
                                                fontFamily: "Lato"
                                            }}
                                        >
                                            Add Email
                                        </CustomSubtitle>
                                    </Box>
                                </Grid>
                            ) : (
                                <Grid item xs={12} md={6}>
                                    <InputLabel
                                        htmlFor="input-with-icon-textfield"
                                        sx={{
                                            color: PRIMARY.dark,
                                            fontWeight: 400,
                                            fontSize: "14px",
                                            lineHeight: "21px",
                                            fontFamily: "Lato"
                                        }}
                                    >
                                        Additional Email
                                    </InputLabel>
                                    <CustomTextField
                                        sx={{
                                            width: {
                                                xs: '100%',      // 100% width on extra-small screens (mobile)
                                                sm: '100%',      // 100% width on small screens (larger mobile devices)
                                                md: '100%',      // 100% width on medium screens (tablets)
                                                lg: '440px',     // 440px width on large screens (desktops)
                                                xl: '440px',     // Keep the width at 440px for extra-large screens (large desktops)
                                            },
                                            '@media (min-width: 1200px) and (max-width: 1530px)': {
                                                width: '100%',  // Full width between 1200px and 1530px screen sizes
                                            },
                                            "& .MuiInput-underline:before": {
                                                borderBottomColor: "rgba(245, 245, 250, 0.3)", // Default underline color
                                            },
                                            "& .MuiInput-underline:hover:before": {
                                                borderBottomColor: "rgba(245, 245, 250, 0.4)", // Underline color on hover
                                            },
                                            "& .MuiInput-underline:after": {
                                                borderBottomColor: "rgba(245, 245, 250, 0.4)", // Underline color when focused
                                            },
                                            "& .MuiInputBase-input::placeholder": {
                                                fontFamily: "Lato"
                                            },
                                        }}
                                        id="input-with-icon-textfield"
                                        name="additional_email"
                                        onChange={handleChange}
                                        value={values.additional_email}
                                        placeholder="Start typing..."
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <MailIcon
                                                        sx={{
                                                            height: "18px",
                                                            width: "18px",
                                                            color: PRIMARY.dark,
                                                            cursor: "pointer"
                                                        }}
                                                    />
                                                </InputAdornment>
                                            ),
                                            sx: {
                                                '& .MuiInputBase-input::placeholder': {
                                                    fontSize: "16px",
                                                    lineHeight: "19.2px",
                                                    fontFamily: "Lato",

                                                },
                                            },
                                        }}
                                        variant="standard"
                                        error={Boolean(
                                            touched.additional_email && errors.additional_email
                                        )}
                                        helperText={
                                            touched.additional_email && errors.additional_email
                                        }
                                    />
                                </Grid>
                            )}

                            <Grid item xs={12} md={6}>
                                <InputLabel
                                    htmlFor="input-with-icon-textfield"
                                    sx={{
                                        color: PRIMARY.dark,
                                        fontWeight: 400,
                                        fontSize: "14px",
                                        lineHeight: "21px",
                                        fontFamily: "Lato"
                                    }}
                                >
                                    Phone
                                </InputLabel>
                                <CustomTextField
                                    sx={{
                                        width: {
                                            xs: '100%',      // 100% width on extra-small screens (mobile)
                                            sm: '100%',      // 100% width on small screens (larger mobile devices)
                                            md: '100%',      // 100% width on medium screens (tablets)
                                            lg: '440px',     // 440px width on large screens (desktops)
                                            xl: '440px',     // Keep the width at 440px for extra-large screens (large desktops)
                                        },
                                        '@media (min-width: 1200px) and (max-width: 1530px)': {
                                            width: '100%',  // Full width between 1200px and 1530px screen sizes
                                        },
                                        "& .MuiInput-underline:before": {
                                            borderBottomColor: "rgba(245, 245, 250, 0.3)", // Default underline color
                                        },
                                        "& .MuiInput-underline:hover:before": {
                                            borderBottomColor: "rgba(245, 245, 250, 0.4)", // Underline color on hover
                                        },
                                        "& .MuiInput-underline:after": {
                                            borderBottomColor: "rgba(245, 245, 250, 0.4)", // Underline color when focused
                                        },
                                        "& .MuiInputBase-input::placeholder": {
                                            fontFamily: "Lato"
                                        },
                                    }}
                                    id="input-with-icon-textfield"
                                    name="phone"
                                    onChange={handleChange}
                                    value={values.phone}
                                    placeholder="Start typing..."
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <CallIcon
                                                    sx={{
                                                        height: "18px",
                                                        width: "18px",
                                                        color: PRIMARY.dark,
                                                        cursor: "pointer"
                                                    }}
                                                />
                                            </InputAdornment>
                                        ),
                                        sx: {
                                            "& .MuiInputBase-input::placeholder": {
                                                fontSize: "16px",
                                                lineHeight: "19.2px",
                                                fontFamily: "Lato",
                                            },
                                        },
                                    }}
                                    inputProps={{
                                        inputMode: 'numeric',  // Mobile keyboard shows numbers
                                        pattern: '[0-9]*',     // Only allows numeric input
                                        onKeyDown: (e) => {
                                            if (!/[0-9]/.test(e.key) && e.key !== "Backspace" && e.key !== "Delete") {
                                                e.preventDefault();  // Block non-numeric characters
                                            }
                                        },
                                    }}
                                    variant="standard"
                                    error={Boolean(touched.phone && errors.phone)}
                                    helperText={touched.phone && errors.phone}
                                />
                            </Grid>

                            {!additional_phone_number ? (
                                <Grid
                                    item
                                    xs={12}
                                    md={6}
                                    sx={{
                                        display: "flex",
                                        justifyContent: "flex-start",
                                        alignItems: "center",
                                    }}
                                >
                                    <Box sx={{ display: "contents" }}>
                                        <AddCircleIcon
                                            sx={{
                                                color: "rgba(245, 245, 250, 0.4)",
                                                mr: 2,
                                                height: "18px",
                                                width: "18px",
                                                mt: "20px",
                                                cursor: "pointer"
                                            }}
                                            onClick={() => setAdditional_phone_number(true)}
                                        />
                                        <CustomSubtitle
                                            sx={{
                                                fontWeight: 700,
                                                fontSize: "12px",
                                                lineHeight: "18px",
                                                color: "var(--ffffff-50, rgba(255, 255, 255, 0.50))",
                                                mt: "20px",
                                                fontFamily: "Lato"
                                            }}
                                        >
                                            Add Phone Number
                                        </CustomSubtitle>
                                    </Box>
                                </Grid>
                            ) : (
                                <Grid item xs={12} md={6}>
                                    <InputLabel
                                        htmlFor="input-with-icon-textfield"

                                        sx={{
                                            color: PRIMARY.dark,
                                            fontWeight: 400,
                                            fontSize: "14px",
                                            lineHeight: "21px",
                                            fontFamily: "Lato"
                                        }}

                                    >
                                        Additional Phone Number
                                    </InputLabel>
                                    <CustomTextField
                                        sx={{
                                            width: {
                                                xs: '100%',      // 100% width on extra-small screens (mobile)
                                                sm: '100%',      // 100% width on small screens (larger mobile devices)
                                                md: '100%',      // 100% width on medium screens (tablets)
                                                lg: '440px',     // 440px width on large screens (desktops)
                                                xl: '440px',     // Keep the width at 440px for extra-large screens (large desktops)
                                            },
                                            '@media (min-width: 1200px) and (max-width: 1530px)': {
                                                width: '100%',  // Full width between 1200px and 1530px screen sizes
                                            },
                                            "& .MuiInput-underline:before": {
                                                borderBottomColor: "rgba(245, 245, 250, 0.3)", // Default underline color
                                            },
                                            "& .MuiInput-underline:hover:before": {
                                                borderBottomColor: "rgba(245, 245, 250, 0.4)", // Underline color on hover
                                            },
                                            "& .MuiInput-underline:after": {
                                                borderBottomColor: "rgba(245, 245, 250, 0.4)", // Underline color when focused
                                            },
                                            "& .MuiInputBase-input::placeholder": {
                                                fontFamily: "Lato"
                                            },
                                        }}
                                        id="input-with-icon-textfield"
                                        name="additional_phone_number"
                                        onChange={handleChange}
                                        value={values.additional_phone_number}
                                        placeholder="Start typing..."
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <CallIcon
                                                        sx={{
                                                            height: "18px",
                                                            width: "18px",
                                                            color: PRIMARY.dark,
                                                            cursor: "pointer"
                                                        }}
                                                    />
                                                </InputAdornment>
                                            ),
                                            sx: {
                                                '& .MuiInputBase-input::placeholder': {
                                                    fontSize: "16px",
                                                    lineHeight: "19.2px",
                                                    fontFamily: "Lato",
                                                },
                                            },
                                        }}
                                        inputProps={{
                                            inputMode: 'numeric',  // Mobile keyboard shows numbers
                                            pattern: '[0-9]*',     // Only allows numeric input
                                            onKeyDown: (e) => {
                                                if (!/[0-9]/.test(e.key) && e.key !== "Backspace" && e.key !== "Delete") {
                                                    e.preventDefault();  // Block non-numeric characters
                                                }
                                            },
                                        }}
                                        variant="standard"
                                        error={Boolean(
                                            touched.additional_phone_number &&
                                            errors.additional_phone_number
                                        )}
                                        helperText={
                                            touched.additional_phone_number &&
                                            errors.additional_phone_number
                                        }
                                    />
                                </Grid>
                            )}

                            <Grid item xs={12} md={6}>
                                <InputLabel
                                    htmlFor="input-with-icon-textfield"
                                    sx={{
                                        color: PRIMARY.dark,
                                        fontWeight: 400,
                                        fontSize: "14px",
                                        lineHeight: "21px",
                                        fontFamily: "Lato"
                                    }}
                                >
                                    Adress line
                                </InputLabel>

                                <CustomTextField
                                    sx={{
                                        width: {
                                            xs: '100%',      // 100% width on extra-small screens (mobile)
                                            sm: '100%',      // 100% width on small screens (larger mobile devices)
                                            md: '100%',      // 100% width on medium screens (tablets)
                                            lg: '440px',     // 440px width on large screens (desktops)
                                            xl: '440px',     // Keep the width at 440px for extra-large screens (large desktops)
                                        },
                                        '@media (min-width: 1200px) and (max-width: 1530px)': {
                                            width: '100%',  // Full width between 1200px and 1530px screen sizes
                                        },
                                        "& .MuiInput-underline:before": {
                                            borderBottomColor: "rgba(245, 245, 250, 0.3)", // Default underline color
                                        },
                                        "& .MuiInput-underline:hover:before": {
                                            borderBottomColor: "rgba(245, 245, 250, 0.4)", // Underline color on hover
                                        },
                                        "& .MuiInput-underline:after": {
                                            borderBottomColor: "rgba(245, 245, 250, 0.4)", // Underline color when focused
                                        },
                                        "& .MuiInputBase-input::placeholder": {
                                            fontFamily: "Lato"
                                        },
                                    }}
                                    id="input-with-icon-textfield"
                                    name="social"
                                    onChange={handleChange}
                                    value={values.social}
                                    placeholder="Start typing..."
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <LocationOnIcon
                                                    sx={{
                                                        height: "18px",
                                                        width: "18px",
                                                        color: PRIMARY.dark,
                                                        cursor: "pointer"
                                                    }}
                                                />
                                            </InputAdornment>
                                        ),
                                        sx: {
                                            "& .MuiInputBase-input::placeholder": {
                                                fontSize: "16px",
                                                lineHeight: "19.2px",
                                                fontFamily: "Lato",
                                            },
                                        },
                                    }}
                                    variant="standard"
                                    error={Boolean(touched.social && errors.social)}
                                    helperText={touched.social && errors.social}
                                />
                            </Grid>



                            <Grid item xs={12} md={6} >
                                <InputLabel
                                    htmlFor="input-with-icon-textfield"
                                    sx={{
                                        color: PRIMARY.dark,
                                        fontWeight: 400,
                                        fontSize: "14px",
                                        lineHeight: "16px",
                                        fontFamily: "Lato"
                                    }}
                                >
                                    Speaking Language
                                </InputLabel>

                                <Autocomplete
                                    sx={{
                                        width: {
                                            xs: '100%',      // 100% width on extra-small screens (mobile)
                                            sm: '100%',      // 100% width on small screens (larger mobile devices)
                                            md: '100%',      // 100% width on medium screens (tablets)
                                            lg: '440px',     // 440px width on large screens (desktops)
                                            xl: '440px',     // Keep the width at 440px for extra-large screens (large desktops)
                                        },
                                        '@media (min-width: 1200px) and (max-width: 1530px)': {
                                            width: '100%',
                                        },
                                        "& .MuiInput-underline:before": {
                                            borderBottomColor: "rgba(245, 245, 250, 0.3)", // Default underline color
                                            borderBottomWidth: "2px",
                                        },
                                        "& .MuiInput-underline:hover:before": {
                                            borderBottomColor: "rgba(245, 245, 250, 0.4)", // Underline color on hover
                                            borderBottomWidth: "2px",
                                        },
                                        "& .MuiInput-underline:after": {
                                            borderBottomColor: "rgba(245, 245, 250, 0.4)", // Underline color when focused
                                            borderBottomWidth: "2px",
                                        },
                                        "& .MuiInputBase-input::placeholder": {
                                            fontFamily: "Lato"
                                        },
                                    }}


                                    id="input-with-icon-textfield-language"
                                    options={languageOptions}
                                    getOptionLabel={(option) => option.label || ""}
                                    value={selectedLanguage}
                                    onChange={(event, newValue) => setSelectedLanguage(newValue)}

                                    popupIcon={

                                        <KeyboardArrowDownRoundedIcon
                                            sx={{

                                                width: "18px",
                                                height: "18px",
                                                color: PRIMARY.dark,
                                                cursor: "pointer"
                                            }}
                                        />
                                    }
                                    clearIcon={null}
                                    renderInput={(params) => (

                                        <TextField

                                            {...params}
                                            placeholder="Select language"
                                            variant="standard"
                                            InputProps={{
                                                ...params.InputProps,
                                                style: {
                                                    fontSize: "16px",

                                                    fontFamily: "Lato",
                                                    paddingBlock: "10px", // Adjust padding to match the TextField


                                                },
                                            }}
                                        />
                                    )}
                                    loading={isLoading}
                                    filterOptions={(options, { inputValue }) => {
                                        const filteredOptions = options.filter((option) =>
                                            option.label
                                                .toLowerCase()
                                                .includes(inputValue.toLowerCase())
                                        );
                                        return filteredOptions;
                                    }}
                                    ListboxProps={{
                                        style: {
                                            maxHeight: "150px",
                                            overflow: "auto",
                                        },
                                    }}

                                />
                                {/* <CustomTextField
                sx={{ width: { xs: '100%', md: '100%',lg:"440px" } }}
                id="input-with-icon-textfield"
                name="preferred_language"
                onChange={handleChange}
                value={values.preferred_language}
                placeholder="Select language"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <KeyboardArrowDownIcon
                        sx={{
                          fontSize: "18px",
                          color: PRIMARY.dark,
                          cursor:"pointer"
                        }}
                      />
                    </InputAdornment>
                  ),
                  sx: {
                    '& .MuiInputBase-input::placeholder': {
                      fontSize: '15px',
                      lineHeight: "19.2px",
                      fontFamily: "Lato",
                      fontWeight: 700,
                    },
                  },
                }}
                variant="standard"
                error={Boolean(touched.preferred_language && errors.preferred_language)}
                helperText={touched.preferred_language && errors.preferred_language}
              /> */}

                            </Grid>

                            <Grid item xs={12} md={6}>
                                <InputLabel
                                    htmlFor="input-with-icon-textfield"
                                    sx={{
                                        color: PRIMARY.dark,
                                        fontWeight: 400,
                                        fontSize: "14px",
                                        lineHeight: "21px",
                                        fontFamily: "Lato"
                                    }}
                                >
                                    Social
                                </InputLabel>
                                <CustomTextField
                                    sx={{
                                        width: {
                                            xs: '100%',      // 100% width on extra-small screens (mobile)
                                            sm: '100%',      // 100% width on small screens (larger mobile devices)
                                            md: '100%',      // 100% width on medium screens (tablets)
                                            lg: '440px',     // 440px width on large screens (desktops)
                                            xl: '440px',     // Keep the width at 440px for extra-large screens (large desktops)
                                        },
                                        '@media (min-width: 1200px) and (max-width: 1530px)': {
                                            width: '100%',  // Full width between 1200px and 1530px screen sizes
                                        },
                                        "& .MuiInput-underline:before": {
                                            borderBottomColor: "rgba(245, 245, 250, 0.3)", // Default underline color
                                        },
                                        "& .MuiInput-underline:hover:before": {
                                            borderBottomColor: "rgba(245, 245, 250, 0.4)", // Underline color on hover
                                        },
                                        "& .MuiInput-underline:after": {
                                            borderBottomColor: "rgba(245, 245, 250, 0.4)", // Underline color when focused
                                        },
                                        "& .MuiInputBase-input::placeholder": {
                                            fontFamily: "Lato"
                                        },
                                    }}
                                    id="input-with-icon-textfield"
                                    name="social"
                                    onChange={handleChange}
                                    value={values.social}
                                    placeholder="Select social profile"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <KeyboardArrowDownIcon
                                                    sx={{
                                                        height: "18px",
                                                        width: "18px",
                                                        color: PRIMARY.dark,
                                                        cursor: "pointer"
                                                    }}
                                                />
                                            </InputAdornment>
                                        ),
                                        sx: {
                                            "& .MuiInputBase-input::placeholder": {
                                                fontSize: "16px",
                                                lineHeight: "19.2px",
                                                fontFamily: "Lato",
                                            },
                                        },
                                    }}
                                    variant="standard"
                                    error={Boolean(touched.social && errors.social)}
                                    helperText={touched.social && errors.social}
                                />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <InputLabel
                                    htmlFor="input-with-icon-textfield"
                                    sx={{
                                        color: PRIMARY.dark,
                                        fontWeight: 400,
                                        fontSize: "14px",
                                        lineHeight: "21px",
                                        fontFamily: "Lato"
                                    }}
                                >
                                    Link
                                </InputLabel>
                                <CustomTextField
                                    sx={{
                                        width: {
                                            xs: '100%',      // 100% width on extra-small screens (mobile)
                                            sm: '100%',      // 100% width on small screens (larger mobile devices)
                                            md: '100%',      // 100% width on medium screens (tablets)
                                            lg: '440px',     // 440px width on large screens (desktops)
                                            xl: '440px',     // Keep the width at 440px for extra-large screens (large desktops)
                                        },
                                        '@media (min-width: 1200px) and (max-width: 1530px)': {
                                            width: '100%',  // Full width between 1200px and 1530px screen sizes
                                        },
                                        "& .MuiInput-underline:before": {
                                            borderBottomColor: "rgba(245, 245, 250, 0.3)", // Default underline color
                                        },
                                        "& .MuiInput-underline:hover:before": {
                                            borderBottomColor: "rgba(245, 245, 250, 0.4)", // Underline color on hover
                                        },
                                        "& .MuiInput-underline:after": {
                                            borderBottomColor: "rgba(245, 245, 250, 0.4)", // Underline color when focused
                                        },
                                        "& .MuiInputBase-input::placeholder": {
                                            fontFamily: "Lato"
                                        },
                                    }}
                                    id="input-with-icon-textfield"
                                    name="link"
                                    value={values.link}
                                    onChange={handleChange}
                                    placeholder="Enter link to your profile"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <LinkIcon
                                                    sx={{
                                                        height: "18px",
                                                        width: "18px",
                                                        color: PRIMARY.dark,
                                                        cursor: "pointer"
                                                    }}
                                                />
                                            </InputAdornment>
                                        ),
                                        sx: {
                                            "& .MuiInputBase-input::placeholder": {
                                                fontSize: "16px",
                                                lineHeight: "19.2px",
                                                fontFamily: "Lato",
                                            },
                                        },
                                    }}
                                    variant="standard"
                                    error={Boolean(touched.link && errors.link)}
                                    helperText={touched.link && errors.link}
                                />
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: { xs: "column", md: "row" },
                                        justifyContent: "flex-start",
                                        gap: 1,
                                        mt: { xs: 4, md: 5 },
                                    }}
                                >

                                    <Box sx={{ marginBottom: "3rem", fontSize: "18px", fontWeight: 700, fontFamily: "Lato" }}>
                                        <Typography>Company information</Typography></Box>
                                </Box>
                            </Grid>
                        </Grid>


                        <Grid container spacing={3}>
                            <Grid item xs={12} md={6}>

                                <InputLabel
                                    htmlFor="input-with-icon-textfield"
                                    sx={{
                                        color: PRIMARY.dark,
                                        fontWeight: 400,
                                        fontSize: "14px",
                                        lineHeight: "21px",
                                        fontFamily: "Lato"
                                    }}
                                >
                                    Company name
                                </InputLabel>
                                <CustomTextField
                                    sx={{
                                        width: {
                                            xs: '100%',      // 100% width on extra-small screens (mobile)
                                            sm: '100%',      // 100% width on small screens (larger mobile devices)
                                            md: '100%',      // 100% width on medium screens (tablets)
                                            lg: '440px',     // 440px width on large screens (desktops)
                                            xl: '440px',     // Keep the width at 440px for extra-large screens (large desktops)
                                        },
                                        '@media (min-width: 1200px) and (max-width: 1530px)': {
                                            width: '100%',  // Full width between 1200px and 1530px screen sizes
                                        },
                                        "& .MuiInput-underline:before": {
                                            borderBottomColor: "rgba(245, 245, 250, 0.3)", // Default underline color
                                        },
                                        "& .MuiInput-underline:hover:before": {
                                            borderBottomColor: "rgba(245, 245, 250, 0.4)", // Underline color on hover
                                        },
                                        "& .MuiInput-underline:after": {
                                            borderBottomColor: "rgba(245, 245, 250, 0.4)", // Underline color when focused
                                        },
                                        "& .MuiInputBase-input::placeholder": {
                                            fontFamily: "Lato"
                                        },
                                    }}
                                    id="input-with-icon-textfield"
                                    name="role"
                                    value={values.company}
                                    onChange={handleChange}
                                    placeholder="Start typing"
                                    InputProps={{
                                        style: {
                                            fontFamily: "Lato",
                                            fontSize: "16px",
                                            fontStyle: "normal",
                                            fontWeight: 500,
                                            lineHeight: "19.2px",
                                        },
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <BusinessIcon
                                                    sx={{
                                                        height: "18px",
                                                        width: "18px",
                                                        color: PRIMARY.dark,
                                                    }}
                                                />
                                            </InputAdornment>
                                        ),
                                        sx: {
                                            "& .MuiInputBase-input::placeholder": {
                                                style: { fontSize: "16px", fontFamily: "Lato" },
                                            },
                                        },
                                    }}
                                    variant="standard"
                                    error={Boolean(touched.company && errors.company)}
                                    helperText={touched.company && errors.company}
                                />

                            </Grid>

                            <Grid item xs={12} md={6}>
                                <InputLabel
                                    htmlFor="input-with-icon-textfield"
                                    sx={{
                                        color: PRIMARY.dark,
                                        fontWeight: 400,
                                        fontSize: "14px",
                                        lineHeight: "21px",
                                        cursor: "pointer"
                                    }}
                                >
                                    Location
                                </InputLabel>
                                <CustomTextField
                                    sx={{
                                        width: {
                                            xs: '100%',      // 100% width on extra-small screens (mobile)
                                            sm: '100%',      // 100% width on small screens (larger mobile devices)
                                            md: '100%',      // 100% width on medium screens (tablets)
                                            lg: '440px',     // 440px width on large screens (desktops)
                                            xl: '440px',     // Keep the width at 440px for extra-large screens (large desktops)
                                        },
                                        '@media (min-width: 1200px) and (max-width: 1530px)': {
                                            width: '100%',  // Full width between 1200px and 1530px screen sizes
                                        },
                                        "& .MuiInput-underline:before": {
                                            borderBottomColor: "rgba(245, 245, 250, 0.3)", // Default underline color
                                        },
                                        "& .MuiInput-underline:hover:before": {
                                            borderBottomColor: "rgba(245, 245, 250, 0.4)", // Underline color on hover
                                        },
                                        "& .MuiInput-underline:after": {
                                            borderBottomColor: "rgba(245, 245, 250, 0.4)", // Underline color when focused
                                        },
                                        "& .MuiInputBase-input::placeholder": {
                                            fontFamily: "Lato"
                                        },
                                    }}
                                    id="input-with-icon-textfield"
                                    name="social"
                                    onChange={handleChange}
                                    value={values.choose}
                                    placeholder="Choose ..."
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <KeyboardArrowDownIcon
                                                    sx={{
                                                        height: "18px",
                                                        width: "18px",
                                                        color: PRIMARY.dark,
                                                        cursor: "pointer"
                                                    }}
                                                />
                                            </InputAdornment>
                                        ),
                                        sx: {
                                            "& .MuiInputBase-input::placeholder": {
                                                fontSize: "16px",
                                                lineHeight: "19.2px",
                                                fontFamily: "Lato",
                                            },
                                        },
                                    }}
                                    variant="standard"
                                    error={Boolean(touched.choose && errors.choose)}
                                    helperText={touched.choose && errors.choose}
                                />
                            </Grid>




                            <Grid item xs={12} md={6}>
                                <InputLabel
                                    htmlFor="input-with-icon-textfield"
                                    sx={{
                                        color: PRIMARY.dark,
                                        fontWeight: 400,
                                        fontSize: "14px",
                                        lineHeight: "21px",
                                        fontFamily: "Lato"
                                    }}
                                >
                                    Billing address
                                </InputLabel>

                                <CustomTextField
                                    sx={{
                                        width: {
                                            xs: '100%',      // 100% width on extra-small screens (mobile)
                                            sm: '100%',      // 100% width on small screens (larger mobile devices)
                                            md: '100%',      // 100% width on medium screens (tablets)
                                            lg: '440px',     // 440px width on large screens (desktops)
                                            xl: '440px',     // Keep the width at 440px for extra-large screens (large desktops)
                                        },
                                        '@media (min-width: 1200px) and (max-width: 1530px)': {
                                            width: '100%',  // Full width between 1200px and 1530px screen sizes
                                        },
                                        "& .MuiInput-underline:before": {
                                            borderBottomColor: "rgba(245, 245, 250, 0.3)", // Default underline color
                                        },
                                        "& .MuiInput-underline:hover:before": {
                                            borderBottomColor: "rgba(245, 245, 250, 0.4)", // Underline color on hover
                                        },
                                        "& .MuiInput-underline:after": {
                                            borderBottomColor: "rgba(245, 245, 250, 0.4)", // Underline color when focused
                                        },
                                        "& .MuiInputBase-input::placeholder": {
                                            fontFamily: "Lato"
                                        },
                                    }}
                                    id="input-with-icon-textfield"
                                    name="social"
                                    onChange={handleChange}
                                    value={values.biling}
                                    placeholder="Start typing..."
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <LocationOnIcon
                                                    sx={{
                                                        height: "18px",
                                                        width: "18px",
                                                        color: PRIMARY.dark,
                                                        cursor: "pointer"
                                                    }}
                                                />
                                            </InputAdornment>
                                        ),
                                        sx: {
                                            "& .MuiInputBase-input::placeholder": {
                                                fontSize: "16px",
                                                lineHeight: "19.2px",
                                                fontFamily: "Lato",
                                            },
                                        },
                                    }}
                                    variant="standard"
                                    error={Boolean(touched.biling && errors.biling)}
                                    helperText={touched.biling && errors.biling}
                                />
                            </Grid>



                            <Grid item xs={12} md={6} >
                                <InputLabel
                                    htmlFor="input-with-icon-textfield"
                                    sx={{
                                        color: PRIMARY.dark,
                                        fontWeight: 400,
                                        fontSize: "14px",
                                        lineHeight: "16px",
                                        fontFamily: "Lato"
                                    }}
                                >
                                    Zip code
                                </InputLabel>


                                <CustomTextField
                                    sx={{
                                        width: {
                                            xs: '100%',      // 100% width on extra-small screens (mobile)
                                            sm: '100%',      // 100% width on small screens (larger mobile devices)
                                            md: '100%',      // 100% width on medium screens (tablets)
                                            lg: '440px',     // 440px width on large screens (desktops)
                                            xl: '440px',     // Keep the width at 440px for extra-large screens (large desktops)
                                        },
                                        '@media (min-width: 1200px) and (max-width: 1530px)': {
                                            width: '100%',  // Full width between 1200px and 1530px screen sizes
                                        },
                                        "& .MuiInput-underline:before": {
                                            borderBottomColor: "rgba(245, 245, 250, 0.3)", // Default underline color
                                        },
                                        "& .MuiInput-underline:hover:before": {
                                            borderBottomColor: "rgba(245, 245, 250, 0.4)", // Underline color on hover
                                        },
                                        "& .MuiInput-underline:after": {
                                            borderBottomColor: "rgba(245, 245, 250, 0.4)", // Underline color when focused
                                        },
                                        "& .MuiInputBase-input::placeholder": {
                                            fontFamily: "Lato"
                                        },
                                    }}
                                    id="input-with-icon-textfield"
                                    name="social"
                                    onChange={handleChange}
                                    value={values.zip}
                                    placeholder="Start typing ..."
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <KeyboardArrowDownIcon
                                                    sx={{
                                                        height: "18px",
                                                        width: "18px",
                                                        color: PRIMARY.dark,
                                                        cursor: "pointer"
                                                    }}
                                                />
                                            </InputAdornment>
                                        ),
                                        sx: {
                                            "& .MuiInputBase-input::placeholder": {
                                                fontSize: "16px",
                                                lineHeight: "19.2px",
                                                fontFamily: "Lato",
                                            },
                                        },
                                    }}
                                    variant="standard"
                                    error={Boolean(touched.zip && errors.zip)}
                                    helperText={touched.zip && errors.zip}
                                />


                            </Grid>

                            <Grid item xs={12} md={6}>
                                <InputLabel
                                    htmlFor="input-with-icon-textfield"
                                    sx={{
                                        color: PRIMARY.dark,
                                        fontWeight: 400,
                                        fontSize: "14px",
                                        lineHeight: "21px",
                                        fontFamily: "Lato"
                                    }}
                                >
                                    Phone
                                </InputLabel>
                                <CustomTextField
                                    sx={{
                                        width: {
                                            xs: '100%',      // 100% width on extra-small screens (mobile)
                                            sm: '100%',      // 100% width on small screens (larger mobile devices)
                                            md: '100%',      // 100% width on medium screens (tablets)
                                            lg: '440px',     // 440px width on large screens (desktops)
                                            xl: '440px',     // Keep the width at 440px for extra-large screens (large desktops)
                                        },
                                        '@media (min-width: 1200px) and (max-width: 1530px)': {
                                            width: '100%',  // Full width between 1200px and 1530px screen sizes
                                        },
                                        "& .MuiInput-underline:before": {
                                            borderBottomColor: "rgba(245, 245, 250, 0.3)", // Default underline color
                                        },
                                        "& .MuiInput-underline:hover:before": {
                                            borderBottomColor: "rgba(245, 245, 250, 0.4)", // Underline color on hover
                                        },
                                        "& .MuiInput-underline:after": {
                                            borderBottomColor: "rgba(245, 245, 250, 0.4)", // Underline color when focused
                                        },
                                        "& .MuiInputBase-input::placeholder": {
                                            fontFamily: "Lato"
                                        },
                                    }}
                                    id="input-with-icon-textfield"
                                    name="phone"
                                    onChange={handleChange}
                                    value={values.phone}
                                    placeholder="Start typing..."
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <CallIcon
                                                    sx={{
                                                        height: "18px",
                                                        width: "18px",
                                                        color: PRIMARY.dark,
                                                        cursor: "pointer"
                                                    }}
                                                />
                                            </InputAdornment>
                                        ),
                                        sx: {
                                            "& .MuiInputBase-input::placeholder": {
                                                fontSize: "16px",
                                                lineHeight: "19.2px",
                                                fontFamily: "Lato",
                                            },
                                        },
                                    }}
                                    inputProps={{
                                        inputMode: 'numeric',  // Mobile keyboard shows numbers
                                        pattern: '[0-9]*',     // Only allows numeric input
                                        onKeyDown: (e) => {
                                            if (!/[0-9]/.test(e.key) && e.key !== "Backspace" && e.key !== "Delete") {
                                                e.preventDefault();  // Block non-numeric characters
                                            }
                                        },
                                    }}
                                    variant="standard"
                                    error={Boolean(touched.phone && errors.phone)}
                                    helperText={touched.phone && errors.phone}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <InputLabel
                                    htmlFor="input-with-icon-textfield"
                                    sx={{
                                        color: PRIMARY.dark,
                                        fontWeight: 400,
                                        fontSize: "14px",
                                        lineHeight: "21px",
                                        cursor: "pointer"
                                    }}
                                >
                                    Language
                                </InputLabel>
                                <CustomTextField
                                    sx={{
                                        width: {
                                            xs: '100%',      // 100% width on extra-small screens (mobile)
                                            sm: '100%',      // 100% width on small screens (larger mobile devices)
                                            md: '100%',      // 100% width on medium screens (tablets)
                                            lg: '440px',     // 440px width on large screens (desktops)
                                            xl: '440px',     // Keep the width at 440px for extra-large screens (large desktops)
                                        },
                                        '@media (min-width: 1200px) and (max-width: 1530px)': {
                                            width: '100%',  // Full width between 1200px and 1530px screen sizes
                                        },
                                        "& .MuiInput-underline:before": {
                                            borderBottomColor: "rgba(245, 245, 250, 0.3)", // Default underline color
                                        },
                                        "& .MuiInput-underline:hover:before": {
                                            borderBottomColor: "rgba(245, 245, 250, 0.4)", // Underline color on hover
                                        },
                                        "& .MuiInput-underline:after": {
                                            borderBottomColor: "rgba(245, 245, 250, 0.4)", // Underline color when focused
                                        },
                                        "& .MuiInputBase-input::placeholder": {
                                            fontFamily: "Lato"
                                        },
                                    }}
                                    id="input-with-icon-textfield"
                                    name="social"
                                    onChange={handleChange}
                                    value={values.language}
                                    placeholder="Select language"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <KeyboardArrowDownIcon
                                                    sx={{
                                                        height: "18px",
                                                        width: "18px",
                                                        color: PRIMARY.dark,
                                                        cursor: "pointer"
                                                    }}
                                                />
                                            </InputAdornment>
                                        ),
                                        sx: {
                                            "& .MuiInputBase-input::placeholder": {
                                                fontSize: "16px",
                                                lineHeight: "19.2px",
                                                fontFamily: "Lato",
                                            },
                                        },
                                    }}
                                    variant="standard"
                                    error={Boolean(touched.language && errors.language)}
                                    helperText={touched.language && errors.language}
                                />
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: { xs: "column", md: "row" },
                                        justifyContent: "flex-end",
                                        gap: 1,
                                        mt: { xs: 4, md: 20 },
                                    }}
                                >
                                    <Button
                                        variant="contained"
                                        sx={{
                                            height: "50px",
                                            width: { xs: "100%", md: "auto", lg: "180px" },
                                            justifyContent: "center",
                                            borderRadius: "8px",
                                            textTransform: "none",
                                            color: theme.palette.primary.white,
                                            background: theme.palette.primary.info,
                                            fontSize: "16px",
                                            lineHeight: "21px",
                                            "&:hover": {

                                                backgroundColor: "#007CD3",
                                            },
                                        }}
                                        type="submit"
                                        onClick={() => setOpenModal(true)}
                                    >
                                        <Box sx={{ textAlign: "center" }}>
                                            Update Setting {" "}
                                        </Box>

                                    </Button>

                                    <OutlinedButton
                                        variant="contained"
                                        sx={{
                                            height: "50px",
                                            width: { xs: "100%", md: "auto" },
                                            borderRadius: "10px",
                                            textTransform: "none",
                                            paddingInline: 5,
                                            background: "none",
                                            fontSize: "16px",
                                            lineHeight: "21px",
                                            "&:hover": {
                                                borderColor: WHITE[50],
                                                backgroundColor: "rgba(255, 255, 255, 0.1)",

                                            },
                                        }}
                                        type="reset"

                                        onClick={handleOpenConfirmModal}
                                    >
                                        Cancel
                                    </OutlinedButton>
                                </Box>
                            </Grid>
                        </Grid>
                    </Form>
                </Box>
                <Dialog open={openConfirmModal} onClose={handleCloseConfirmModal} maxWidth="sm" fullWidth>
                    <Box p={2} bgcolor={"#222526"}>
                        <DialogTitle>
                            <Box sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                marginBottom: "20px",
                                alignItems: "center",
                            }}>
                                <CustomTypography4
                                    sx={{
                                        fontWeight: 700,
                                        fontSize: "16px",
                                        lineHeight: "24px",
                                        color: "#FFFFFF",
                                    }}
                                ></CustomTypography4>

                                <CloseButton
                                    onClick={handleCloseConfirmModal}
                                    sx={{
                                        width: "36px",
                                        height: "36px",
                                        border: "1px solid rgba(255, 255, 255, 0.5)", // Add border to close button
                                    }}
                                >
                                    <CloseIcon
                                        sx={{
                                            color: "rgba(255, 255, 255, 0.5)",
                                            width: "18px",
                                            height: "18px",
                                        }}
                                    />
                                </CloseButton>
                            </Box>
                        </DialogTitle>

                        <DialogContent sx={{ textAlign: "center" }}>
                            <Typography
                                sx={{
                                    color: "white",
                                    fontSize: "26px",
                                    lineHeight: "27px",
                                    fontWeight: 700,
                                    fontFamily: "Lato",
                                    paddingBottom: "20px",
                                }}
                            >
                                Are you sure
                            </Typography>

                            <InputLabel
                                htmlFor="input-with-icon-textfield"
                                sx={{
                                    color: "rgba(128, 131, 163, 1)",
                                    fontSize: "14px",
                                    lineHeight: "21px",
                                    fontWeight: 400,
                                    fontFamily: "Lato",
                                    paddingBottom: "20px",
                                }}
                            >
                                Make sure your action is correct.
                            </InputLabel>
                        </DialogContent>
                        <DialogActions>
                            <Box
                                mt={4}
                                sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    width: "100%",
                                }}
                            >
                                <Button
                                    onClick={handleCloseConfirmModal}
                                    sx={{
                                        width: "213px",
                                        height: "52px",
                                        backgroundColor: "rgba(161, 161, 161, 0.1)",
                                        borderRadius: "10px",
                                        color: "rgba(255, 255, 255, 1)",
                                        fontWeight: 700,
                                        fontSize: '16px',
                                        textTransform: 'none',
                                        fontFamily: 'Lato'
                                    }}
                                >
                                    No
                                </Button>

                                <Button
                                    variant="contained"
                                    sx={{
                                        textTransform: 'none',
                                        width: "213px",
                                        height: "52px",
                                        fontFamily: "Lato",
                                        fontWeight: 700,
                                        fontSize: "16px",
                                        borderRadius: "10px",
                                        background: "#FF808B",
                                        marginLeft: "20px",
                                        color: "rgba(255, 255, 255, 1)",
                                        "&:hover": {
                                            backgroundColor: "#e76b77",
                                        },
                                    }}
                                    onClick={handleCloseConfirmModal}
                                >
                                    Yes, Close
                                </Button>
                            </Box>
                        </DialogActions>
                    </Box>
                </Dialog>
            </FormikProvider>



        </>
    );
}

BusinessAdminEditprofile.propTypes = {
    userDetails: PropTypes.object,
    uploadedImage: PropTypes.object,
    onProfileUpdate: PropTypes.func.isRequired,
};
