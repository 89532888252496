import React from "react";
import { useRoutes, Navigate } from "react-router-dom";
import AuthGuard from "../pages/auth/AuthGuard";
import DashboardLayout from "../layout/DashboardLayout";
import AdminDashboardLayout from "../layout/AdminDashboardLayout";
import SimpleLayout from "../layout/SimpleLayout";
import BusinessDasboardlayout from "../pages/businessUser/BussinesLayout/BusinessDasboardLayout"
import {
  SignUpPage,
  LoginPage,
  ResetPasswordPage,
  DashBoardPage,
  MessagePage,
  ForgetPasswordPage,
  OtpVerificationPage,
  PromoCodePage,
  EmailStatusPage,
  ForgetPasswordResetSuccess,
  ForgetPasswordEmailPage,
  SettingsPage,
  VideoCallPage,
  SchedulePage,
  MeetingJoinPage,
  ParticipantsPage,
  SearchPage,
  FaqPage,
  DocumentsPage,
  ContactPage,
  NotificationPage,
  GuestLoginPage,
  MeetingNotStartPage,
  GuestVideoCallApp,
  CreateMeetingPage,
  CallHistoryPage,
  SocialMediaSignUpSuccessPage,
  ManageSubscriptionPage,
  ChangePasswordPage,
  EditProfilePage, OrderHistorySection, NotificationSection, SubscriptionCard,
  BusinessDashBoardPage,
  BusinessNotification,
  BusinessCalender,
  BusinessDocumentPage,
  BussinessMessenger,
  BusinessContacts,
  BusinessSettings,
  BusinessEditprofile,
  ManagePlanIndex,
  BusinessChangePasswordForm,
  BusinessNotificationSection,
  // BusinessFaq,
  BusinesssFAQ,
  BusinessIndex,
  BusinessMeetingjoiningPge,
  BusinessLoginPage,
  BusinessSignUpPage,
  AdminNotificationPage,
  AdminDashBoardPage,
  AdminCustomersPage,
  AdminPlansPage,
  AdminReportsPage,
  AdminUsersPage,
  BusinessOtpVerificationPage,
  AdminBillingPage,
  AdminSupportPage,
  AdminPromotionPage,
  AdminSettingsPage,
  AdminLoginPage,
  BusinessAdminDashBoardPage,
  BusinessAdminIndex,
  BusinessAdminNotification,
  BussinessAdminMessenger,
  BusinessAdminCalender,
  BusinessAdminContacts,
  BusinessAdminDocumentPage,
  BusinessAdminSettingsPage,
  // BusinessAdminChangePassword,
  //BusinessAdminChangePasswordindex,
  BusinessAdminChangePassword,
  BusinessAdminNotificationSection,
  BusinessAdminOrderHistorySection,
  BusinessAdminMeetingjoiningPge,
  BusinessAdminSubscriptionCard,
  BusinessAdminpanellpage,
  BusinessAdminDashboard,
  BusinessAdminActivities,
  BusinessAdminUserManagment,
  BusinessAdminRole,
  BusinessAdminSearchPage,
  AdminEditProfilePage,
  AdminPasswordPage,
  AdminCountryPage,
  AdminLanguagePage,
  BusinessSearchPage,
  BusinessForgetPasswordPage,
  BusinessEmailStatusPage,
  BusinessResetPasswordPage,
  BusinessForgetPasswordResetSuccess,
  BusinessSubscriptionCard,
 
  
  BusinessAdminLoginPage,
  BusinessAdminSignUpPage,
  BusinessAdminForgetPasswordPage,
  BusinessAdminEmailStatusPage,
  BusinessAdminResetPasswordPage,
  BusinessAdminForgetPasswordResetSuccess,
  BusinessAdminOtpVerificationPage,
  BusinesssAdminFAQ,
  BusinessGuestLoginPage,
  BusinessGuestVideoCallApp,

} from "./elements";
import { PATH_AFTER_LOGIN } from "../utils/Config-globals";

import BusinessVideoCallApp from "../pages/businessUser/Calls/BusinessVideoCallScreen";
import BusinessAdminDashboardLayout from "../pages/BusinessAdmin/Layout/BusinessAdminDashboardLayout";
import BusinessAdminManagePlan from "../pages/BusinessAdmin/Settings/ManagePlan/BusinessAdminManagePlan";
import BusinessAdminEditprofile from "../pages/BusinessAdmin/Settings/Profile/BusinessAdminEditProfile";
import BusinessAdminVideoCallApp from "../pages/BusinessAdmin/Call/BusinessAdminVideoCallScreen";


export default function Router() {
  return useRoutes([
    { path: "/", element: <Navigate to="/auth/login" /> },
    {
      path: "auth",
      children: [
        { path: "login", element: <LoginPage /> },
        { path: "sign-up", element: <SignUpPage /> },
        { path: "reset-password", element: <ResetPasswordPage /> },
        { path: "otp-verification", element: <OtpVerificationPage /> },
        { path: "forgot-password", element: <ForgetPasswordPage /> },
        { path: "promocode", element: <PromoCodePage /> },
        { path: "email-sucess", element: <EmailStatusPage /> },
        { path: "password-sucess", element: <ForgetPasswordEmailPage /> },
        { path: "password-reset-success", element: <ForgetPasswordResetSuccess /> },
        { path: "guest-login", element: <GuestLoginPage /> }, // Existing route for guest login
        {
          path: "guest-login/join",
          element: <GuestLoginPage />  // <-- New route for guest login with join
        },
        { path: "create-meeting/:id", element: <CreateMeetingPage /> },
        { path: "social-media-signup", element: <SocialMediaSignUpSuccessPage /> },
      ],
    },
    {
      path: "dashboard",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: "Overview", element: <DashBoardPage /> },
        { path: "messages", element: <MessagePage /> },
        { path: "settings", element: <SettingsPage /> },
        { path: "schedule", element: <SchedulePage /> },
        { path: "subscription", element: <SubscriptionCard /> },
        { path: "contacts", element: <ContactPage /> },
        { path: "notifications", element: <NotificationPage /> },
        { path: "meeting-join", element: <MeetingJoinPage /> },
        { path: "participants", element: <ParticipantsPage /> },
        { path: "support", element: <FaqPage /> },
        { path: "documents", element: <DocumentsPage /> },
        { path: "Search", element: <SearchPage /> },
        {
          path: "settings",
          element: <SettingsPage />,
          children: [
            { path: "manage-subscription", element: <ManageSubscriptionPage /> },  // Add child routes for sub-pages
            { path: "edit-profile", element: <EditProfilePage /> },
            { path: "change-password", element: <ChangePasswordPage /> },
            { path: "order-history", element: <OrderHistorySection /> },
            { path: "notification", element: <NotificationSection /> },
          ],
        },
        {
          path: "call",
          children: [
            { element: <CallHistoryPage />, index: true },
            { path: "meet", element: <VideoCallPage /> },
            {
              path: "join/:meetingId",
              element: <MeetingJoinPage />,
            },
            {
              path: "join/",
              element: <MeetingJoinPage />, // Render the MeetingJoinPage directly
            },
          ],
        },
      ],
    },
    {
      path: "guest",
      element: <SimpleLayout />, // Layout for guest pages
      children: [
        { path: "join/:quickCallId", element: <MeetingJoinPage /> },
        { path: "meet", element: <GuestVideoCallApp /> },
        { path: "not-start", element: <MeetingNotStartPage /> },
      ],
    },


    //FOR BUSINESS USER
    { path: "/", element: <Navigate to="businessauth/login" /> },
    {
      path: "businessauth",
      children: [
        { path: "login", element: <BusinessLoginPage /> },
        { path: "signup", element: <BusinessSignUpPage /> },
        { path: "email-sucess", element: <BusinessEmailStatusPage /> },
        { path: "otp-verification", element: <BusinessOtpVerificationPage /> },
        { path: "newpassword-reset-form", element: <BusinessResetPasswordPage /> },
        { path: "forgot-password", element: <BusinessForgetPasswordPage /> },
        { path: "promocode", element: <PromoCodePage /> },
        { path: "password-sucess", element: <ForgetPasswordEmailPage /> },
        { path: "password-reset-success", element: <BusinessForgetPasswordResetSuccess /> },
        { path: "guest-login", element: <BusinessGuestLoginPage /> }, // Existing route for guest login
        {
          path: "guest-login/join",
          element: <BusinessGuestLoginPage />  // <-- New route for guest login with join
        },
        { path: "create-meeting/:id", element: <CreateMeetingPage /> },
        { path: "social-media-signup", element: <SocialMediaSignUpSuccessPage /> },
      ],
    },


    {
      path: "business",

      element: (<>
        <BusinessDasboardlayout />,
      </>),

      children: [
        { path: "overview", element: <BusinessDashBoardPage /> },
        { path: "meet", element: <GuestVideoCallApp /> },
        { path: "not-start", element: <MeetingNotStartPage /> },
        { path: "notifications", element: <BusinessNotification /> },
        { path: "schedule", element: <BusinessCalender /> },
        { path: "documents", element: <BusinessDocumentPage /> },
        { path: "messages", element: <BussinessMessenger /> },
        { path: "contacts", element: <BusinessContacts /> },
        { path: "settings", element: <BusinessSettings /> },
        { path: "support", element: <BusinesssFAQ /> },
        { path: "Search", element: <BusinessSearchPage /> },
        { path: "Subscription", element: <BusinessSubscriptionCard /> },

        {
          path: "settings",
          element: <BusinessSettings />,
          children: [
            { path: "edit-profile", element: <BusinessEditprofile /> },
            { path: "manage-plan", element: <ManagePlanIndex /> },

            { path: "change-password-business", element: <BusinessChangePasswordForm /> },
            { path: "notification-business", element: <BusinessNotificationSection /> },
          ],
        }
        ,
        {
          path: "call",
          children: [
            { element: <BusinessIndex />, index: true },
            { path: "meet", element: <BusinessVideoCallApp /> },
            {
              path: "join/:meetingId",
              element: <BusinessMeetingjoiningPge />,
            },
            {
              path: "join/",
              element: <BusinessMeetingjoiningPge />, // Render the MeetingJoinPage directly
            },
          ],
        },
      ]
    },
    {
      path: "businessguest",
      element: <SimpleLayout />, // Layout for guest pages
      children: [
        { path: "join", element: <BusinessMeetingjoiningPge /> },
        { path: "meet", element: <BusinessGuestVideoCallApp /> },
        { path: "not-start", element: <MeetingNotStartPage /> },
      ],
    },

    //FOR BUSINESS ADMIN

    { path: "/", element: <Navigate to="businessadminauth/login" /> },
    {
      path: "businessadminauth",
      children: [
        { path: "login", element: <BusinessAdminLoginPage /> },
        { path: "signup", element: <BusinessAdminSignUpPage /> },
        { path: "forgot-password", element: <BusinessAdminForgetPasswordPage /> },
        { path: "email-sucess", element: <BusinessAdminEmailStatusPage /> },
        { path: "newpassword-reset-form", element: <BusinessAdminResetPasswordPage /> },
        { path: "password-reset-success", element: <BusinessAdminForgetPasswordResetSuccess /> },
         { path: "otp-verification", element: <BusinessAdminOtpVerificationPage /> },
        // { path: "promocode", element: <PromoCodePage /> },
        // { path: "password-sucess", element: <ForgetPasswordEmailPage /> },
        // { path: "guest-login", element: <GuestLoginPage /> }, // Existing route for guest login
        // {
        //   path: "guest-login/join",
        //   element: <GuestLoginPage />  // <-- New route for guest login with join
        // },
        // { path: "create-meeting/:id", element: <CreateMeetingPage /> },
        // { path: "social-media-signup", element: <SocialMediaSignUpSuccessPage /> },
      ],
    },

    {
      path: "businessadmin",

      element: 
        <BusinessAdminDashboardLayout />,
    

      children: [
        { path: "overview", element: <BusinessAdminDashBoardPage /> },
        //   { path: "meet", element: <GuestVideoCallApp /> },
        //   { path: "not-start", element: <MeetingNotStartPage /> }, 
        { path: "notifications", element: <BusinessAdminNotification /> },
        { path: "messages", element: <BussinessAdminMessenger /> },
        { path: "schedule", element: <BusinessAdminCalender /> },
        { path: "documents", element: <BusinessAdminDocumentPage /> },
        { path: "subscription", element: <BusinessAdminSubscriptionCard /> },
        { path: "contacts", element: <BusinessAdminContacts /> },
        { path: "settings", element: <BusinessAdminSettingsPage /> },
          { path: "support", element: <BusinesssAdminFAQ /> },
        { path: "Search", element: < BusinessAdminSearchPage /> },
        {
          path: "settings",
          element: <BusinessAdminSettingsPage />,
          children: [
            { path: "edit-profile", element: <BusinessAdminEditprofile /> },
            { path: "manage-plan", element: <BusinessAdminManagePlan /> },
            { path: "change-password-businessadmin", element: <BusinessAdminChangePassword /> },
            { path: "notification-businessadmin", element: <BusinessAdminNotificationSection /> },
            { path: "order-history-businessadmin", element: < BusinessAdminOrderHistorySection /> },
          ],
        },
        {
          path: "Businessadminpanell",
          element: <BusinessAdminpanellpage />,
          children: [
            { path: "businessadmin-dashboard", element: <BusinessAdminDashboard /> },
            { path: "businessadmin-activity", element: <BusinessAdminActivities /> },
            { path: "businessadmin-usermanagamnet", element: <BusinessAdminUserManagment /> },
            { path: "businessadmin-roles", element: <BusinessAdminRole /> },
            { path: "order-history-businessadmin", element: < BusinessAdminOrderHistorySection /> },
          ],
        }
        ,
        {
          path: "call",
          children: [
            { element: <BusinessAdminIndex />, index: true },
            { path: "meet", element: <BusinessAdminVideoCallApp /> },
            {
              path: "join/:meetingId",
              element: <BusinessAdminMeetingjoiningPge />,
            },
            // {
            //   path: "join/",
            //   element: <MeetingJoinPage />, // Render the MeetingJoinPage directly
            // },
          ],
        },
      ]
    },


    //FOR ADMIN
    { path: "/", element: <Navigate to="/auth/admin-login" /> },
    {
      path: "auth",

      children: [
        { path: "admin-login", element: <AdminLoginPage /> },
      ],
    },
    {
      path: "admin",
      element: (
        // <AuthGuard>
        <AdminDashboardLayout />
        // </AuthGuard>
      ),
      children: [
        // { element: <Navigate replace />, index: true },
        { path: "overview", element: <AdminDashBoardPage /> },
        { path: "notifications", element: <AdminNotificationPage /> },
        { path: "customers", element: <AdminCustomersPage /> },
        { path: "plan", element: <AdminPlansPage /> },
        { path: "reports", element: <AdminReportsPage /> },
        { path: "user", element: <AdminUsersPage /> },
        { path: "billing", element: <AdminBillingPage /> },
        { path: "support", element: <AdminSupportPage /> },
        { path: "promotion", element: <AdminPromotionPage /> },
        {
          path: "settings", element: <AdminSettingsPage />,
          children: [
            { path: "people", element: <AdminEditProfilePage /> },
            { path: "security", element: <AdminPasswordPage /> },
            { path: "language", element: <AdminLanguagePage /> },
            { path: "country", element: <AdminCountryPage /> },
          ],
        },
      ],
    },



  ]);
}
