import PropTypes from "prop-types";
import { Box, Button, useTheme } from "@mui/material";
import React, { useState } from "react";
import {
  CustomSubtitle,
  CustomTypography1,
} from "../../components/Typography/Typography";
import MeetingAttendeeDetails from "../../pages/businessUser/Calls/BusinessMettingAttendesDetials";
//import TranslationSetupModal from "../../sections/Schedules/TranslationSetupform";

import {
  CloseButton,
  OutlinedButton,
} from "../../components/Butons/CustomButton";
import CloseIcon from "@mui/icons-material/Close";
import { OutlinedPaper } from "../../components/Paper/Paper";
import PlanUpgradeImg from "../../assets/images/Meeting/planupgrade.svg";
import CustomizedSwitch from "../../components/Switch";
import SettingsIcon from "@mui/icons-material/Settings";
import { useNavigate } from "react-router-dom";
import { PATH_BUSINESS_DASHBOARD } from "../../routes/Path";
//import { QuickMeeting } from "../../redux/slices/MeetingSlice";
//import { useDispatch } from "react-redux";
import BusinessTranslationaSetupModal from "./BusinessTranslationaSetupModal";

function BusinessMeetingParticipants({ onBack, invitees, setOpenQuickCall }) {
  const [open, setOpen] = useState(false); // Modal state
  const theme = useTheme();
  //const dispatch = useDispatch();
  const navigate = useNavigate();
  const [switchStatus, setSwitchStatus] = useState(false);
 // const [yourLanguage, setYourLanguage] = useState("");
  const [translationLanguages, setTranslationLanguages] = useState([]);
  //const [translationType, setTranslationType] = useState("");
  //const [error, setError] = useState("");

  const isUpgrade = true;

  const handleSwitchChange = (checked) => {
    setSwitchStatus(checked);
    if (checked) {
      setOpen(true); // Open the modal when translation is enabled
    }
  };

  const handleScheduleMeet = () => {
    const mockMeetingId = "12345"; // Replace with a mock or predefined meeting ID
  
    // Directly navigate to the JoinMeeting page without making the API call
    navigate(PATH_BUSINESS_DASHBOARD.JoinMeeting(mockMeetingId));
    localStorage.setItem("quick_call_id", mockMeetingId);
  };
  

  return (
    <>
      <Box p={1}>
        <Box sx={{ position: "relative" }}>
          <CustomTypography1
            sx={{
              textAlign: "left",
              fontSize: "16px",
              fontWeight: 400,
              lineHeight: "24px",
              display: "flex",
              justifyContent: "space-between",
              "@media (max-width: 600px)": {
                fontSize: "14px",
              },
          
            }}
          >
            Call Settings
          </CustomTypography1>
          <CloseButton
            sx={{ position: "absolute", right: 2, top: 2, width: "36px", height: "36px" }}
            onClick={() => {
              setOpenQuickCall(false);
              onBack();
            }}
          >
            <CloseIcon
              sx={{ width: "18px", height: "18px", color: "rgba(255, 255, 255, 0.5)" }}
            />
          </CloseButton>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mt: 5,
          }}
        >
          <CustomSubtitle
            sx={{
              textAlign: "left",
              color: "rgba(255, 255, 255, 0.5)",
              fontWeight: 400,
              fontSize: "14px",
              lineHeight: "21px",
            }}
          >
            Members
          </CustomSubtitle>
          {isUpgrade ? (
            <Box sx={{ display: "flex", justifyContent: "center", gap: 2 }}>
              <OutlinedPaper
                sx={{
                  color: "white",
                  fontSize: "10px",
                  display: "flex",
                  justifyContent: "center",
                  fontFamily:"Lato",
                  alignItems: "center",
                  p: "5px",
                  borderRadius: "20px",
                  width: "122.3px",
                  height: "27px",
                  borderColor: "rgba(255, 255, 255, 1)",
                }}
              >
                <img
                  src={PlanUpgradeImg}
                  alt="plan"
                  height={"10.5px"}
                  width={"10.42px"}
                  style={{ marginRight: "8px" }}
                />
                <p style={{ margin: 0 }}>Enable Translation</p>
              </OutlinedPaper>

              <CustomizedSwitch
                checked={switchStatus}
                setChecked={handleSwitchChange} // Toggle switch and open modal
              />
            </Box>
          ) : (
            <OutlinedPaper
              sx={{
                color: "white",
                fontSize: "10px",
                display: "flex",
                justifyContent: "center",
                p: "5px",
                borderRadius: "20px",
              }}
            >
              <img src={PlanUpgradeImg} alt="plan" />
              <p style={{ margin: 0 }}>Upgrade to enable Translation</p>
            </OutlinedPaper>
          )}
        </Box>

        <Box sx={{ background: "#1A1C1D", mt: 3, maxHeight:"250px", overflow:"auto" }}>
          {/* Pass translationLanguages to the MeetingAttendeeDetails */}
          <MeetingAttendeeDetails
            isTranslation={switchStatus}
            invitees={invitees}
            translationLanguages={translationLanguages}
          />
        </Box>
        {/* {error && (
        <Grid item xs={12}>
          <Typography
            color="error"
            sx={{ fontSize: "14px", fontWeight: "bold" }}
          >
            {error}
          </Typography>
        </Grid>
      )} */}
        {/* Adjusting button alignment */}
        <Box
          sx={{
            display: "flex",
            justifyContent: switchStatus ? "space-between" : "flex-end", // Conditionally align
            alignItems: "center",
            paddingTop: 3,
            "@media (max-width: 600px)": {
              flexDirection: "column",
            },
          }}
        >
          {switchStatus && (
            <OutlinedButton
              variant="contained"
              startIcon={<SettingsIcon />}
              sx={{fontSize:"16px",
                height:"46px",
                 boxShadow:"none",
                   fontFamily:"Lato",
                borderRadius: "10px",
                textTransform: "none",
                "&:hover": {
                  backgroundColor: "inherit",
              
               
                },
              }}
              onClick={() => setOpen(true)}
            >
              Translation Settings
            </OutlinedButton>
          )}

          <Button
            variant="contained"
            sx={{
              
              fontSize:"16px",
              height:"46px",
                   fontFamily:"Lato",
              width: "164px",
              borderRadius: "10px",
              textTransform: "none",
              paddingInline: 5,
              background: theme.palette.primary.info,
              color: theme.palette.primary.white,
              "&:hover": {
                backgroundColor: "#007CD3",
              },
              "@media (max-width: 600px)": {
                  marginTop: "10px",
                },
            }}
            onClick={handleScheduleMeet}
          >
            Make call
          </Button>
        </Box>
        <Box
          sx={{
            position: "relative",
            padding: 3,
          }}
        ></Box>
      </Box>

      <BusinessTranslationaSetupModal
        open={open}
        onClose={() => setOpen(false)} // Close modal when finished
        //setYourLanguage={setYourLanguage}
        setTranslationLanguages={setTranslationLanguages}
       // setTranslationType={setTranslationType}
      />
    </>
  );
}

export default BusinessMeetingParticipants;

BusinessMeetingParticipants.propTypes = {
  onBack: PropTypes.func,
  invitees: PropTypes.array,
  setOpenQuickCall: PropTypes.func,
};
