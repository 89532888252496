import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Typography,
  Grid,
  TextField,
  IconButton,
  Radio,
  RadioGroup,
  InputLabel,
  Dialog,
  InputAdornment,
} from "@mui/material";
import { styled } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/material/styles";
import RedeemIcon from '@mui/icons-material/Redeem';
import EmailImg from "../../../assets/images/Email/EmailSendImg.svg";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import Group from "../../../assets/images/subscription/visa.png";
import masterCard from "../../../assets/images/subscription/masterCardd.png";
import { OutlinedButton } from "../../../components/Butons/CustomButton";
import { CustomTypography4 } from "../../../components/Typography/Typography";
import { useNavigate } from "react-router-dom";
import { WHITE } from "../../../theme/Palette";

const ModalContainer = styled(Box)(() => ({
  backgroundColor: "#222526",
  borderRadius: "12px",
  boxShadow: "0px 6px 16px rgba(153, 155, 168, 0.1)",
  color: "#fff",
  padding: "24px 32px",
}));

const ModalHeader = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  paddingBottom: "16px",
}));

const ModalContent = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "16px",
}));

const AddCardModal = ({ open, onClose ,onSuccess}) => {
  const theme = useTheme();
  const [addCardOpen, setAddCardOpen] = useState(false);

//  const handleAddCardOpen = ()=>{
//   setAddCardOpen(true)
//  }
  const handleAddCardClose = () => {
    setAddCardOpen(false);
  };
  return (
    <>
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      sx={{ backgroundImage: "none !important" }}
      PaperProps={{
        style: {
          backgroundImage: "none !important",
          background: "none",
          //boxShadow: "0px 6px 16px rgba(153, 155, 168, 0.1)",
        },
      }}
    >
      <ModalContainer>
        <ModalHeader>
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: "16px",
              fontFamily: "Lato",
              mt: 1,
            }}
          >
            Add a card
          </Typography>

          <IconButton
            onClick={onClose}
            sx={{
              color: "#FFFFFF80",
              border: "1px solid #444",
              borderRadius: "10px",
              width: "36px",
              height: "36px",
            }}
          >
            <CloseIcon sx={{ height: "18px", width: "18px" }} />
          </IconButton>
        </ModalHeader>

        <ModalContent>
          {/* Card Details Form */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "30px",
              mt: 5,
            }}
          >
            <Box sx={{ mb: 2 }}>
              <InputLabel
                htmlFor="full-name-input"
                sx={{
                  color: "rgba(255, 255, 255, 0.5)",
                  fontSize: "14px",
                  //lineHeight: "21px",

                  fontFamily: "Lato",
                }}
              >
                Name
              </InputLabel>
              <TextField
                fullWidth
                variant="standard"
                placeholder="Name on card"
                sx={{
                  "& .MuiInput-underline:before": {
                    borderBottom: `1px solid #FFFFFF80`, // Adjusted border thickness
                  },
                  "& .MuiInput-underline:after": {
                    borderBottom: `2px solid #FFFFFF80`, // Adjusted border color
                  },
                  "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                    borderBottom: `1px solid #FFFFFF80`,
                  },
                  "& .MuiInputBase-input": {
                    padding: "8px 0px", // Increased padding
                    color: "white",
                    fontSize: "16px", // Adjusted font size
                    fontFamily: "Lato",
                    fontWeight: 400,
                  },
                }}
              />
            </Box>

            <Box sx={{ mb: 2 }}>
              <InputLabel
                htmlFor="full-name-input"
                sx={{
                  color: "rgba(255, 255, 255, 0.5)",
                  fontSize: "14px",
                  //lineHeight: "21px",

                  fontFamily: "Lato",
                }}
              >
                Card number
              </InputLabel>
              <TextField
                fullWidth
                variant="standard"
                placeholder="000 000 0000 00"
                sx={{
                  "& .MuiInput-underline:before": {
                    borderBottom: `1px solid #FFFFFF80`, // Adjusted border thickness
                  },
                  "& .MuiInput-underline:after": {
                    borderBottom: `2px solid #FFFFFF80`, // Adjusted border color
                  },
                  "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                    borderBottom: `1px solid #FFFFFF80`,
                  },
                  "& .MuiInputBase-input": {
                    padding: "8px 0px", // Increased padding
                    fontSize: "16px", // Adjusted font size
                    fontFamily: "Lato",
                    fontWeight: 400,
                  },
                }}
              />
            </Box>

            <Box sx={{ display: "flex", gap: "16px" }}>
              <Box sx={{ mb: 2, width: "100%" }}>
                <InputLabel
                  htmlFor="full-name-input"
                  sx={{
                    color: "rgba(255, 255, 255, 0.5)",
                    fontSize: "14px",
                    //lineHeight: "21px",

                    fontFamily: "Lato",
                  }}
                >
                  Expiration
                </InputLabel>
                <TextField
                  fullWidth
                  variant="standard"
                  placeholder="MM /YY"
                  sx={{
                    "& .MuiInput-underline:before": {
                      borderBottom: `1px solid #FFFFFF80`, // Adjusted border thickness
                    },
                    "& .MuiInput-underline:after": {
                      borderBottom: `2px solid #FFFFFF80`, // Adjusted border color
                    },
                    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                      borderBottom: `1px solid #FFFFFF80`,
                    },
                    "& .MuiInputBase-input": {
                      padding: "8px 0px", // Increased padding
                      color: "white",
                      fontSize: "16px", // Adjusted font size
                    },
                  }}
                />
              </Box>
              <Box sx={{ mb: 2, width: "100%" }}>
                <InputLabel
                  htmlFor="full-name-input"
                  sx={{
                    color: "#FFFFFF80",
                    fontSize: "14px",
                    lineHeight: "21px",
                    fontWeight: 500,
                    fontFamily: "Lato",
                  }}
                >
                  CVV
                </InputLabel>
                <TextField
                  fullWidth
                  variant="standard"
                  placeholder="0000"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <CreditCardIcon
                          sx={{
                            color: "#FFFFFF80",
                            height: "18px",
                            width: "18px",
                          }}
                        />{" "}
                      </InputAdornment>
                    ),
                  }}
                  InputLabelProps={{
                    style: { color: "rgba(255, 255, 255, 0.5)" },
                  }}
                  sx={{
                    "& .MuiInput-underline:before": {
                      borderBottom: `1px solid #FFFFFF80`, // Adjusted border thickness
                    },
                    "& .MuiInput-underline:after": {
                      borderBottom: `2px solid #FFFFFF80`, // Adjusted border color
                    },
                    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                      borderBottom: `1px solid #FFFFFF80`,
                    },
                    "& .MuiInputBase-input": {
                      padding: "8px 0px", // Increased padding
                      color: "white",
                      fontSize: "16px", // Adjusted font size
                    },
                  }}
                />
              </Box>
            </Box>
          </Box>

          {/* Action Buttons */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center", // Ensures vertical alignment of items
              mt: 6, // Margin top for spacing from above elements
            }}
          >
            {/* Cancel Button */}
            <OutlinedButton
              variant="outlined"
              onClick={onClose}
              sx={{
                width: "124px",
                height: "46px", // Same height for alignment
                display: "flex",
                alignItems: "center", // Center content vertically
                justifyContent: "center", // Center content horizontally
                marginTop: "0px", // Remove margin top
                "&:hover": {
                  borderColor: WHITE[50],
                  backgroundColor: "rgba(255, 255, 255, 0.1)",
                },
              }}
            >
              <CustomTypography4 sx={{ fontSize: "16px", fontFamily: "Lato" }}>
                Cancel
              </CustomTypography4>
            </OutlinedButton>

            {/* Save Button */}
            <Button
              variant="contained"
              sx={{
                textTransform: "none",
                borderRadius: "8px",
                width: "164px",
                height: "46px",
                display: "flex",
                alignItems: "center", // Center content vertically
                justifyContent: "center", // Center content horizontally
                marginTop: "0px", // Remove margin top
                padding: "8px 40px",
                background: theme.palette.primary.info,
                color: theme.palette.primary.white,
                fontSize: "16px",
                fontFamily: "Lato",
                ":hover": {
            backgroundColor:'#007CD3',
                  color: theme.palette.primary.white,
                },
              }} onClick={onSuccess}
            >
              Save
            </Button>
          </Box>
        </ModalContent>
      </ModalContainer>
    </Dialog>
      <AddCardSuccessModal open={addCardOpen} onClose={handleAddCardClose} />  
    </>
  );
};

AddCardModal.propTypes = {
  open: PropTypes.bool.isRequired, // Validate open as a boolean prop
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.bool.isRequired,
};

const AddCardSuccessModal = ({ open, onClose }) => {
  const theme = useTheme();
  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth="sm"
        fullWidth
        sx={{ backgroundImage: "none !important" }}
        PaperProps={{
          style: {
            backgroundImage: "none !important",
            background: "none",
          },
        }}
      >
        <ModalContainer>
          <ModalHeader sx={{ display: "flex", justifyContent: "flex-end" }}>
            <IconButton
              onClick={onClose}
              sx={{
                color: "#FFFFFF80",
                border: "1px solid rgba(236, 238, 245, 1)",
                borderRadius: "10px",
                width: "36px",
                height: "36px",
              }}
            >
              <CloseIcon sx={{ height: "18px", width: "18px" }} />
            </IconButton>
          </ModalHeader>

          <ModalContent>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "16px",
                alignItems: "center",
              }}
            >
              <Box
                p={5}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img src={EmailImg} />
              </Box>
              <Typography
                variant="caption"
                sx={{ fontSize: "26px", fontFamily: "Lato", fontWeight: 600 }}
              >
                Card Successfully Added
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                mt: 10,
              }}
            >
              <Button
                variant="contained"
                sx={{
                  textTransform: "none",
                  borderRadius: "8px",
                  width: "498px",
                  height: "52px",
                  marginBottom: "30px",
                  padding: "5px 40px",
                  background: theme.palette.primary.info,
                  color: theme.palette.primary.white,
                  fontSize: "16px",
                  fontFamily: "Lato",
                  ":hover": {
                    background: theme.palette.primary.info,
                    color: theme.palette.primary.white,
                  },
                }}
                onClick={() => {
                  onClose(); // Trigger handler to close the AddCardSuccessModal and show CheckoutModal
                }}
              >
                Ok
              </Button>
            </Box>
          </ModalContent>
        </ModalContainer>
      </Dialog>
    </>
  );
};

AddCardSuccessModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

const ProceedModal = ({ open, onClose }) => {
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      sx={{ backgroundImage: "none !important" }}
      PaperProps={{
        style: {
          backgroundImage: "none !important",
          background: "none",
          //boxShadow: "0px 6px 16px rgba(153, 155, 168, 0.1)",
        },
      }}
    >
      <ModalContainer>
        <ModalHeader sx={{ display: "flex", justifyContent: "flex-end" }}>
          <IconButton
            onClick={onClose}
            sx={{
              color: "#FFFFFF80",
              border: "1px solid rgba(236, 238, 245, 1)",
              borderRadius: "10px",
              width: "36px",
              height: "36px",
            }}
          >
            <CloseIcon sx={{ height: "18px", width: "18px" }} />
          </IconButton>
        </ModalHeader>

        <ModalContent>
          {/* Card Details Form */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
              alignItems: "center",
            }}
          >
            <Box
              p={5}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img src={EmailImg} />
            </Box>
            <Typography
              variant="caption"
              sx={{ fontSize: "26px", fontFamily: "Lato", fontWeight: 600 }}
            >
              Payment Successful
            </Typography>
            <Typography
              variant="caption"
              sx={{
                fontSize: "14px",
                color: "#8083A3",
                fontWeight: 400,
                fontFamily: "Lato",
              }}
            >
              A copy of your receipt has been send to your email.
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mt: 10, // Margin top to create space between content above
            }}
          >
            <Button
              variant="contained"
              sx={{
                textTransform: "none",
                borderRadius: "8px",
                width: "498px", // Keep the width if necessary; adjust as needed
                height: "52px",
                marginBottom: "30px",
                padding: "5px 40px",
                background: theme.palette.primary.info,
                color: theme.palette.primary.white,
                fontSize: "16px",
                fontFamily: "Lato",

                ":hover": {
                  background: theme.palette.primary.info,
                  color: theme.palette.primary.white,
                },
              }}
              onClick={() => {
                onClose(); // Close the modal
                navigate("/businessadmin/settings/manage-plan"); // Navigate to manage subscription
              }}
            >
              Manage subscription
            </Button>
          </Box>
        </ModalContent>
      </ModalContainer>
    </Dialog>
  );
};

ProceedModal.propTypes = {
  open: PropTypes.bool.isRequired, // Validate open as a boolean prop
  onClose: PropTypes.func.isRequired, // Validate onClose as a function prop
};

export default function CheckoutModal({ open, onClose }) {
  const [selectedCard, setSelectedCard] = useState("visa");
  const [addCardOpen, setAddCardOpen] = useState(false);
  const [proceedOpen, setProceedOpen] = useState(false);
  const [addCardSuccessOpen, setAddCardSuccessOpen] = useState(false);
  const theme = useTheme();

  const handleAddCardOpen = () => {
    setAddCardOpen(true);
  };
  const handleProceedOpen = () => {
    setProceedOpen(true);
    onClose();
  };

  const handleAddCardClose = () => {
    setAddCardOpen(false);
  };
  const handleProceedClose = () => {
    setProceedOpen(false);
    openModal();
  };

  const handleAddCardSuccessClose = () => {
    setAddCardSuccessOpen(false);
  };

  const handleBackToCheckout = () => {
    setAddCardSuccessOpen(false); // Close success modal
    setAddCardOpen(false); // Close AddCard modal
    setTimeout(() => setAddCardOpen(true), 300); // Reopen the Checkout modal after closing the AddCard modal
  };

  const handleSuccessAfterAddCard = () => {
    setAddCardSuccessOpen(true); // Show success modal
    handleAddCardClose(); // Close AddCard modal
  };

  const openModal = () => {
    setTimeout(() => {
      onClose(false); // Close first
      setTimeout(() => {
        onClose(true); // Open again
      }, 100);
    }, 100);
  };

  return (
    <>
      <Dialog
        open={open && !addCardOpen && !proceedOpen}
        onClose={onClose}
        maxWidth="md"
        fullWidth
        sx={{ backgroundImage: "none !important" }}
        PaperProps={{
          style: {
            backgroundImage: "none !important",
            background: "none",
            boxShadow: "0px 6px 16px rgba(153, 155, 168, 0.1)",
          },
        }}
      >
        <ModalContainer>
          {/* Modal Header */}
          <ModalHeader>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: "31px",
                fontFamily: "Lato",
                lineHeight: "40px",
              }}
            >
              Checkout
            </Typography>

            <IconButton
              onClick={onClose}
              sx={{
                color: "#FFFFFF80",
                border: "1px solid #444",
                borderRadius: "10px",
                width: "36px",
                height: "36px",
              }}
            >
              <CloseIcon />
            </IconButton>
          </ModalHeader>

          {/* Modal Content */}
          <ModalContent>
            <Grid container spacing={4}>
              {/* Left Section - Billing and Payment Methods */}
              <Grid item xs={12} md={6}>
                <Box sx={{ mt: 5 }}>
                  <Typography
                    sx={{
                      mb: 6,
                      fontWeight: 500,
                      fontSize: "15px",
                      fontFamily: "Lato",
                      lineHeight: "24px",
                      wordSpacing: 3,
                    }}
                  >
                    Billing details (optional)
                  </Typography>
                  <Box sx={{ my: "10px" }}>
                    <Box sx={{ mb: 2 }}>
                      <InputLabel
                        htmlFor="full-name-input"
                        sx={{
                          color: "rgba(255, 255, 255, 0.5)",
                          fontSize: "14px",
                          lineHeight: "21px",
                          fontWeight: 400,
                          fontFamily: "Lato",
                        }}
                      >
                        Full name
                      </InputLabel>
                      <TextField
                        fullWidth
                        variant="standard"
                        placeholder="Type here"
                        sx={{
                          "& .MuiInput-underline:before": {
                            borderBottom: `1px solid #FFFFFF80`, // Adjusted border thickness
                          },
                          "& .MuiInput-underline:after": {
                            borderBottom: `2px solid #FFFFFF80`, // Adjusted border color
                          },
                          "& .MuiInput-underline:hover:not(.Mui-disabled):before":
                            {
                              borderBottom: `1px solid #FFFFFF80`,
                            },
                          "& .MuiInputBase-input": {
                            padding: "8px 0px", // Increased padding
                            color: "white",
                            fontSize: "14px", // Adjusted font size
                            fontFamily: "Lato",
                          },
                        }}
                      />
                    </Box>

                    <Box sx={{ marginTop: "20px" }}>
                      <InputLabel
                        htmlFor="email-address-input"
                        sx={{
                          color: "rgba(255, 255, 255, 0.5)",
                          fontSize: "14px",
                          lineHeight: "21px",
                          fontWeight: 400,
                          fontFamily: "Lato",
                        }}
                      >
                        Email address
                      </InputLabel>
                      <TextField
                        fullWidth
                        variant="standard"
                        placeholder="Your email address"
                        sx={{
                          "& .MuiInput-underline:before": {
                            borderBottom: `1px solid #FFFFFF80`, // Adjusted border thickness
                          },
                          "& .MuiInput-underline:after": {
                            borderBottom: `1px solid #FFFFFF80`, // Adjusted border color
                          },
                          "& .MuiInput-underline:hover:not(.Mui-disabled):before":
                            {
                              borderBottom: `1px solid #FFFFFF80`,
                            },
                          "& .MuiInputBase-input": {
                            padding: "8px 0px", // Increased padding
                            color: "white",
                            fontSize: "14px", // Adjusted font size
                            fontFamily: "Lato",
                          },
                        }}
                      />
                    </Box>
                  </Box>

                  <Typography
                    sx={{
                      my: 4,
                      fontWeight: 500,
                      mt: 8,
                      fontFamily: "Lato",
                      fontSize: "16px",
                      lineHeight: "24px",
                    }}
                  >
                    Payment Methods
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "20px",
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        color: "rgba(255, 255, 255, 0.6)",
                        fontWeight: 300,
                        fontFamily: "Lato",
                        fontSize: "14px",
                        lineHeight: "21px",
                      }}
                    >
                      Saved payment options
                    </Typography>
                    <Button
                      variant="contained"
                      sx={{
                        textTransform: "none",
                        borderRadius: "12px",
                        padding: "2px",
                        background: "#1A1C1D",
                        border: "1px solid",
                        color: "#FFFFFF",
                        fontSize: "9px",
                        fontFamily: "Lato",

                        ":hover": {
                          background: theme.palette.primary.dark,
                        },
                      }}
                      onClick={handleAddCardOpen}
                    >
                      Add New
                    </Button>
                  </Box>

                  {/* Payment Cards */}
                  <RadioGroup
                    value={selectedCard}
                    onChange={(e) => setSelectedCard(e.target.value)}
                  >
                    {/* Visa Card */}
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        p: 2,

                        backgroundColor: "#1A1C1D",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Box>
                          <img
                            src={Group}
                            style={{ height: "36px", width: "39px" }}
                            alt="Group"
                          />
                        </Box>
                        <Box sx={{ marginLeft: "10px" }}>
                          <Typography
                            sx={{
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "white",
                              fontFamily: "Lato",
                              mb: "-7px",
                            }}
                          >
                            ****4586
                          </Typography>
                          <Typography
                            variant="caption"
                            sx={{
                              color: "rgba(255, 255, 255, 0.5)",
                              fontSize: "14px",
                              fontFamily: "Lato",
                            }}
                          >
                            Expire 13/2026
                          </Typography>
                        </Box>
                      </Box>
                      <Radio
                        value="visa"
                        sx={{
                          transform: "scale(1.3)", // Initial transform scale
                          "& .MuiSvgIcon-root": {
                            height: "20px",
                            width: "20px",
                          }, // Default size of the radio button
                          "&:not(.Mui-checked) .MuiSvgIcon-root": {
                            // Border color for unchecked state
                            backgroundColor: "transparent", // Background color for unchecked state
                            color: "rgba(255, 255, 255, 0.2)",
                          },
                          "&.Mui-checked": {
                            transform: "scale(1.0)", // Transform scale for checked state
                            borderRadius: "50px", // Border radius when checked
                            "& .MuiSvgIcon-root": {
                              fontSize: "20px", // Font size for inner checked icon
                            },
                          },
                          "&.Mui-checked .MuiSvgIcon-root": {
                            border: "7px solid rgba(0, 146, 248, 1)", // Border for checked state
                            width: "20px", // Width of the checked radio button
                            height: "20px", // Height of the checked radio button
                            borderRadius: "50px", // Add border radius to create a circular shape
                            backgroundColor: "white", // Inner circle color
                            color: "white", // Inner icon color
                          },
                        }}
                      />
                    </Box>

                    {/* MasterCard */}
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        p: 2,

                        borderTop: "1px solid #FFFFFF80",
                        backgroundColor: "#1A1C1D",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Box>
                          <img
                            src={masterCard}
                            style={{ height: "20px", width: "30px" }}
                            alt="masterCard"
                          />
                        </Box>
                        <Box sx={{ marginLeft: "10px" }}>
                          <Typography
                            sx={{
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "white",
                              fontFamily: "Lato",
                              mb: "-7px",
                            }}
                          >
                            ****4586
                          </Typography>
                          <Typography
                            variant="caption"
                            sx={{
                              color: "rgba(255, 255, 255, 0.5)",
                              fontSize: "14px",
                              fontFamily: "Lato",
                            }}
                          >
                            Expire 23/2028
                          </Typography>
                        </Box>
                      </Box>

                      <Radio
                        value="mastercard"
                        sx={{
                          transform: "scale(1.3)", // Initial transform scale
                          "& .MuiSvgIcon-root": {
                            height: "20px",
                            width: "20px",
                          }, // Default size of the radio button
                          "&:not(.Mui-checked) .MuiSvgIcon-root": {
                            // Border color for unchecked state
                            backgroundColor: "transparent", // Background color for unchecked state
                            color: "rgba(255, 255, 255, 0.2)",
                          },
                          "&.Mui-checked": {
                            transform: "scale(1.0)", // Transform scale for checked state
                            borderRadius: "50px", // Border radius when checked
                            "& .MuiSvgIcon-root": {
                              fontSize: "20px", // Font size for inner checked icon
                            },
                          },
                          "&.Mui-checked .MuiSvgIcon-root": {
                            border: "7px solid rgba(0, 146, 248, 1)", // Border for checked state
                            width: "20px", // Width of the checked radio button
                            height: "20px", // Height of the checked radio button
                            borderRadius: "50px", // Add border radius to create a circular shape
                            backgroundColor: "white", // Inner circle color
                            color: "white", // Inner icon color
                          },
                        }}
                      />
                    </Box>
                  </RadioGroup>
                </Box>
              </Grid>

              {/* Right Section - Payment Summary */}
              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    backgroundColor: "#42424D",

                    padding: "20px",
                    marginBottom: "40px",
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: 500,
                      mb: 6,
                      fontSize: "16px",
                      fontFamily: "Lato",
                      lineHeight: "24px",
                    }}
                  >
                    Payment Summary
                  </Typography>

                  <Typography
                    sx={{
                      mb: 1,
                      fontSize: "14px",
                      color: "#FFFFFF80",
                      fontFamily: "Lato",
                      fontWeight: 400,
                      lineHeight: "21px",
                    }}
                  >
                    Selected subscriptions
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      mb: 1,
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: 500,
                        mb: 1,
                        fontFamily: "Lato",
                        fontSize: "16px",
                        lineHeight: "19.2px",
                      }}
                    >
                      Basic Plan
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: 500,
                        mb: 1,
                        fontFamily: "Lato",
                        fontSize: "16px",
                        lineHeight: "19.2px",
                      }}
                    >
                      $10.00
                    </Typography>
                  </Box>
                  <Box sx={{ mb: 2, mt: 6 }}>
                    <InputLabel
                      htmlFor="full-name-input"
                      sx={{
                        fontSize: "14px",
                        color: "#FFFFFF80",
                        fontFamily: "Lato",
                        fontWeight: 400,
                        lineHeight: "21px",
                        marginBottom: "20px",
                      }}
                    >
                      Do you have a promocode ?
                    </InputLabel>
                    <TextField
                      fullWidth
                      variant="standard"
                      placeholder="Promocode"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <RedeemIcon
                              sx={{ color: "#FFFFFF80", fontSize: "18px" }}
                            />{" "}
                          </InputAdornment>
                        ),
                      }}
                      InputLabelProps={{
                        style: { color: "rgba(255, 255, 255, 0.5)" },
                      }}
                      sx={{
                        "& .MuiInput-underline:before": {
                          borderBottom: `1px solid color:"#FFFFFF80"`,
                        },
                        "& .MuiInput-underline:after": {
                          borderBottom: `2px solid color:"#FFFFFF80"`,
                        },
                        "& .MuiInput-underline:hover:not(.Mui-disabled):before":
                          {
                            borderBottom: `1px solid color:"#FFFFFF80"`,
                          },
                        "& .MuiInputBase-input": {
                          padding: "8px 0px",
                          color: "white",
                          fontSize: "16px",
                          fontFamily: "Lato",
                          fontWeight: 500,
                        },
                      }}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      mt: 3,
                      mb: 1,
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Lato",
                        fontWeight: 500,
                        fontSize: "16px",
                      }}
                    >
                      Subtotal
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: "Lato",
                        fontWeight: 500,
                        fontSize: "18px",
                      }}
                    >
                      +$10.00
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      mb: 1,
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Lato",
                        fontWeight: 500,
                        fontSize: "16px",
                      }}
                    >
                      Tax
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: "Lato",
                        fontWeight: 500,
                        fontSize: "18px",
                      }}
                    >
                      +$2.00
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      color: "#28C345",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "16px",
                        color: "#28C345",
                        mt: 1,
                        fontWeight: 700,
                        fontFamily: "Lato",
                      }}
                    >
                      Promocode Applied
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: "Lato",
                        fontWeight: 500,
                        fontSize: "18px",
                      }}
                    >
                      - $5.00
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      mt: 3,
                      mb: 6,
                      fontWeight: 600,
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Lato",
                        fontWeight: 500,
                        fontSize: "16px",
                      }}
                    >
                      Total Amount
                    </Typography>
                    <Typography sx={{ fontSize: "26px", fontFamily: "Lato" }}>
                      $10.00
                    </Typography>
                  </Box>
                  <Button
                    variant="contained"
                    sx={{
                      textTransform: "none",
                      borderRadius: "8px",
                      fontFamily: "Lato",
                      width: "100%",
                      marginBottom: "30px",
                      padding: "5px 40px",
                      background: theme.palette.primary.info,
                      color: theme.palette.primary.white,
                      fontSize: "16px",
                      ":hover": {
                      backgroundColor:"#007CD3",
                        color: theme.palette.primary.white,
                      },
                    }}
                    onClick={handleProceedOpen}
                  >
                    Proceed
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </ModalContent>
        </ModalContainer>
      </Dialog>
      <AddCardModal open={addCardOpen} onClose={handleAddCardClose} onSuccess={handleSuccessAfterAddCard}/>
      <AddCardSuccessModal
        open={addCardSuccessOpen}
        onClose={handleAddCardSuccessClose}
        handleBackToCheckout={handleBackToCheckout} // Add the back to checkout handler
      />
      <ProceedModal open={proceedOpen} onClose={handleProceedClose} />
    </>
  );
}

CheckoutModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
